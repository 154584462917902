import { AnyAction } from "redux";
import { typeCreator } from "../utility/types";

export const FETCH_USER = typeCreator("EDIT_USER", "FETCH_USER");
export const SUBMIT_EDIT_USER = typeCreator("EDIT_USER", "SUBMIT_EDIT_USER");
export const DELETE_USER = typeCreator("EDIT_USER", "DELETE_USER");

export const SET_ROLE = "EDIT_USER/SET_ROLE";
export const SET_ROLE_ID = "EDIT_USER/SET_ROLE_ID";

export const RESET_USER_DETAILS = "EDIT_USER/RESET_USER_DETAILS";
export interface EditUserDetailsState {
  email: string;
  nationality: string;
  officeAddress: string;
  city: string;
  state: string;
  county: string;
  postalCode: string;
  firstName: string;
  lastName: string;
  name: string;
  createdDate: string;
  jobTitle: string;
  userRoleId: string;
  userRole: string;
  updatedUserRoleId: string;
  updatedUserRole: string;
}

export interface FetchUserAction extends AnyAction {
  type: typeof FETCH_USER.START;
  orgId: string;
  orgRoleId: string;
  contactId: string;
  resolve?: (response: unknown) => void;
}

export interface FetchUserSuccessAction extends AnyAction {
  type: typeof FETCH_USER.SUCCESS;
  payload: {
    user: {
      email: string;
      nationality: string;
      officeAddress: string;
      city: string;
      state: string;
      county: string;
      postalCode: string;
      firstName: string;
      lastName: string;
      name: string;
      createdDate: string;
      userRoleId: string;
      userRole: string;
      jobTitle: string;
    };
  };
}

export interface FetchUserErrorAction extends AnyAction {
  type: typeof FETCH_USER.ERROR;
}

export interface SubmitEditUserAction extends AnyAction {
  type: typeof SUBMIT_EDIT_USER.START;
  orgId: string;
  orgRoleId: string;
  contactId: string;
  role: string;
  roleId: string;
  resolve?: (response: unknown) => void;
}

export interface SubmitEditUserSuccessAction extends AnyAction {
  type: typeof SUBMIT_EDIT_USER.SUCCESS;
}

export interface SubmitEditUserErrorAction extends AnyAction {
  type: typeof SUBMIT_EDIT_USER.ERROR;
}

export interface SetRoleAction extends AnyAction {
  type: typeof SET_ROLE;
  value: string;
}

export interface SetRoleIdAction extends AnyAction {
  type: typeof SET_ROLE_ID;
  value: string;
}

export interface ResetUserDetailsAction extends AnyAction {
  type: typeof RESET_USER_DETAILS;
}

export interface DeleteUserAction extends AnyAction {
  type: typeof DELETE_USER.START;
  orgId: string;
  orgRoleId: string;
  contactId: string;
  resolve?: (response: unknown) => void;
}

export interface DeleteUserSuccessAction extends AnyAction {
  type: typeof DELETE_USER.SUCCESS;
}

export interface DeleteUserErrorAction extends AnyAction {
  type: typeof DELETE_USER.ERROR;
}

export type EditUserActionTypes =
  | FetchUserAction
  | FetchUserSuccessAction
  | FetchUserErrorAction
  | SubmitEditUserAction
  | SubmitEditUserSuccessAction
  | SubmitEditUserErrorAction
  | SetRoleAction
  | SetRoleIdAction
  | ResetUserDetailsAction
  | DeleteUserAction
  | DeleteUserSuccessAction
  | DeleteUserErrorAction;
