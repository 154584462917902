export default {
  includedItemsText: "Includes:",
  inProgressText: "In progress",
  activeText: "Active",
  onHold: "On hold",
  ctaText: {
    quote: "View and accept quote",
    viewAgreement: "View and accept agreement",
    activateAgreement: "Activate agreement",
  },
  emptyCardDescription:
    "ACCA agreements gives you a competitive advantage with tailored discounts and other benefits for your organisation. Contact us via your Business Development Manager to discuss agreements.",
  validityText: "Valid until ",
  contactUsCtaText: "Contact us",
  exemptionsText: "exam exemptions",
  registrationsText: "Initial registrations",
  subscriptionsText: "years subscriptions",
};
