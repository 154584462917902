import React, { FC } from "react";
import { SiteFooter } from "@acca-portals/shared";
import staticContent from "./Footer.content";

const Footer: FC = () => {
  return (
    <div className="u-margin-top-2">
      <SiteFooter content={staticContent} />
    </div>
  );
};

export default Footer;
