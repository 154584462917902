import React, { FC, useState } from "react";
import {
  Card,
  ToggleControlGroup,
  RadioButton,
  Select,
  Button,
  LoadingDots,
} from "acca-design-system";
import { Label, useMarketingPauseDays } from "@acca-portals/shared";
import "./MarketingPauseCard.scss";
import staticContent from "./MarketingPauseCard.content";

export interface IMarketingPauseCard {
  onSubmit: (pausedays: number, selectedPauseOption: boolean) => void;
  type: string;
  period: number;
  isUpdating?: boolean;
}

const MarketingPauseCard: FC<IMarketingPauseCard> = ({ onSubmit, type, period, isUpdating }) => {
  const [pausedays, setPauseDays] = useState<number>(period);
  const [pauseOption, setPauseOption] = useState(null);
  const { heading, pauseFor, pauseNo } = staticContent;
  const { isLoading, isError, data } = useMarketingPauseDays();
  const pauseDropDownValues = data?.map((term) => ({
    label: `${term} days`,
    value: `${term}`,
  }));

  if (isError) {
    return null;
  }

  const selectMarketingPauseDays = (days) => {
    return pauseDropDownValues?.find(({ value }) => value === `${days}`);
  };

  const lowestPeriod = pauseDropDownValues?.sort((a, b) => (a.value > b.value ? 1 : -1));
  const defaultPeriod = period
    ? selectMarketingPauseDays(period)
    : selectMarketingPauseDays(lowestPeriod && lowestPeriod[0].value);

  const handleOnClick = () => {
    const selectedPauseOption = pauseOption.split("_")[1] === "true";
    onSubmit(pausedays || Number.parseInt(defaultPeriod.value, 10), selectedPauseOption);
  };

  const renderMain = () => {
    return (
      <>
        <Label id="pause-heading" className="u-margin-bottom-2" text={heading} />
        <ToggleControlGroup>
          <div className="marketing-pause-card__dropdown u-content-container-medium">
            <RadioButton
              id={`${type}_pauseYes`}
              value={`${type}_true`}
              text={pauseFor}
              checked={pauseOption === `${type}_true`}
              onChange={(e) => setPauseOption(e.target.value)}
            />
            <div className="marketing-pause-card__dropdown-options">
              <Select
                id="pauseOptions"
                options={pauseDropDownValues}
                value={selectMarketingPauseDays(pausedays) || defaultPeriod}
                onChange={({ value }) => setPauseDays(Number.parseInt(value, 10))}
                disabled={pauseOption === `${type}_false`}
              />
            </div>
          </div>
          <RadioButton
            id={`${type}_pauseNo`}
            value={`${type}_false`}
            text={pauseNo}
            checked={pauseOption === `${type}_false`}
            onChange={(e) => setPauseOption(e.target.value)}
          />
        </ToggleControlGroup>
        <Button
          text="Save"
          type="submit"
          className="u-margin-top-2"
          disabled={!pauseOption}
          onClick={handleOnClick}
        />
      </>
    );
  };

  return (
    <Card className="marketing-pause-card" backgroundColor="grey">
      {isLoading || isUpdating ? <LoadingDots /> : renderMain()}
    </Card>
  );
};

export default MarketingPauseCard;
