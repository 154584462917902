import React, { FC } from "react";
import { Container } from "@acca-portals/shared";
import staticContent from "./AgreementSuccessModal.content";
import illustration from "../../assets/images/illustration_all-good.svg";
import "./agreement-success-modal.scss";

const AgreementThankyouModal: FC = () => {
  return (
    <>
      <Container className="section">
        <form id="AgreementSuccess-form" className="agreementSuccess">
          <div>
            <img src={illustration} alt="all_good" className="agreementSuccess__illustration" />
          </div>

          <h1 className="u-font-heading h500 u-margin-bottom-2 u-text-center">
            {staticContent.title}
          </h1>
          <p>{staticContent.description}</p>
        </form>
      </Container>
    </>
  );
};

export default AgreementThankyouModal;
