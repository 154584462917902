import React, { FC } from "react";
import * as FlagIcons from "./FlagIcons";

export interface FlagIconImageProps {
  flagName: string;
  className?: string;
}

const FlagIconImage: FC<FlagIconImageProps> = ({ flagName, className }) => {
  const flags: Record<string, string> = { ...FlagIcons };
  const imagePath = flags[flagName];
  if (!imagePath) {
    return null;
  }
  return <img className={className} src={imagePath} alt="" />;
};

export default FlagIconImage;
