import {
  SUBMIT_NEW_USER,
  SET_FIRSTNAME,
  SET_LASTNAME,
  SET_EMAIL,
  SET_ROLE,
  SET_MESSAGE,
  RESET_USER_DETAILS,
  SET_ROLE_ID,
  RESET_ERROR_CODE,
} from "./types";
import actionCreator from "../utility/actionCreator";

const args = ["orgId", "orgRoleId", "resolve"];

export const submitNewUser = actionCreator(SUBMIT_NEW_USER, ...args);
export const setFirstname = actionCreator(SET_FIRSTNAME, "value");
export const setLastname = actionCreator(SET_LASTNAME, "value");
export const setEmail = actionCreator(SET_EMAIL, "value");
export const setRole = actionCreator(SET_ROLE, "value");
export const setRoleId = actionCreator(SET_ROLE_ID, "value");
export const setMessage = actionCreator(SET_MESSAGE, "value");
export const resetUserDetails = actionCreator(RESET_USER_DETAILS);
export const resetErrorCode = actionCreator(RESET_ERROR_CODE);
