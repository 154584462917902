import { combineReducers, Reducer } from "redux";
import { History } from "history";
import { connectRouter } from "connected-react-router";

import { UserProfileState } from "./userProfile/types";
import { ContentState } from "./content/types";
import { AppErrorState } from "./appError/types";
import { OrganisationListingState } from "./organisationListing/types";

import { LookupState } from "./lookups/types";
import { OrganisationOverviewState } from "./organisationOverview/types";
import { ReviewUserState } from "./reviewUser/types";
import userProfile from "./userProfile/reducers";
import organisationListing from "./organisationListing/reducers";
import lookups from "./lookups/reducers";
import newUserDetails from "./newUserDetails/reducers";
import editUserDetails from "./editUserDetails/reducers";
import organisationOverview from "./organisationOverview/reducers";
import pendingUserDetails from "./pendingUser/reducer";
import agreementsAndQuotes from "./agreementsAndQuotes/reducers";
import org from "./org/reducers";
import content from "./content/reducers";
import appError from "./appError/reducers";
import { NewUserDetailsState } from "./newUserDetails/types";
import { EditUserDetailsState } from "./editUserDetails/types";
import reviewUserDetails from "./reviewUser/reducers";
import { PendingUserDetailsState } from "./pendingUser/types";
import { OrgState } from "./org/types";
import { AgreementsState } from "./agreementsAndQuotes/types";

export interface RootState {
  router?: string;
  userProfile?: UserProfileState;
  organisationListing?: OrganisationListingState;
  newUserDetails?: NewUserDetailsState;
  editUserDetails?: EditUserDetailsState;
  lookups?: LookupState;
  content?: ContentState;
  appError?: AppErrorState;
  organisationOverview?: OrganisationOverviewState;
  reviewUserDetails?: ReviewUserState;
  pendingUserDetails?: PendingUserDetailsState;
  agreementsAndQuotes?: AgreementsState;
  org?: OrgState;
}

export const rootReducer = (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    userProfile,
    organisationListing,
    lookups,
    content,
    newUserDetails,
    editUserDetails,
    appError,
    organisationOverview,
    reviewUserDetails,
    pendingUserDetails,
    agreementsAndQuotes,
    org,
  });
