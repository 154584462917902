import React, { FC, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { SetRejectMessage, SetAgreementError } from "../../state/agreementsAndQuotes/actions";
import { getAgreementRejectedMessage } from "../../state/agreementsAndQuotes/selectors";
import InputWithValidation, { Validations } from "../InputWithValidation/InputWithValidation";
import { isAlphanumericValue, isMaximumLength } from "../../utilities/validation-rules";
import staticContent from "./AgreementRejectModal.content";

import "./agreement-reject-modal.scss";

const AgreementRejectModal: FC = () => {
  const dispatch = useDispatch();

  const handleMessageChange = (e) => {
    dispatch(SetRejectMessage(e.target.value));
  };

  const rejectedMessage = useSelector(getAgreementRejectedMessage);
  const [invalidFields, setInvalidFields] = useState([]);

  const onFieldValidate = (errors: Validations, fieldId: string) => {
    if (errors.length === 0) {
      return setInvalidFields(invalidFields.filter((id) => id !== fieldId));
    }
    return setInvalidFields((prevState) => prevState.concat([fieldId]));
  };
  useEffect(() => {
    if (invalidFields) {
      dispatch(SetAgreementError(invalidFields));
    }
  }, [dispatch, invalidFields]);

  return (
    <>
      <form id="AgreementReject-form" className="agreementReject">
        <h1 className="u-font-heading h500 u-margin-bottom-2 u-text-center">
          {staticContent.title}
        </h1>
        <InputWithValidation
          id="message"
          type="textarea"
          labelText={staticContent?.messageHeading}
          onChange={handleMessageChange}
          value={rejectedMessage}
          validations={[isAlphanumericValue, isMaximumLength]}
          onValidate={onFieldValidate}
        />
      </form>
    </>
  );
};

export default AgreementRejectModal;
