import React, { FC, useEffect, useState, FormEvent } from "react";
import {
  Container,
  SubmittingMessage,
  PageContentLoader,
  replaceTemplates,
} from "@acca-portals/shared";
import { Card, Button, PopupManager, FormField, IconProfile24 } from "acca-design-system";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import staticContent from "./ReviewUserPage.content";
import DetailsList from "../../components/Details/DetailsList";
import UserTitle from "../../components/UserTitle/UserTitle";
import ROUTES from "../../constants/routes";

import formatDate from "../../utilities/format-date";
import { fetchReviewUserContent } from "../../state/content/actions";
import { fetchUserRoles } from "../../state/lookups/actions";
import { PageContent } from "../../state/content/types";
import { getRevieweUserContent } from "../../state/content/selectors";
import {
  setReviewRoleName,
  setReviewRoleId,
  fetchReviewUser,
  AcceptReviewUser,
  setReviewDeclineMsg,
  DeclineReviewUser,
  setReviewIsAccepted,
  setReviewIsDeclined,
} from "../../state/reviewUser/actions";
import {
  getReviewUserRoleName,
  getReviewUserDetails,
  getEmail,
  getFullName,
  getRequesttedtTime,
  getReviewUserDeclineMsg,
  getReviewUserIsAccepted,
  getReviewUserIsDeclined,
} from "../../state/reviewUser/selectors";
import { getViewerRolesId, getViewerRolesValue } from "../../state/lookups/selectors";
import RoleSelector from "../../components/RoleSelector/RoleSelector";
import { getOrganisationName } from "../../state/organisationOverview/selectors";
import { fetchOrganisationOverview } from "../../state/organisationOverview/action";
import { isUserAdminForOrganisation } from "../../state/userProfile/selectors";
import "./review-user-page.scss";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";
import apiConfig from "../../configs/apiConfig";

const ReviewUserPage: FC = () => {
  const labels = {
    email: "Email",
    nationality: "Nationality",
    address: "Office address",
    city: "Town/City",
    state: "County/State",
    province: "Province",
    postcode: "Postcode/zip",
    jobTitle: "Job title",
    message: "Message",
  };
  const list = useSelector(getReviewUserDetails);
  const detailsList = {
    email: list.userRequest.email,
    nationality: list.userRequest.nationality,
    address: list.userRequest.officeAddress,
    city: list.userRequest.city,
    state: list.userRequest.state,
    province: list.userRequest.county,
    postcode: list.userRequest.postalCode,
    jobTitle: list.userRequest.jobTitle,
    message: list.userRequest.message,
  };
  const { orgId, orgRoleId, requestId } = useParams<{
    orgId: string;
    orgRoleId: string;
    requestId: string;
  }>();
  const content: PageContent = useSelector(getRevieweUserContent);

  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const email = useSelector(getEmail);
  const roletype = useSelector(getReviewUserRoleName);
  const fullName = useSelector(getFullName);
  const orgName = useSelector((state) => getOrganisationName(state, orgId, orgRoleId));
  const requestedTime = useSelector(getRequesttedtTime);
  const isUserAdminForOrg = useSelector((state) => isUserAdminForOrganisation(state, orgName));
  const isDeclined = useSelector(getReviewUserIsDeclined);
  const isAccepted = useSelector(getReviewUserIsAccepted);

  const breadcrumb = [
    {
      text: "Organisations",
      to: "/admin/organisations",
    },
    {
      text: orgName,
      to: `${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`,
    },
    {
      text: `Review ${fullName}`,
      to: `${ROUTES.reviewUser}/${orgId}/${orgRoleId}/${requestId}`,
    },
  ];
  const defaultRoleId = useSelector(getViewerRolesId);
  const defaultRoleValue = useSelector(getViewerRolesValue);
  const declineMsg = useSelector(getReviewUserDeclineMsg);
  const timeout = apiConfig.timeout.reviewUserTimeout;

  const handleRoleChange = (e) => {
    dispatch(setReviewRoleName(e.target.value));
    dispatch(setReviewRoleId(e.target.id));
  };
  const handleMessageChange = (e) => {
    dispatch(setReviewDeclineMsg(e.target.value));
  };
  const handleAccept = () =>
    new Promise((resolve) => {
      dispatch(setReviewIsAccepted(true));
      dispatch(AcceptReviewUser(orgId, orgRoleId, requestId, timeout, resolve));
    });
  const handleDecline = () =>
    new Promise((resolve) => {
      dispatch(setReviewIsDeclined(true));
      dispatch(DeclineReviewUser(orgId, orgRoleId, requestId, resolve));
    });

  const onReviewUserAcceptSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    await handleAccept();
    setIsSubmitting(false);
    toast.success(
      replaceTemplates(staticContent.toastSuccessMessage, {
        username: `${email}`,
        roleType: `${roletype}`,
      })
    );
    history.push(`${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`);
  };

  const onReviewUserDeclineSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    await handleDecline();
    setIsSubmitting(false);
    toast.error(staticContent.toastCancelMessage);
    history.push(`${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`);
  };

  useEffect(() => {
    if (!content) {
      dispatch(fetchReviewUserContent());
    }
    if (isDeclined === false && isAccepted === false) {
      dispatch(fetchReviewUser(orgId, orgRoleId, requestId));
    }

    if (email) {
      setIsLoading(false);
    }
    if (!orgName) {
      dispatch(fetchOrganisationOverview(orgId, orgRoleId));
    }
    if (!defaultRoleId) {
      dispatch(fetchUserRoles());
    } else {
      dispatch(setReviewRoleName(defaultRoleValue));
      dispatch(setReviewRoleId(defaultRoleId));
    }
  }, [dispatch, defaultRoleId, email, isDeclined, isAccepted]);

  const pageReady = content && orgName && defaultRoleId;
  if (!pageReady) {
    return <PageContentLoader />;
  }
  if (!isUserAdminForOrg) {
    history.push(`${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`);
  }

  const fullNameTitle = replaceTemplates(content?.heading.text, {
    username: fullName,
  });

  return (
    <>
      {!isSubmitting && !isLoading ? (
        <DefaultPageLayout
          titleContentText={content?.heading?.text}
          pageTitle={staticContent.pageHeading}
          pageSubTitle={fullNameTitle}
          breadcrumb={breadcrumb}
        >
          <Container className="section review-user">
            <div className="cell">
              <Card icon={IconProfile24}>
                <UserTitle
                  username={fullName}
                  timestamp={replaceTemplates(staticContent.requestedAccessTimestamp, {
                    date: formatDate(requestedTime),
                  })}
                />

                <div className="u-content-container-medium">
                  <DetailsList labels={labels} detailsList={detailsList} />
                </div>
                <p className="u-padding-bottom-1 u-font-bold">{staticContent.userRoleLabel}</p>
                <RoleSelector
                  onChange={handleRoleChange}
                  stateSelector={getReviewUserRoleName}
                  className="u-margin-bottom-3"
                />
                <Button
                  text={staticContent.acceptUser}
                  type="submit"
                  className="u-margin-right-2"
                  onClick={onReviewUserAcceptSubmit}
                />
                <PopupManager
                  showPopup={showPopup}
                  handleTogglePopup={() => setShowPopup(!showPopup)}
                  closePopup={() => {
                    dispatch(setReviewDeclineMsg(""));
                    setShowPopup(false);
                  }}
                  position="top"
                  showCloseButton
                  CustomTrigger={({ forwardedRef }) => (
                    <Button
                      forwardedRef={forwardedRef}
                      className="u-margin-top-1"
                      onClick={() => {
                        setShowPopup(true);
                      }}
                      variant="link"
                      text={staticContent.declineUser}
                    />
                  )}
                >
                  <div>
                    <p>{staticContent.popupMessage}</p>
                    <FormField hasMaxWidth>
                      <label htmlFor="MultipleRows" className="form-label u-margin-top-2">
                        {staticContent.popupLabel}
                      </label>
                      <textarea
                        id="MultipleRows"
                        maxLength={500}
                        value={declineMsg}
                        onChange={handleMessageChange}
                      />
                    </FormField>
                    <Button
                      text={staticContent.declineUser}
                      type="submit"
                      className="u-margin-right-2 u-margin-top-2"
                      onClick={onReviewUserDeclineSubmit}
                    />
                  </div>
                </PopupManager>
              </Card>
            </div>
          </Container>
        </DefaultPageLayout>
      ) : (
        <SubmittingMessage title={isLoading ? "Loading" : "Saving"} />
      )}
    </>
  );
};

export default ReviewUserPage;
