import React, { FC, useState } from "react";
import {
  Sidebar,
  SidebarContainer,
  NavBar,
  IconProfile16,
  IconChevronLeft16,
  Hamburger,
  VerticalNav,
} from "acca-design-system";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthService } from "@acca-portals/shared";

import OrgProfile from "../OrgProfile/OrgProfile";
import { navigation } from "./Sidebar.content";
import {
  getHasMultiplePortalAccounts,
  getOrgRole,
  getUserEmail,
} from "../../state/userProfile/selectors";
import { getOrgId, getOrgRoleId } from "../../state/org/selectors";
import {
  getOrganisationLocation,
  getOrganisationName,
} from "../../state/organisationOverview/selectors";

const getInitialMenu = (pathname: string) => {
  let level = 0;
  let menuId;
  let hasActiveItem = false;

  const getActiveMenuItem = (items, currentLevel, parent) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const { subLinks, to } = item;
      const hasSubLinks = subLinks && subLinks.length > 0;
      if (to === pathname && !hasSubLinks) {
        level = currentLevel;
        hasActiveItem = true;
        menuId = parent.id;
        break;
      }
      if (hasSubLinks) {
        getActiveMenuItem(subLinks, currentLevel + 1, item);
      }
    }
  };

  getActiveMenuItem(navigation, level, navigation[0]);

  return hasActiveItem ? { level, menuId } : { level: 0, menuId: navigation[0].id };
};

const SidebarComponent: FC = () => {
  const [menuDrawOpen, setMenuDrawOpen] = useState(false);
  const { pathname } = useLocation();
  const initialMenu = getInitialMenu(pathname);
  const [currentLevel, setCurrentLevel] = useState(initialMenu.level);
  const [currentMenu, setCurrentMenu] = useState(initialMenu.menuId);
  const [previousMenu, setPreviousMenu] = useState("/");
  const userEmail = useSelector(getUserEmail);
  const orgId = useSelector(getOrgId);
  const orgRoleId = useSelector(getOrgRoleId);
  const orgLocation = useSelector((state) => getOrganisationLocation(state, orgId, orgRoleId));
  const orgName = useSelector((state) => getOrganisationName(state, orgId, orgRoleId));
  const orgRole = useSelector((state) => getOrgRole(state, orgId, orgRoleId));
  const hasMultiplePortalAccounts = useSelector(getHasMultiplePortalAccounts);

  const conditionalSidebarData = () => {
    if (!orgRole) {
      return navigation;
    }
    return navigation;
  };

  const verticalNav = (
    <VerticalNav
      previousMenu={previousMenu}
      currentMenu={currentMenu}
      currentLevel={currentLevel}
      currentUrl={pathname}
      navigation={conditionalSidebarData()}
      onItemClick={(_, hasSubMenu, menuId, prevMenu) => {
        if (hasSubMenu) {
          setCurrentLevel(currentLevel + 1);
          setCurrentMenu(menuId);
          setPreviousMenu(prevMenu);
        }
      }}
      onBackBtnClick={(_, menuId, prevMenu) => {
        setCurrentLevel(currentLevel - 1);
        setCurrentMenu(menuId);
        setPreviousMenu(prevMenu);
      }}
      headerButton={
        !!hasMultiplePortalAccounts && {
          text: "Back to profile switcher",
          onClick: () => window.location.replace("/"),
          icon: IconChevronLeft16,
        }
      }
    />
  );
  const navBar = (
    <NavBar
      menuDrawOpen={menuDrawOpen}
      menu={{
        text: "Menu",
        icon: Hamburger,
      }}
      links={[
        {
          text: "Log out",
          icon: IconProfile16,
        },
      ]}
      onLinkClick={() => AuthService.logout()}
      onMenuClick={() => {
        setMenuDrawOpen(!menuDrawOpen);
      }}
    />
  );

  const userProfile = (
    <OrgProfile
      orgLocation={orgLocation}
      orgName={orgName}
      orgRole={orgRole}
      email={userEmail && userEmail}
    />
  );

  return (
    <SidebarContainer>
      <Sidebar
        userProfile={userProfile}
        navigation={verticalNav}
        navBar={navBar}
        isActive={menuDrawOpen}
        closeMenu={() => {
          setMenuDrawOpen(false);
        }}
        controls={[
          {
            text: "Log out",
            icon: IconProfile16,
            onClick: () => AuthService.logout(),
          },
        ]}
      />
    </SidebarContainer>
  );
};

export default SidebarComponent;
