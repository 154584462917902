export default {
  accIdText: "ACCA ID",
  accNameText: "Name",
  accNationalityText: "Nationality",
  accLocationText: "Location",
  accJobTitleText: "Job title",
  deleteMyAccount: "Delete My Account",
  accountDetailsTitleText: "Account Details",
  accountDetailsSubTitleText: "Check your account details",
  genericErrorMessage: "An error occurred submitting the form.",
  accountDetailsHeaderText: "Account Details",
  accountDetailsKeyDetails: "Your key details",
  accountDetailsAlertDescription:
    "Some account details can't be updated here, such as your name and nationality. Please contact customer services if you'd like to change these. <a href='/'>send us a message</a> or call us on <a href='tel:+44 0141 582 2000'>+44 (0)141 582 2000</a>",
};
