import React, { FC } from "react";
import { Card, IconInternational, IconNational, IconLocal } from "acca-design-system";
import "./org-profile.scss";

export interface OrgProfileProps {
  email?: string;
  orgRole?: string;
  orgName?: string;
  orgLocation?: string;
}

const OrgProfile: FC<OrgProfileProps> = ({ email, orgRole, orgName, orgLocation }) => {
  const location = () => {
    if (orgLocation === "National office") return IconLocal;
    if (orgLocation === "Regional office") return IconNational;
    return IconInternational;
  };

  return (
    <div className="avatar-container">
      <Card className="org-profile no_link_style" icon={location()} iconSize="large" href="/">
        <div className="org-profile__content">
          {email && <div className="org-profile__email">{email}</div>}
          {orgRole && <div className="org-profile__role">{orgRole}</div>}
          {orgName && <div className="org-profile__org-name">{orgName}</div>}
          <div className="org-profile__org-label">Organisation</div>
        </div>
      </Card>
    </div>
  );
};

export default OrgProfile;
