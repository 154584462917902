import React, { FC } from "react";
import Parser from "html-react-parser";

import { LoadingDots, BodyCopy } from "acca-design-system";

import "./submitting-message.scss";

export interface SubmittingMessageProps {
  title?: string;
  description?: string;
}

const SubmittingMessage: FC<SubmittingMessageProps> = ({
  title = "Searching",
  description = "<p>This can take up to 30 seconds.</p>",
}) => {
  return (
    <div className="submitting-message">
      <div className="submitting-message__inner">
        <LoadingDots className="u-margin-top-8 u-margin-bottom-4" />
        <h3 className="submitting-message__title">{title}</h3>
        <BodyCopy className="submitting-message__description">{Parser(description)}</BodyCopy>
      </div>
    </div>
  );
};

export default SubmittingMessage;
