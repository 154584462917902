export const { API_BASE_URL } = process.env;

export default {
  endpoints: {
    lookups: {
      countries: `${API_BASE_URL}/lookups?type=countries`,
      marketingUnsubscribeReasons: `${API_BASE_URL}/lookups?type=marketingUnsubscribeReasons`,
      marketingPauseDays: `${API_BASE_URL}/lookups?type=marketingPauseDays`,
    },
    templates: {
      notifications: `${API_BASE_URL}/public/ui/templates?page=notifications`,
    },
    user: {
      userProfile: `${API_BASE_URL}/users/me`,
    },
  },
  reactQueryConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        retry: false,
        cacheTime: 0,
      },
    },
  },
};
