import React, { FC, ReactSVGElement } from "react";
import Parser from "html-react-parser";
import { BodyCopy, TimelineEvent, Card, Button } from "acca-design-system";
import staticContent from "../QATimelineEvents.content";

export interface UnhighlightedProps {
  title?: string;
  description?: string;
  icon?: ReactSVGElement;
  id?: string;
  key?: number;
  cardStatus?: string;
  hasButton?: boolean;
}

const Unhighlighted: FC<UnhighlightedProps> = ({
  icon,
  id,
  title,
  description,
  cardStatus,
  hasButton,
}) => {
  return (
    <>
      {cardStatus === "active" ? (
        <TimelineEvent id={id} icon={icon}>
          <Card backgroundColor="grey" className={hasButton ? "hasButton" : ""}>
            <BodyCopy>
              <h3 className="unhighlighted-heading u-font-heading">{title}</h3>
              {description && <p className="u-margin-top-1">{Parser(description)}</p>}
            </BodyCopy>
            {hasButton && <Button variant="outlined" text={staticContent.agreement.talkToUs} />}
          </Card>
        </TimelineEvent>
      ) : (
        <TimelineEvent id={id} icon={icon}>
          <BodyCopy>
            <h3 className="unhighlighted-heading u-font-heading">{title}</h3>
            {description && <p className="u-margin-top-1">{Parser(description)}</p>}
          </BodyCopy>
        </TimelineEvent>
      )}
    </>
  );
};

export default Unhighlighted;
