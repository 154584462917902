import React from "react";
import { IconChevronLeft16, IconChevronRight16 } from "acca-design-system";
import "./circle-buttons-nav.scss";

interface ICircleButtonsNav {
  onPrevClick: () => void;
  onNextClick: () => void;
  prevButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
  className?: string;
}

const CircleButtonsNav = ({
  onPrevClick,
  onNextClick,
  prevButtonDisabled,
  nextButtonDisabled,
  className = "",
}: ICircleButtonsNav) => {
  return (
    <nav className={`circle-buttons-nav ${className}`}>
      <button
        onClick={onPrevClick}
        className="circle-buttons-nav__btn"
        disabled={prevButtonDisabled}
      >
        <IconChevronLeft16 />
      </button>
      <button
        onClick={onNextClick}
        className="circle-buttons-nav__btn"
        disabled={nextButtonDisabled}
      >
        <IconChevronRight16 />
      </button>
    </nav>
  );
};

export default CircleButtonsNav;
