import React, { FC } from "react";
import { PageHeader } from "acca-design-system";
import { Section } from "@acca-portals/shared";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";
import content from "./LoginDetailsPage.content";

const LoginDetailsPage: FC = () => {
  const pageError = undefined; // placeholder for API integration
  const isPageReady = true;

  const renderMain = () => {
    if (pageError || !isPageReady) {
      return null;
    }

    const { pageTitle, pageSubtitle } = content;
    return (
      <>
        <PageHeader className="u-margin-bottom-2" pageTitle={pageTitle} quote={pageSubtitle} />
        <Section>
          <p>Coming soon.</p>
        </Section>
      </>
    );
  };

  return (
    <DefaultPageLayout loading={!isPageReady} error={pageError}>
      {renderMain()}
    </DefaultPageLayout>
  );
};

export default LoginDetailsPage;
