import {
  LookupState,
  LookupActionTypes,
  FETCH_USER_ROLES,
  FETCH_USER_ROLES_SUCCESS,
  FETCH_QUOTE_DECLINE_REASONS,
  SUBMIT_QUOTE_DECLINE_REASONS,
  SET_DECLINE_REASONS,
  SET_DECLINE_MESSAGE,
  SET_QUOTE_NUMBER,
  SET_QUOTE_ID,
  SUBMIT_QUOTE_ACCEPTED,
  RESET_DECLINE_MESSAGE,
  RESET_DECLINE_REASONS,
  SET_DECLINE_ERROR,
  RESET_DECLINE_ERROR,
} from "./types";

export const initialState: LookupState = {
  declineMessage: "",
  declineReasonsList: [],
  quoteNumber: "",
  quoteId: "",
  declineError: [],
};

export default function lookupsReducer(
  state = initialState,
  action: LookupActionTypes
): LookupState {
  switch (action.type) {
    case FETCH_USER_ROLES:
      return {
        ...state,
        userRoles: null,
      };
    case FETCH_USER_ROLES_SUCCESS:
      return {
        ...state,
        userRoles: action.payload.userRoles,
      };
    case FETCH_QUOTE_DECLINE_REASONS.START:
      return {
        ...state,
        quoteDeclineReasons: null,
      };
    case FETCH_QUOTE_DECLINE_REASONS.SUCCESS:
      return {
        ...state,
        quoteDeclineReasons: action.payload.quoteDeclineReasons,
      };
    case SUBMIT_QUOTE_DECLINE_REASONS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DECLINE_MESSAGE:
      return {
        ...state,
        declineMessage: action.value,
      };
    case SET_DECLINE_REASONS:
      return {
        ...state,
        declineReasonsList: action.value,
      };
    case SET_QUOTE_NUMBER:
      return {
        ...state,
        quoteNumber: action.value,
      };
    case SET_QUOTE_ID:
      return {
        ...state,
        quoteId: action.value,
      };
    case SUBMIT_QUOTE_ACCEPTED.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_DECLINE_MESSAGE:
      return {
        ...state,
        declineMessage: "",
      };
    case RESET_DECLINE_REASONS:
      return {
        ...state,
        declineReasonsList: [],
      };
    case SET_DECLINE_ERROR:
      return {
        ...state,
        declineError: action.value,
      };
    case RESET_DECLINE_ERROR:
      return {
        ...state,
        declineError: [],
      };
    default:
      return state;
  }
}
