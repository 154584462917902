import { RootState } from "../rootReducer";

export const getUserRoles = (state: RootState) => state.lookups.userRoles;
export const getQuoteDeclinedReasons = (state: RootState) => state.lookups.quoteDeclineReasons;
export const getDeclinedMessage = (state: RootState) => state.lookups.declineMessage;
export const getSelectedReasons = (state: RootState) => state.lookups.declineReasonsList;
export const getQuoteNumber = (state: RootState) => state.lookups.quoteNumber;
export const getQuoteId = (state: RootState) => state.lookups.quoteId;
export const getDeclineError = (state: RootState) => state.lookups.declineError;

export const getViewerRolesId = (state: RootState) => {
  const role = state.lookups.userRoles;
  if (!role) {
    return null;
  }
  return role.filter((id) => id.userDefault === true)[0].id;
};
export const getViewerRolesValue = (state: RootState) => {
  const role = state.lookups.userRoles;
  if (!role) {
    return null;
  }
  return role.filter((id) => id.userDefault === true)[0].value;
};
