import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { PageContentLoader, AuthService } from "@acca-portals/shared";

const WithAccessControl: FC = ({ children }) => {
  const location = useLocation();
  const [authLoaded, setAuthIsLoaded] = useState(false);

  useEffect(() => {
    const isAuthenticated = AuthService.isAuthenticated();
    if (!isAuthenticated) {
      return AuthService.login();
    }
    return setAuthIsLoaded(true);
  }, [location]);

  if (!authLoaded) {
    return <PageContentLoader />;
  }

  return <>{children}</>;
};

export default WithAccessControl;
