import { Configuration } from "@azure/msal-browser";
import { Authorities, Scopes } from "acca-auth-service";

const tenantName: string = process.env.TENANT_NAME;
const authorityBaseUrl = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com
`;

export const authorities: Authorities = {
  signupOrSignin: `${authorityBaseUrl}/B2C_1A_signup_signin`,
  signupOnly: `${authorityBaseUrl}/B2C_1A_Acca_Signup`,
  signinOnly: `${authorityBaseUrl}/B2C_1A_Acca_Signin`,
  forgotPassword: `${authorityBaseUrl}/B2C_1A_PasswordReset`,
  profileEdit: `${authorityBaseUrl}/B2C_1_PROFILE_EDIT`,
};

export const instanceConfig: Configuration = {
  auth: {
    clientId: process.env.AUTH_CLIENT_ID,
    authority: authorities.signupOrSignin,
    knownAuthorities: [`${tenantName}.b2clogin.com`],
    postLogoutRedirectUri: "/",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

export const scopes: Scopes = [
  "openid",
  "profile",
  "email",
  `https://${tenantName}.onmicrosoft.com/acca_api/api.READ`,
  `https://${tenantName}.onmicrosoft.com/acca_api/api.WRITE`,
];
