import React, { FC, useRef, useState, useEffect } from "react";
import { Container } from "@acca-portals/shared";
import { IconBell16 } from "acca-design-system";
import CircleButtonsNav from "../CircleButtonsNav/CircleButtonsNav";
import "./notifications-bar.scss";
import DotsNav from "../DotsNav/DotsNav";

const NotificationsBar: FC = ({ children }) => {
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(true);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [activeCardIdx, setActiveCardIdx] = useState(0);

  const cardsContainer = useRef<HTMLDivElement>(null);

  const onScroll = () => {
    const { scrollLeft, scrollWidth, offsetWidth } = cardsContainer.current;
    setPrevButtonDisabled(scrollLeft === 0);
    setNextButtonDisabled(scrollLeft + offsetWidth >= scrollWidth);

    // on mobile, update the active dot to currently scrolled into card
    const offsets = Array.from(cardsContainer.current.querySelectorAll(".notification-card")).map(
      (cardEl: HTMLElement) => cardEl.offsetLeft
    );
    const closest = offsets.reduce((prev, curr) =>
      Math.abs(curr - scrollLeft) < Math.abs(prev - scrollLeft) ? curr : prev
    );

    setActiveCardIdx(offsets.indexOf(closest));
  };

  const scrollCardIntoView = (cardIdx: number) => {
    const card = cardsContainer.current.querySelectorAll(".notification-card")[
      cardIdx
    ] as HTMLElement;
    cardsContainer.current.scrollTo({
      left: card.offsetLeft,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    cardsContainer.current.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [cardsContainer]);

  if (!children) {
    return null;
  }

  const getCardWidth = () => cardsContainer.current.querySelector(".notification-card").clientWidth;

  const handlePrevClick = () => {
    cardsContainer.current.scrollBy({
      left: -getCardWidth() * 2,
      behavior: "smooth",
    });
  };

  const handleNextClick = () => {
    cardsContainer.current.scrollBy({
      left: getCardWidth() * 2,
      behavior: "smooth",
    });
  };

  const numberOfNotifications = (children as Array<any>).length || 1;
  return (
    <div className="notifications-bar">
      <Container>
        <div className="cell">
          <div className="notifications-bar__header">
            <h3 className="notifications-bar__title">
              <IconBell16 className="notifications-bar__bell" /> {numberOfNotifications} Priority
              Notification
              {numberOfNotifications > 1 && "s"}
            </h3>
            {numberOfNotifications > 1 && (
              <CircleButtonsNav
                className="notifications-bar__circle-nav"
                onPrevClick={handlePrevClick}
                onNextClick={handleNextClick}
                prevButtonDisabled={prevButtonDisabled}
                nextButtonDisabled={nextButtonDisabled}
              />
            )}
          </div>
        </div>
      </Container>
      <div className="notifications-bar__cards" ref={cardsContainer}>
        {children}
      </div>
      <DotsNav
        className="notifications-bar__dots-nav"
        numberOfDots={numberOfNotifications}
        activeIndex={activeCardIdx}
        onClick={scrollCardIntoView}
      />
    </div>
  );
};

export default NotificationsBar;
