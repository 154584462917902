import { takeLatest, call } from "redux-saga/effects";
import { ICallApi } from "@acca-portals/shared";
import apiConfig from "../../configs/apiConfig";
import { FETCH_ORG_OVERVIEW_REQUEST, FetchOrganisationOverviewAction } from "./types";
import { useSaga } from "../utility/hooks/useSaga";

function* handlefetchOrganisationOverview({ orgId, orgRoleId }: FetchOrganisationOverviewAction) {
  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.user.organisationDetails(orgId, orgRoleId),
    method: "GET",
  };

  yield call(
    useSaga,
    [FETCH_ORG_OVERVIEW_REQUEST.success, FETCH_ORG_OVERVIEW_REQUEST.error],
    requestArgs,
    null,
    { orgId, orgRoleId }
  );
}

export default function* organisationOverviewSagas(): unknown {
  yield takeLatest(FETCH_ORG_OVERVIEW_REQUEST.START, handlefetchOrganisationOverview);
}
