import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { LiveMessage } from "react-aria-live";

export interface titleContent {
  browserTitle: {
    message: string;
  };
}

export interface BrowserTitleProps {
  trackPageView: (userType: string, customPageViewPath: string, pageName?: string) => void;
  content?: titleContent;
  customPageViewPath?: string;
  userType?: string;
}

const BrowserTitle: FC<BrowserTitleProps> = ({
  trackPageView,
  content,
  customPageViewPath,
  userType,
}) => {
  const pageName = content && content.browserTitle ? content.browserTitle.message : "";

  useEffect(() => {
    if (pageName !== "" && userType) {
      trackPageView(pageName, userType, customPageViewPath);
    }
  }, [content, userType]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {pageName !== "" && (
        <LiveMessage message={`${pageName} page has loaded`} aria-live="assertive" />
      )}
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
    </>
  );
};

export default BrowserTitle;
