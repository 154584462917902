import {
  ReviewUserState,
  ReviewUserActionTypes,
  FETCH_REVIEW_USER_REQUEST,
  SET_REVIEW_USER_ROLENAME,
  SET_REVIEW_USER_ROLEID,
  ACCEPT_REVIEW_USER_REQUEST,
  SET_REVIEW_USER_DECLINE_MSG,
  DECLINE_REVIEW_USER_REQUEST,
  SET_REVIEW_USER_IS_DECLINED,
  SET_REVIEW_USER_IS_ACCEPTED,
} from "./types";

export const initialState: ReviewUserState = {
  userRequest: {
    email: "",
    nationality: "",
    officeAddress: "",
    city: "",
    state: "",
    county: "",
    postalCode: "",
    firstName: "",
    lastName: "",
    name: "",
    jobTitle: "",
    createdDate: "",
    message: "",
    userRoleId: "",
    userRole: "",
  },
  reviewRoleId: "",
  reviewRoleName: "",
  reviewDeclineMessage: "",
  isAccepted: false,
  isDeclined: false,
};
export default function reviewUserReducer(
  state = initialState,
  action: ReviewUserActionTypes
): ReviewUserState {
  switch (action.type) {
    case FETCH_REVIEW_USER_REQUEST.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_REVIEW_USER_ROLENAME:
      return {
        ...state,
        reviewRoleName: action.value,
      };
    case SET_REVIEW_USER_ROLEID:
      return {
        ...state,
        reviewRoleId: action.value,
      };
    case SET_REVIEW_USER_DECLINE_MSG:
      return {
        ...state,
        reviewDeclineMessage: action.value,
      };
    case ACCEPT_REVIEW_USER_REQUEST.SUCCESS:
      return {
        userRequest: {
          email: "",
          nationality: "",
          officeAddress: "",
          city: "",
          state: "",
          county: "",
          postalCode: "",
          firstName: "",
          lastName: "",
          name: "",
          jobTitle: "",
          createdDate: "",
          message: "",
          userRoleId: "",
          userRole: "",
        },
        reviewRoleId: "",
        reviewRoleName: "",
        reviewDeclineMessage: "",
        isAccepted: true,
        isDeclined: false,
      };
    case DECLINE_REVIEW_USER_REQUEST.SUCCESS:
      return {
        userRequest: {
          email: "",
          nationality: "",
          officeAddress: "",
          city: "",
          state: "",
          county: "",
          postalCode: "",
          firstName: "",
          lastName: "",
          name: "",
          jobTitle: "",
          createdDate: "",
          message: "",
          userRoleId: "",
          userRole: "",
        },
        reviewRoleId: "",
        reviewRoleName: "",
        reviewDeclineMessage: "",
        isAccepted: false,
        isDeclined: true,
      };
    case SET_REVIEW_USER_IS_ACCEPTED:
      return {
        ...state,
        isAccepted: action.value,
      };
    case SET_REVIEW_USER_IS_DECLINED:
      return {
        ...state,
        isDeclined: action.value,
      };

    default:
      return state;
  }
}
