import {
  FETCH_ADD_USER_CONTENT,
  FETCH_EDIT_USER_CONTENT,
  FETCH_LANDING_PAGE_CONTENT,
  FETCH_ORG_LISTING_CONTENT,
  FETCH_PENDING_USER_CONTENT,
  FetchAddUserContentAction,
  FetchEditUserContentAction,
  FetchLandingPageContentAction,
  FetchOrganisationsListingContentAction,
  FetchPendingUserContentAction,
  FETCH_REVIEW_USER_CONTENT,
  FetchReviewUserContentAction,
  FETCH_CATEGORIES_CONTENT,
  FetchCategoriesContentAction,
  FETCH_PRODUCTS_CONTENT,
  FetchProductsContentAction,
} from "./types";

export function fetchLandingPageContent(): FetchLandingPageContentAction {
  return {
    type: FETCH_LANDING_PAGE_CONTENT,
  };
}

export function fetchOrganisationsListingContent(): FetchOrganisationsListingContentAction {
  return {
    type: FETCH_ORG_LISTING_CONTENT,
  };
}

export function fetchAddUserContent(): FetchAddUserContentAction {
  return {
    type: FETCH_ADD_USER_CONTENT,
  };
}

export function fetchEditUserContent(): FetchEditUserContentAction {
  return {
    type: FETCH_EDIT_USER_CONTENT,
  };
}

export function fetchPendingUserContent(): FetchPendingUserContentAction {
  return {
    type: FETCH_PENDING_USER_CONTENT,
  };
}
export function fetchReviewUserContent(): FetchReviewUserContentAction {
  return {
    type: FETCH_REVIEW_USER_CONTENT,
  };
}
export function FetchCategoriesContent(): FetchCategoriesContentAction {
  return {
    type: FETCH_CATEGORIES_CONTENT,
  };
}
export function FetchProductsContent(): FetchProductsContentAction {
  return {
    type: FETCH_PRODUCTS_CONTENT,
  };
}
