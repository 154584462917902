import React, { FC, FormEvent, useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import {
  Alert,
  Button,
  IconProfile24,
  BodyCopy,
  Select,
  PageHeader,
  FormField,
  LoadingDots,
} from "acca-design-system";
import {
  SaveBar,
  Section,
  FormSection,
  useContent,
  useLookups,
  Label,
  useUserDetails,
  useUpdateUserDetails,
  useCountries,
} from "@acca-portals/shared";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";
import "./AccountDetailsPage.scss";
import staticContent from "./AccountDetailsPage.content";
import ROUTES from "../../constants/routes";

import apiConfig from "../../configs/apiConfig";
import { IAccountDetailsContent } from "./types";

const AccountDetailsPage: FC = () => {
  const content = useContent<IAccountDetailsContent>(
    apiConfig.endpoints.templates.accountDetailsPage
  );
  const [locationEditMode, setLocationEditMode] = useState(false);
  const [initialLocation, setInitialLocation] = useState(null);
  const [updatedlocation, setUpdatedlocation] = useState(null);
  const [initialJobTitle, setInitialJobTitle] = useState(null);
  const [updatedJobTitle, setUpdatedJobTitle] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  const userDetails = useUserDetails();
  const updateUserDetails = useUpdateUserDetails();
  const countries = useCountries();
  const jobTitles = useLookups<string[]>(apiConfig.endpoints.lookups.jobTitles, "jobTitles");
  const countryOfResidence = userDetails?.data?.countryOfResidence;
  const jobTitle = userDetails?.data?.jobTitle;
  const countryDropDownValues = countries?.data?.map(({ name, iso2, iso3 }) => ({
    label: name,
    value: iso2,
    iso3,
  }));

  const jobTitleDropDownValues = jobTitles?.data?.map((title: string) => ({
    label: title,
    value: title,
  }));

  const selectCountryOption = (locationName) => {
    const country = countries?.data?.find(({ name }) => locationName === name);
    return {
      label: country?.name,
      value: country?.iso2,
    };
  };

  const selectJobTitleOption = (selectedTitle) => {
    const matchedTitle = jobTitles?.data?.find((title) => title === selectedTitle);
    return {
      label: matchedTitle,
      value: matchedTitle,
    };
  };

  const pageError = countries.error || content.error || userDetails.error;
  const isPageReady = countries.isSuccess && content.isSuccess && userDetails.isSuccess;

  useEffect(() => setInitialLocation(countryOfResidence), [countryOfResidence]);
  useEffect(() => setInitialJobTitle(jobTitle), [jobTitle]);

  useEffect(() => {
    setIsDirty(false);
    setUpdatedlocation(null);
    setUpdatedJobTitle(null);
  }, [locationEditMode]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const body = {
      countryOfResidence: updatedlocation || initialLocation,
      jobTitle: updatedJobTitle || initialJobTitle,
    };
    updateUserDetails.mutate(body);
    setLocationEditMode(false);
    setInitialLocation(updatedlocation || initialLocation);
    setInitialJobTitle(updatedJobTitle || initialJobTitle);
    setIsDirty(false);
  };

  const handleLocationChange = ({ label }) => {
    setUpdatedlocation(label);
    if (label !== initialLocation) {
      setIsDirty(true);
    } else if (initialJobTitle === updatedJobTitle || updatedJobTitle === null) {
      setIsDirty(false);
    }
  };

  const handleJobTitleChange = ({ label }) => {
    setUpdatedJobTitle(label);
    if (label !== initialJobTitle) {
      setIsDirty(true);
    } else if (initialLocation === updatedlocation || updatedlocation === null) {
      setIsDirty(false);
    }
  };

  const renderMain = () => {
    if (pageError || !isPageReady) {
      return null;
    }

    const { title, heading, description } = content.data;
    const {
      accIdText,
      accNameText,
      accNationalityText,
      accLocationText,
      accJobTitleText,
      deleteMyAccount,
      accountDetailsTitleText,
      genericErrorMessage,
      accountDetailsAlertDescription,
    } = staticContent;

    const { accaId, name, nationality } = userDetails.data;

    const renderAccountDetails = () => {
      if (updateUserDetails?.isLoading || userDetails.isFetching) {
        return <LoadingDots />;
      }

      if (!locationEditMode) {
        return (
          <dl className="u-margin-top-2">
            <dt>{accIdText}</dt>
            <dd>{accaId}</dd>
            <dt>{accNameText}</dt>
            <dd>{name}</dd>
            <dt>{accNationalityText}</dt>
            <dd>{nationality}</dd>
            <dt>{accLocationText}</dt>
            <dd>{initialLocation}</dd>
            <dt>{accJobTitleText}</dt>
            <dd>{initialJobTitle}</dd>
          </dl>
        );
      }
      return (
        <>
          <dl className="u-margin-top-2">
            <dt>{accIdText}</dt>
            <dd>{accaId}</dd>
            <dt>{accNameText}</dt>
            <dd>{name}</dd>
            <dt>{accNationalityText}</dt>
            <dd>{nationality}</dd>
          </dl>
          <FormField className="u-content-container-medium u-margin-top-1">
            <Label id="yourLocation" text={accLocationText} required />
            <Select
              hasJumpList
              id="yourLocation"
              options={countryDropDownValues}
              value={selectCountryOption(updatedlocation || initialLocation)}
              onChange={handleLocationChange}
            />
          </FormField>
          <FormField className="u-content-container-medium u-margin-top-1">
            <Label id="jobTitle" text={accJobTitleText} required />
            <Select
              hasJumpList
              id="jobTitle"
              options={jobTitleDropDownValues}
              value={selectJobTitleOption(updatedJobTitle || initialJobTitle)}
              onChange={handleJobTitleChange}
            />
          </FormField>
          <BodyCopy className="u-margin-top-2">
            {accountDetailsAlertDescription && (
              <Alert inline>{HTMLReactParser(accountDetailsAlertDescription)}</Alert>
            )}
          </BodyCopy>
        </>
      );
    };

    return (
      <form noValidate onSubmit={handleSubmit}>
        <PageHeader className="u-margin-bottom-2" pageTitle={title.text} quote={heading.text} />
        <Section>
          <FormSection
            icon={IconProfile24}
            isEditMode={locationEditMode}
            onEditToggleClick={() => setLocationEditMode(!locationEditMode)}
          >
            <h2 className="section-title">{accountDetailsTitleText}</h2>
            <BodyCopy>{HTMLReactParser(description.value)}</BodyCopy>
            <div className="account-details">{renderAccountDetails()}</div>
          </FormSection>
          {locationEditMode && (
            <SaveBar
              disabled={!isDirty}
              isSubmitting={updateUserDetails.isLoading}
              errorMessage={genericErrorMessage}
              showErrorPrompt={updateUserDetails.isError}
              isDirty={isDirty}
            />
          )}
        </Section>

        {!locationEditMode && (
          <Section>
            <Button
              text={deleteMyAccount}
              type="button"
              variant="outlined"
              to={ROUTES.deleteAccount}
            />
          </Section>
        )}
      </form>
    );
  };

  return (
    <DefaultPageLayout loading={!isPageReady} error={pageError}>
      {renderMain()}
    </DefaultPageLayout>
  );
};

export default AccountDetailsPage;
