export default {
  title: "Admin",
  pageHeading: "Add User",
  firstnameInputLabel: "First name",
  lastnameInputLabel: "Last name",
  emailInputLabel: "Email",
  messageInputLabel: "Message (optional)",
  userRoleLabel: "Select user role",
  submitButtonLabel: "Submit and send invite",
  cancelButtonLabel: "Cancel",
  toastSuccessMessage: "{username} has been emailed a request to join",
  toastCancelMessage: "Invite has been cancelled",
  invalidEmailMessage: "The mail you have entered is invalid",
};
