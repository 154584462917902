const content = {
  standard: {
    browserTitle: {
      message: "System error",
    },
    preTitle: "Failed to load content",
    title: "Sorry, we couldn't load your information",
    message: "This might be a temporary problem.<br>Try reloading the page now.",
    buttonText: "Reload",
    illustration: "illustration503",
    buttonIcon: "IconReload16",
    footer: {
      title: "Need help?",
      message: "Get 24/7 support from our customer services team.",
      link: {
        text: "Get help",
        href: "https://www.accaglobal.com/gb/en/footertoolbar/contact-us.html",
      },
    },
  },
  timeout: {
    browserTitle: {
      message: "Timeout error",
    },
    preTitle: "Timeout error",
    title: "Sorry, this page failed to load.",
    message: "This might be a temporary problem. Try reloading the page.",
    buttonText: "Reload",
    illustration: "illustration503",
    buttonIcon: "IconReload16",
    footer: {
      title: "Need help?",
      message: "Get 24/7 support from our customer services team.",
      link: {
        text: "Get help",
        href: "https://www.accaglobal.com/gb/en/footertoolbar/contact-us.html",
      },
    },
  },
};

export default content;
