import axios, { AxiosPromise, Method, AxiosRequestConfig } from "axios";
import AuthService from "../auth/AuthService";

export interface ICallApi extends AxiosRequestConfig {
  url: string;
  method: Method;
  headers?: Record<string, unknown>;
  data?: unknown;
  params?: Record<string, unknown>;
  timeout?: 30000;
  isPublic?: boolean;
}

class ApiService {
  CallApi<T>({
    url,
    method,
    headers,
    data,
    params,
    timeout,
    isPublic = false,
  }: ICallApi): AxiosPromise<T> {
    const config: AxiosRequestConfig = {
      method,
      data,
      params,
      url,
      timeout,
      headers: {
        Accept: "application/json",
        client_id: process.env.API_CLIENT_ID,
        ...headers,
      },
    };
    if (isPublic) {
      return axios.request(config);
    }
    return AuthService.getProfileToken().then((accessToken: string) => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return axios.request(config);
    });
  }
}

export default new ApiService();
