import React, { FC } from "react";
import { Switch, Route } from "react-router-dom";

import ROUTES from "../../constants/routes";

import LandingPage from "../LandingPage/LandingPage";
import PageNotFoundPage from "../PageNotFoundPage/PageNotFoundPage";

import OrganisationsListingPage from "../OrganisationsListingPage/OrganisationsListingPage";
import OrganisationDetailsPage from "../OrganisationDetailsPage/OrganisationDetailsPage";
import AddUserPage from "../AddUserPage/AddUserPage";
import EditUserPage from "../EditUserPage/EditUserPage";
import ReviewUserPage from "../ReviewUserPage/ReviewUserPage";
import PendingUserPage from "../PendingUserPage/PendingUserPage";
import AgreementsPage from "../AgreementsPage/Agreements";
import AgreementQuoteInfoPage from "../AgreementQuoteInfoPage/AgreementQuoteInfoPage";
import MyDetailsPage from "../MyDetailsPage/MyDetailsPage";
import AccountDetailsPage from "../AccountDetailsPage/AccountDetailsPage";
import ContactDetailsPage from "../ContactDetailsPage/ContactDetailsPage";
import MarketingConsentPage from "../MarketingConsentPage/MarketingConsentPage";
import LoginDetailsPage from "../LoginDetailsPage/LoginDetailsPage";
import DeleteAccountPage from "../DeleteAccountPage/DeleteAccountPage";
import SidebarComponent from "../../components/Sidebar/Sidebar";

interface Page {
  path: string;
  component: FC;
  exact: boolean;
}

const publicPages: Array<Page> = [
  {
    path: ROUTES.landingPage,
    component: LandingPage,
    exact: true,
  },
  {
    path: ROUTES.organisationsListing,
    component: OrganisationsListingPage,
    exact: true,
  },
  {
    path: `${ROUTES.organisationsListing}/:orgId/:orgRoleId`,
    component: OrganisationDetailsPage,
    exact: true,
  },
  {
    path: `${ROUTES.addUser}/:orgId/:orgRoleId`,
    component: AddUserPage,
    exact: true,
  },
  {
    path: `${ROUTES.reviewUser}/:orgId/:orgRoleId/:requestId`,
    component: ReviewUserPage,
    exact: true,
  },
  {
    path: `${ROUTES.pendingUser}/:orgId/:orgRoleId/:contactId`,
    component: PendingUserPage,
    exact: true,
  },
  {
    path: `${ROUTES.agreements}`,
    component: AgreementsPage,
    exact: true,
  },
  {
    path: `${ROUTES.agreementInfoPage}`,
    component: AgreementQuoteInfoPage,
    exact: true,
  },
  {
    path: `${ROUTES.myDetailsPage}`,
    component: MyDetailsPage,
    exact: true,
  },
  {
    path: `${ROUTES.loginDetailsPage}`,
    component: LoginDetailsPage,
    exact: true,
  },
  {
    path: `${ROUTES.contactDetailsPage}`,
    component: ContactDetailsPage,
    exact: true,
  },
  {
    path: `${ROUTES.marketingConsentPage}`,
    component: MarketingConsentPage,
    exact: true,
  },
  {
    path: `${ROUTES.accountDetails}`,
    component: AccountDetailsPage,
    exact: true,
  },
  {
    path: `${ROUTES.deleteAccount}`,
    component: DeleteAccountPage,
    exact: true,
  },
];

const privatePages: Array<Page> = [
  {
    path: `${ROUTES.patchUser}/:orgId/:orgRoleId/:contactId`,
    component: EditUserPage,
    exact: true,
  },
];

const Router: FC = () => (
  <>
    <SidebarComponent />
    <Switch>
      {publicPages.map(({ path, component, exact }) => {
        return <Route key={path} path={path} component={component} exact={exact} />;
      })}
      {privatePages.map(({ path, component, exact }) => {
        return <Route key={path} path={path} component={component} exact={exact} />;
      })}
      <Route component={PageNotFoundPage} />
    </Switch>
  </>
);

export default Router;
