import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { getUserCount } from "../../../../state/organisationOverview/selectors";
import "./user-count.scss";

const UserCount: FC = () => {
  const { orgId, orgRoleId } = useParams<{ orgId: string; orgRoleId: string }>();
  const userCount = useSelector((state) => getUserCount(state, orgId, orgRoleId));

  if (!userCount) {
    return null;
  }

  return (
    <span className="user-count" aria-hidden="true">
      {userCount} {userCount === 1 ? "user" : "users"}
    </span>
  );
};

export default UserCount;
