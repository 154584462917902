import React from "react";
import "./dots-nav.scss";

interface IDotsNavProps {
  numberOfDots: number;
  onClick?: (btnIndex: number) => void;
  activeIndex: number;
  className?: string;
}

const DotsNav = ({ numberOfDots, activeIndex, onClick, className }: IDotsNavProps) => {
  if (numberOfDots < 2) {
    return null;
  }
  return (
    <nav className={`dots-nav ${className}`}>
      {[...Array(numberOfDots)].map((_, idx) => (
        <button
          key={`dots-nav-btn-${idx}`}
          className={`dots-nav__btn ${activeIndex === idx && "dots-nav__btn--active"}`}
          aria-label={`Item ${idx + 1}`}
          onClick={() => onClick && onClick(idx)}
        />
      ))}
    </nav>
  );
};

export default DotsNav;
