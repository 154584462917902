import { RootState } from "../rootReducer";
import staticContent from "../../pages/OrganisationDetailsPage/components/UserTable/UserTable.content";

export const getOrganisationName = (state: RootState, orgId: string, orgRoleId: string) => {
  if (!state.organisationOverview.results[orgId]) {
    return null;
  }
  if (!state.organisationOverview.results[orgId][orgRoleId]) {
    return "";
  }

  return state.organisationOverview.results[orgId][orgRoleId].organisation.name;
};

export const getOrganisationLocation = (state: RootState, orgId: string, orgRoleId: string) => {
  if (!state.organisationOverview.results[orgId]) {
    return null;
  }
  if (!state.organisationOverview.results[orgId][orgRoleId]) {
    return "";
  }

  return state.organisationOverview.results[orgId][orgRoleId].organisation.officeType;
};

export const getNotifications = (state: RootState, orgId: string, orgRoleId: string) => {
  if (!state.organisationOverview.results[orgId]) {
    return null;
  }

  if (!state.organisationOverview.results[orgId][orgRoleId]) {
    return null;
  }
  return state.organisationOverview.results[orgId][orgRoleId].userRequests;
};

export const getUserTableData = (state: RootState, orgId: string, orgRoleId: string) => {
  if (!state.organisationOverview.results[orgId]) {
    return null;
  }

  if (!state.organisationOverview.results[orgId][orgRoleId]) {
    return null;
  }

  const allUsers = [
    ...state.organisationOverview.results[orgId][orgRoleId].invitations.map((user) => {
      const statusContent = () => {
        switch (user.status) {
          case "Declined":
            return staticContent.declined;
          case "Cancelled":
            return staticContent.cancelled;
          default:
            return staticContent.pending;
        }
      };
      return {
        ...user,
        roleType: statusContent(),
        userSince: statusContent(),
        pending: true,
      };
    }),
    ...state.organisationOverview.results[orgId][orgRoleId].users.map((user) => ({
      ...user,
      roleType: user.userRole,
      userSince: user.createdDate,
      pending: false,
    })),
  ];

  const { sortBy } = state.organisationOverview;

  if (sortBy === "userSince") {
    allUsers.sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1));
  } else if (sortBy === "name") {
    allUsers.sort((a, b) =>
      (a.lastName?.toLocaleUpperCase() || "").localeCompare(b.lastName?.toLocaleUpperCase() || "")
    );
  } else {
    allUsers.sort((a, b) => {
      const textA = a[sortBy]?.toLocaleUpperCase() || "";
      const textB = b[sortBy]?.toLocaleUpperCase() || "";
      return (textA as string).localeCompare(textB);
    });
  }

  return allUsers.map(
    ({ firstName, lastName, jobTitle, roleType, pending, userSince, contactId }) => {
      return {
        name: `${firstName} ${lastName}`,
        jobTitle,
        roleType,
        pending,
        userSince,
        contactId,
      };
    }
  );
};

export const getOrganisation = (state: RootState, orgId: string, orgRoleId: string) => {
  const org = state.organisationOverview.results[orgId];
  if (!org) {
    return null;
  }
  return org[orgRoleId].organisation;
};

export const getUserCount = (state: RootState, orgId: string, orgRoleId: string) => {
  const org = state.organisationOverview.results[orgId];
  if (!org) {
    return null;
  }
  return org[orgRoleId].users.length;
};

export const getUserExistingRole = (
  state: RootState,
  orgId: string,
  orgRoleId: string,
  userEmail: string
) => {
  const org = state.organisationOverview.results[orgId];
  if (!org) {
    return null;
  }
  const existingUser = org[orgRoleId].users?.filter((user) => user.email === userEmail)[0];
  return existingUser?.userRole;
};
