import {
  OrganisationOverviewState,
  OrganisationOverviewActionTypes,
  FETCH_ORG_OVERVIEW_REQUEST,
  UPDATE_ORG_SORTBY,
} from "./types";

export const initialState: OrganisationOverviewState = {
  sortBy: "userSince",
  results: {},
};

export default function organisationOverviewReducer(
  state = initialState,
  action: OrganisationOverviewActionTypes
): OrganisationOverviewState {
  switch (action.type) {
    case FETCH_ORG_OVERVIEW_REQUEST.START:
      return initialState;
    case FETCH_ORG_OVERVIEW_REQUEST.SUCCESS:
      return {
        ...state,
        results: {
          ...state.results,
          [action.meta.orgId]: {
            ...state.results[action.meta.orgId],
            [action.meta.orgRoleId]: action.payload,
          },
        },
      };
    case UPDATE_ORG_SORTBY:
      return {
        ...state,
        sortBy: action.sortBy,
      };
    default:
      return state;
  }
}
