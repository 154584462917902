import { UserProfileState, UserProfileActionTypes, FETCH_USER_PROFILE } from "./types";

export const initialState: UserProfileState = {};

export default function userProfileReducer(
  state = initialState,
  action: UserProfileActionTypes
): UserProfileState {
  switch (action.type) {
    case FETCH_USER_PROFILE.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
