import React, { FC } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";

import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ReactQueryClient } from "@acca-portals/shared";
import store, { history } from "../../state/store";
import AppInitialiser from "../AppInitialiser/AppInitialiser";

const App: FC = () => (
  <QueryClientProvider client={ReactQueryClient}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppInitialiser />
      </ConnectedRouter>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default App;
