import { takeLatest, put, call, select } from "redux-saga/effects";
import { ApiService, ICallApi, YieldReturn } from "@acca-portals/shared";
import apiConfig from "../../configs/apiConfig";
import {
  SET_ERROR_CODE,
  SUBMIT_NEW_USER,
  SUBMIT_NEW_USER_ERROR,
  SUBMIT_NEW_USER_SUCCESS,
  SubmitNewUserAction,
} from "./types";
import { getEmail, getFirstname, getLastname, getMessage, getUserRoleId } from "./selectors";
import apiErrors from "../../configs/apiErrors";

function* handleSubmitNewUser({ orgId, orgRoleId, resolve }: SubmitNewUserAction) {
  try {
    const message = yield select(getMessage);
    const firstName = yield select(getFirstname);

    const userDetails: any = {
      lastName: yield select(getLastname),
      emailAddress: yield select(getEmail),
      roleId: yield select(getUserRoleId),
    };
    if (message) userDetails.message = message;
    if (firstName) userDetails.firstName = firstName;

    const requestArgs: ICallApi = {
      url: apiConfig.endpoints.user.addUser(orgId, orgRoleId),
      method: "POST",
      data: userDetails,
    };
    const response: YieldReturn<typeof ApiService.CallApi> = yield call(
      ApiService.CallApi,
      requestArgs
    );
    yield put({
      type: SUBMIT_NEW_USER_SUCCESS,
      payload: response.data,
    });

    return resolve(response.data);
  } catch (error) {
    if (
      error.response.data.code &&
      apiErrors.apiErrors.filter((errorEntry) => errorEntry.id === error.response.data.code)
        .length > 0
    ) {
      yield put({
        type: SET_ERROR_CODE,
        value: error.response.data.code,
      });

      return resolve(error.response.data);
    }
    return yield put({
      type: SUBMIT_NEW_USER_ERROR,
      error,
    });
  }
}

export default function* newUserSagas(): unknown {
  yield takeLatest(SUBMIT_NEW_USER, handleSubmitNewUser);
}
