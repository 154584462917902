import React, { FC } from "react";
import "./user-title.scss";

export interface UserTitleProps {
  username: string;
  timestamp?: string;
}

const UserTitle: FC<UserTitleProps> = ({ username, timestamp }) => {
  return (
    <div className="user-details">
      <h5 className="user-title h600  u-margin-bottom-1">{username}</h5>
      <div className="user-timestamp">{timestamp}</div>
    </div>
  );
};

export default UserTitle;
