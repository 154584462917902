import React, { FC } from "react";
import { LiveAnnouncer } from "react-aria-live";
import {
  ToastNotifications,
  AuthService,
  WithAccessControl,
  NotificationsProvider,
} from "@acca-portals/shared";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";

import Router from "../Router/Router";
import apiConfig from "../../configs/apiConfig";
import { getOrgId, getOrgRoleId } from "../../state/org/selectors";
import Footer from "../../components/Footer/Footer";

const RootPage: FC = () => {
  const { appTimeout } = apiConfig.timeout;
  const orgId = useSelector(getOrgId);
  const orgRoleId = useSelector(getOrgRoleId);

  useIdleTimer({
    timeout: appTimeout,
    onIdle: () => AuthService.logout(),
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  return (
    <LiveAnnouncer>
      <WithAccessControl>
        <NotificationsProvider apiUrl={apiConfig.endpoints.user.notifications(orgId, orgRoleId)}>
          <ToastNotifications />
          <Router />
          <Footer />
        </NotificationsProvider>
      </WithAccessControl>
    </LiveAnnouncer>
  );
};

export default RootPage;
