import React from "react";
import classnames from "classnames";

export interface ILabelProps {
  id: string;
  text: string;
  required?: boolean;
  className?: string;
}

const Label = ({ className, id, text, required }: ILabelProps) => {
  const classes = classnames("form-label", className);

  return (
    <label htmlFor={id} className={classes}>
      {text} {required && <span className="form-label__asterisk">*</span>}
    </label>
  );
};

export default Label;
