import { parseISO, format } from "date-fns";
import DATE_FORMATS from "../constants/dates";

const formatDate = (date: string): string => {
  const dateFormat = DATE_FORMATS.FORMAT_DAY_MONTH_YEAR;
  if (!date) {
    return null;
  }
  return format(parseISO(date), dateFormat);
};

export default formatDate;

export const formatDateMonthYear = (date: Date): string => {
  const dateFormat = DATE_FORMATS.FORMAT_DAY_ABBREVIATEDMONTH_YEAR;
  if (!date) {
    return null;
  }
  return format(date, dateFormat);
};
