import React, { FC } from "react";
import { Card, IconEmail32, BodyCopy, PageHeader, Alert, Anchor } from "acca-design-system";
import { useSelector } from "react-redux";
import HTMLReactParser from "html-react-parser";
import { Section, useContent, useCountries } from "@acca-portals/shared";

import apiConfig from "../../configs/apiConfig";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";
import { IContactDetailsPageContent } from "./types";
import { useContactDetails } from "../../hooks/useContactDetails";
import { getContactId } from "../../state/userProfile/selectors";
import ContactDetailsForm from "./ContactDetailsForm";
import staticContent from "./ContactDetailsPage.content";
import ROUTES from "../../constants/routes";

const ContactDetailsPage: FC = () => {
  const content = useContent<IContactDetailsPageContent>(
    apiConfig.endpoints.templates.contactDetailsPage
  );

  const contactId = useSelector(getContactId);
  const contactDetails = useContactDetails(contactId);
  const countries = useCountries();

  const pageError = content.error || countries.error || contactDetails.error;
  const isPageReady =
    content.isSuccess &&
    countries.isSuccess &&
    contactDetails.isSuccess &&
    !contactDetails.isFetching;

  const renderMain = () => {
    if (pageError || !isPageReady) {
      return null;
    }

    const { pagetitle, pagedescription, emaildescription, emailtitle } = content.data;
    const { phoneNumber, email } = contactDetails.data;
    return (
      <>
        <PageHeader
          className="u-margin-bottom-2"
          pageTitle={pagetitle?.text}
          quote={pagedescription?.text}
        />
        <Section>
          <Card icon={IconEmail32} iconSize="large" className="u-margin-bottom-1">
            <h3 className="section-title">{emailtitle?.text}</h3>
            <BodyCopy>{HTMLReactParser(emaildescription?.value)}</BodyCopy>
            <dl className="u-margin-top-2">
              <dt>{staticContent.emailLabel}</dt>
              <dd>{email}</dd>
            </dl>

            <BodyCopy className="u-margin-top-2 notice">
              <Alert inline>Please note</Alert>
              <p>
                If you&apos;d like to change your email address, please{" "}
                <Anchor to={ROUTES.landingPage} className="nowrap">
                  send us a message
                </Anchor>{" "}
                or call us on{" "}
                <a href="tel:+44 0141 582 2000" className="nowrap">
                  +44 (0)141 582 2000
                </a>
              </p>
            </BodyCopy>
          </Card>

          <ContactDetailsForm content={content.data} phoneNumber={phoneNumber} />
        </Section>
      </>
    );
  };

  return (
    <DefaultPageLayout loading={!isPageReady} error={pageError}>
      {renderMain()}
    </DefaultPageLayout>
  );
};

export default ContactDetailsPage;
