import React, { FC } from "react";
import Parser from "html-react-parser";
import { BodyCopy } from "acca-design-system";
import { AppMessage } from "@acca-portals/shared";
import { useSelector } from "react-redux";

import content from "./PageNotFoundPage.content";
import illustrationSrc from "../../assets/images/illustration-404.svg";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";
import { getOrgRoleId, getOrgId } from "../../state/org/selectors";

const PageNotFoundPage: FC = () => {
  const orgId = useSelector(getOrgId);
  const orgRoleId = useSelector(getOrgRoleId);

  const { heading, description, footer } = content;
  return (
    <DefaultPageLayout titleContentText={heading}>
      <div data-testid="page-not-found-takeover">
        <AppMessage
          title={heading}
          message={description}
          className="u-bg-grey-10"
          buttons={[
            {
              text: "Back to homepage",
              href: `/?orgId=${orgId}&orgRoleId=${orgRoleId}`,
            },
          ]}
          illustrationSrc={illustrationSrc}
          footer={
            <>
              <h3 className="h700 u-font-heading u-margin-bottom-1">{footer.heading}</h3>
              <BodyCopy>
                <p>
                  {Parser(footer.message)}{" "}
                  <a
                    className="u-black"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.accaglobal.com/gb/en/footertoolbar/contact-us.html"
                  >
                    Get help
                  </a>
                  .
                </p>
              </BodyCopy>
            </>
          }
        />
      </div>
    </DefaultPageLayout>
  );
};

export default PageNotFoundPage;
