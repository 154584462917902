import React, { FC, Fragment } from "react";
import "./details-list.scss";
import { sentenceCase } from "@acca-portals/shared";

export interface details {
  email: string;
}
export interface DetailsListProps {
  detailsList: details;
  labels: details;
}
/** Example of labels to be passed to the component * */
/* const labels = {
  email: "Email",
  nationality: "Nationality",
  jobTitle: "Job title",
  countryOfResidence: "County/State",
}; */

const DetailsList: FC<DetailsListProps> = ({ detailsList, labels }) => {
  const result = Object.keys(detailsList).map((key) => ({ key, value: detailsList[key] }));
  return (
    <div className="details-wrapper">
      <dl>
        {result.map((a, i) => {
          const keyText = a.key.replace(/([a-z])([A-Z])/g, `$1 $2`);
          return (
            <Fragment key={i}>
              <dt className="details-wrapper__Label">
                {!a.key ? "" : labels[a.key] || sentenceCase(keyText.toLowerCase())}:
              </dt>
              <dd>{!a.value ? "-" : a.value}</dd>
            </Fragment>
          );
        })}
      </dl>
    </div>
  );
};

export default DetailsList;
