import {
  RESET_USER_DETAILS,
  SUBMIT_EDIT_USER,
  FETCH_USER,
  SET_ROLE,
  SET_ROLE_ID,
  DELETE_USER,
} from "./types";
import actionCreator from "../utility/actionCreator";

const args = ["orgId", "orgRoleId", "contactId"];

export const submitEditUser = actionCreator(
  SUBMIT_EDIT_USER.START,
  ...args,
  "role",
  "roleId",
  "resolve"
);
export const fetchUser = actionCreator(FETCH_USER.START, ...args);
export const setRole = actionCreator(SET_ROLE, "value");
export const setRoleId = actionCreator(SET_ROLE_ID, "value");
export const resetUserDetails = actionCreator(RESET_USER_DETAILS);
export const deleteUser = actionCreator(DELETE_USER.START, ...args, "resolve");
