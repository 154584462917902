import React, { FC, FormEvent, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Parser from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import { Card, Button, PopupManager, IconProfile24 } from "acca-design-system";

import {
  Container,
  PageContentLoader,
  SubmittingMessage,
  replaceTemplates,
} from "@acca-portals/shared";
import staticContent from "./EditUserPage.content";

import { fetchEditUserContent } from "../../state/content/actions";
import { PageContent } from "../../state/content/types";
import { getEditUserContent } from "../../state/content/selectors";
import RoleSelector from "../../components/RoleSelector/RoleSelector";
import DetailsList from "../../components/Details/DetailsList";
import UserTitle from "../../components/UserTitle/UserTitle";

import {
  fetchUser,
  setRole,
  setRoleId,
  submitEditUser,
  resetUserDetails,
  deleteUser,
} from "../../state/editUserDetails/actions";
import {
  getEditUserDetails,
  getJoinedDate,
  getUpdatedUserRole,
  getUpdatedUserRoleId,
  getRole,
  getRoleID,
  getFullName,
} from "../../state/editUserDetails/selectors";
import ROUTES from "../../constants/routes";
import { getOrganisationName } from "../../state/organisationOverview/selectors";
import { fetchOrganisationOverview } from "../../state/organisationOverview/action";
import { isUserAdminForOrganisation } from "../../state/userProfile/selectors";
import formatDate from "../../utilities/format-date";

import "./edit-user.scss";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";

const EditUserPage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { orgId, orgRoleId, contactId } = useParams<{
    orgId: string;
    orgRoleId: string;
    contactId: string;
  }>();

  const content: PageContent = useSelector(getEditUserContent);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const userDetails = useSelector(getEditUserDetails);
  const fullName = useSelector(getFullName);

  const updatedUserRole = useSelector(getUpdatedUserRole);
  const updatedUserRoleId = useSelector(getUpdatedUserRoleId);
  const joinedDate = useSelector(getJoinedDate);
  const orgName = useSelector((state) => getOrganisationName(state, orgId, orgRoleId));
  const isUserAdminForOrg = useSelector((state) => isUserAdminForOrganisation(state, orgName));
  const existingRoleId = useSelector(getRoleID);
  const existingRoleValue = useSelector(getRole);
  const isValid = !!updatedUserRole && !!existingRoleValue && updatedUserRole !== existingRoleValue;

  useEffect(() => {
    if (!content) {
      dispatch(fetchEditUserContent());
    }
    if (!orgName) {
      dispatch(fetchOrganisationOverview(orgId, orgRoleId));
    }
    if (!isDeleted) {
      if (!userDetails.detailsList.email) {
        dispatch(fetchUser(orgId, orgRoleId, contactId));
      }
      if (!existingRoleId) {
        dispatch(fetchUser(orgId, orgRoleId, contactId));
      } else {
        dispatch(setRole(existingRoleValue));
        dispatch(setRoleId(existingRoleId));
      }
    }
    return () => {
      dispatch(setRoleId(null));
    };
  }, [dispatch, existingRoleId]);

  const pageReady = content && userDetails.detailsList.email && orgName && !!existingRoleId;
  if (!pageReady) {
    return <PageContentLoader />;
  }

  if (!isUserAdminForOrg) {
    history.push(`${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`);
  }

  const fullNameTitle = replaceTemplates(content?.heading.text, {
    username: fullName,
  });

  const breadcrumb = [
    {
      text: orgName,
      to: `${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`,
    },
    {
      text: fullName,
      to: `${ROUTES.patchUser}/${orgId}/${orgRoleId}/${contactId}`,
    },
  ];

  const userSubmit = () =>
    new Promise((resolve) =>
      dispatch(
        submitEditUser(orgId, orgRoleId, contactId, updatedUserRole, updatedUserRoleId, resolve)
      )
    );

  const userDelete = () =>
    new Promise((resolve) => {
      setIsDeleted(true);
      dispatch(deleteUser(orgId, orgRoleId, contactId, resolve));
    });

  const handleRoleChange = (e) => {
    dispatch(setRole(e.target.value));
    dispatch(setRoleId(e.target.id));
  };

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    await userSubmit();
    setIsSubmitting(false);
    history.push(`${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`);
  };

  const onFormCancel = async (e: FormEvent) => {
    e.preventDefault();
    dispatch(resetUserDetails());
    history.push(`${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`);
  };

  const onFormRemove = async (e: FormEvent) => {
    e.preventDefault();
    setShowPopup(false);
    setIsSubmitting(true);

    await userDelete();
    setIsSubmitting(false);
    history.push(`${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`);
  };

  return (
    <>
      {!isSubmitting ? (
        <DefaultPageLayout
          titleContentText={fullNameTitle}
          pageTitle={staticContent.pageTitle}
          pageSubTitle={fullNameTitle}
          breadcrumb={breadcrumb}
        >
          <Container>
            <Card
              icon={IconProfile24}
              className="u-padding-bottom-2 u-margin-top-0 u-bg-white u-full-browser-width u-margin-left-1 u-margin-right-1"
            >
              <UserTitle
                username={fullName}
                timestamp={replaceTemplates(staticContent.userTitleTimestamp, {
                  joinedDate: formatDate(joinedDate),
                })}
              />
              <div className="u-content-container-medium">
                <DetailsList detailsList={userDetails.detailsList} labels={userDetails.labels} />
                <form autoComplete="off" className="u-margin-bottom-3">
                  <p className="u-padding-bottom-1 u-font-bold">{staticContent.userRoleLabel}</p>
                  <RoleSelector onChange={handleRoleChange} stateSelector={getUpdatedUserRole} />
                </form>
              </div>
              <Button
                text={staticContent.submitButtonLabel}
                onClick={onFormSubmit}
                disabled={!isValid}
                type="submit"
                className="u-margin-right-2"
              />
              <Button
                text={staticContent.cancelButtonLabel}
                type="button"
                onClick={onFormCancel}
                variant="link"
              />
              <PopupManager
                showPopup={showPopup}
                CustomTrigger={({ forwardedRef }) => (
                  <Button
                    className="button--right"
                    forwardedRef={forwardedRef}
                    variant="outlined"
                    onClick={() => {
                      setShowPopup(true);
                    }}
                    text={staticContent.removeButtonLabel}
                  />
                )}
                handleTogglePopup={() => setShowPopup(!showPopup)}
                closePopup={() => setShowPopup(false)}
                position="top"
                showCloseButton
              >
                <div className="u-margin-bottom-1">
                  <h4 className="u-margin-bottom-1">{content?.title.text}</h4>
                  <div>{Parser(content?.description.value)}</div>
                </div>
                <Button
                  text={staticContent.removeButtonLabel}
                  onClick={onFormRemove}
                  variant="primary"
                />
              </PopupManager>
            </Card>
          </Container>
        </DefaultPageLayout>
      ) : (
        <SubmittingMessage title="Saving" />
      )}
    </>
  );
};

export default EditUserPage;
