import React, { FC } from "react";
import Lottie from "react-lottie";
import SplitText from "react-pose-text";
import posed, { PoseGroup } from "react-pose";
import AfternoonAnimation from "../../assets/lotties/afternoon.json";
import EveningAnimation from "../../assets/lotties/evening.json";
import MorningAnimation from "../../assets/lotties/morning.json";
import { isMorning, isAfternoon } from "../../utilities/dateUtils";
import "./greetings-takeover.scss";
import { getGreeting } from "../WelcomeMessage/WelcomeMessage";

const MessageAnimation = posed.h1({
  enter: { y: 0, opacity: 1 },
  exit: { y: -100, opacity: 0, transition: { duration: 500, ease: "easeIn" } },
});

const IllustrationAnimation = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0, transition: { duration: 250, ease: "easeIn" } },
});

const ContainerAnimation = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0, delay: 500, transition: { duration: 0 } },
});

const getAnimation = () => {
  const now = new Date(Date.now());
  if (isMorning(now)) {
    return MorningAnimation;
  }
  if (isAfternoon(now)) {
    return AfternoonAnimation;
  }
  return EveningAnimation;
};

export interface GreetingsTakeoverProps {
  firstName?: string;
  fadeOut?: boolean;
}

const GreetingsTakeover: FC<GreetingsTakeoverProps> = ({ firstName, fadeOut }) => {
  const perChar = firstName ? 300 / firstName.length : 1;
  const charPoses = {
    exit: { opacity: 0, delay: 1000 },
    enter: {
      opacity: 1,
      delay: ({ charIndex }) => charIndex * perChar,
    },
  };

  return (
    <div className="greetings-takeover">
      <PoseGroup>
        {!fadeOut && (
          <ContainerAnimation key="gt-container" className="greetings-takeover__content">
            <IllustrationAnimation key="gt-illustration" className="greetings-takeover__animation">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: getAnimation(),
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                width={260}
                height={200}
              />
            </IllustrationAnimation>
            <MessageAnimation
              key="gt-message"
              className="greetings-takeover__message h500 u-font-normal u-margin-top-2"
            >
              {getGreeting()}
              {firstName && (
                <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
                  {`, ${firstName}`}
                </SplitText>
              )}
            </MessageAnimation>
          </ContainerAnimation>
        )}
      </PoseGroup>
    </div>
  );
};

export default GreetingsTakeover;
