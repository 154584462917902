import React, { FC, FormEvent, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  PageContentLoader,
  SubmittingMessage,
  replaceTemplates,
} from "@acca-portals/shared";
import { Button, Card, PopupManager, IconProfile24 } from "acca-design-system";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import UserTitle from "../../components/UserTitle/UserTitle";
import staticContent from "./PendingUserPage.content";

import ROUTES from "../../constants/routes";

import formatDate from "../../utilities/format-date";
import { PageContent } from "../../state/content/types";
import { getPendingUserContent } from "../../state/content/selectors";
import { fetchPendingUserContent } from "../../state/content/actions";
import { fetchOrganisationOverview } from "../../state/organisationOverview/action";
import { getOrganisationName } from "../../state/organisationOverview/selectors";
import { getPendingUserInvitation } from "../../state/pendingUser/selectors";
import DetailsList from "../../components/Details/DetailsList";
import { deleteInvite, resendInvite } from "../../state/pendingUser/actions";
import "./pending-user.scss";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";

const PendingUser: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const content: PageContent = useSelector(getPendingUserContent);
  const { orgId, orgRoleId, contactId } = useParams<{
    orgId: string;
    orgRoleId: string;
    contactId: string;
  }>();
  const [showPopup, setShowPopup] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const orgName = useSelector((state) => getOrganisationName(state, orgId, orgRoleId));
  const invitation = useSelector((state) =>
    getPendingUserInvitation(state, orgId, orgRoleId, contactId)
  );

  useEffect(() => {
    if (!content) {
      dispatch(fetchPendingUserContent());
    }

    if (!orgName) {
      dispatch(fetchOrganisationOverview(orgId, orgRoleId));
    }
  }, [contactId, content, dispatch, orgId, orgName, orgRoleId]);

  const pageReady = content && orgName;
  if (!pageReady) {
    return <PageContentLoader />;
  }

  const fullName = `${invitation.firstName} ${invitation.lastName}`;
  const fullNameTitle = replaceTemplates(content?.heading.text, {
    username: fullName,
  });

  const breadcrumb = [
    {
      text: orgName,
      to: `${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`,
    },
    {
      text: fullName,
      to: `${ROUTES.pendingUser}/${orgId}/${orgRoleId}/${contactId}`,
    },
  ];

  const userDetails = invitation && {
    detailsList: {
      email: invitation?.email,
      firstname: invitation?.firstName,
      lastname: invitation?.lastName,
      userrole: invitation?.userRole,
    },
    labels: {
      email: "Email",
      firstname: "First name",
      lastname: "Last name",
      userrole: "User role",
    },
  };

  const resendInvitation = () =>
    new Promise((resolve) =>
      dispatch(resendInvite(orgId, orgRoleId, invitation?.requestId, resolve))
    );

  const onResendInvitation = async (e: FormEvent) => {
    e.preventDefault();
    setIsResending(true);

    await resendInvitation();

    toast.success(
      replaceTemplates(staticContent.resendToastMessage, {
        email: `${invitation?.email}`,
      })
    );
    setIsResending(false);
    history.push(`${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`);
  };

  const inviteDelete = () =>
    new Promise((resolve) =>
      dispatch(deleteInvite(orgId, orgRoleId, invitation?.requestId, resolve))
    );

  const onDeleteInvitation = async (e: FormEvent) => {
    e.preventDefault();
    setShowPopup(false);
    setIsDeleting(true);

    await inviteDelete();

    toast.error(staticContent.cancelToastMessage);
    setIsDeleting(false);
    history.push(`${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`);
  };

  return (
    <DefaultPageLayout
      titleContentText={fullNameTitle}
      pageTitle={staticContent.pageTitle}
      pageSubTitle={fullNameTitle}
      breadcrumb={breadcrumb}
    >
      <>
        {!isDeleting && !isResending ? (
          <Container>
            <Card
              icon={IconProfile24}
              className="u-padding-bottom-2 u-margin-top-0 u-bg-white u-full-browser-width u-margin-left-1 u-margin-right-1"
            >
              <div className="pending-user-details">
                <UserTitle
                  username={fullName}
                  timestamp={replaceTemplates(staticContent.userTitleTimestamp, {
                    joinedDate: formatDate(invitation?.createdDate),
                  })}
                />
                <div className="u-content-container-medium">
                  <DetailsList
                    detailsList={userDetails?.detailsList}
                    labels={userDetails?.labels}
                  />
                </div>
                <div className="pending-user-details-buttons">
                  <Button
                    text={staticContent.resendInvitationButtonLabel}
                    onClick={onResendInvitation}
                    type="submit"
                    className="u-margin-right-2"
                  />
                  <PopupManager
                    showPopup={showPopup}
                    CustomTrigger={({ forwardedRef }) => (
                      <Button
                        forwardedRef={forwardedRef}
                        variant="link"
                        className="u-margin-top-1"
                        onClick={() => {
                          setShowPopup(true);
                        }}
                        text={staticContent.cancelInviteButtonLabel}
                      />
                    )}
                    handleTogglePopup={() => setShowPopup(!showPopup)}
                    closePopup={() => setShowPopup(false)}
                    position="top"
                    showCloseButton
                  >
                    <div className="u-margin-bottom-1">
                      <div>{staticContent.cancelPopupDescription}</div>
                    </div>
                    <Button
                      text={staticContent.cancelInvitePopupButtonLabel}
                      onClick={onDeleteInvitation}
                      variant="primary"
                    />
                  </PopupManager>
                </div>
              </div>
            </Card>
          </Container>
        ) : (
          <SubmittingMessage title={isDeleting ? "Deleting" : "Resending"} />
        )}
      </>
    </DefaultPageLayout>
  );
};

export default PendingUser;
