import React, { FC } from "react";
import classnames from "classnames";
import { Card, Button, IconClose16, IconEdit16 } from "acca-design-system";

import "./form-section.scss";

export interface IFormSectionProps {
  isEditMode?: boolean;
  className?: string;
  icon?: React.ReactNode;
  onEditToggleClick?: () => void;
}

// TODO: Move to Shared Portal Components when fully polished
const FormSection: FC<IFormSectionProps> = ({
  isEditMode,
  icon,
  className,
  children,
  onEditToggleClick,
}) => {
  const classes = classnames(
    "form-section",
    { "form-section--is-edit-mode": isEditMode },
    className
  );

  return (
    <Card icon={icon} iconSize="large" className={classes}>
      <Button
        text={isEditMode ? "Cancel" : "Edit"}
        onClick={onEditToggleClick}
        variant="link"
        icon={isEditMode ? IconClose16 : IconEdit16}
        className="form-section__edit-button"
      />
      {children}
    </Card>
  );
};

export default FormSection;
