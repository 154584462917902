import {
  fetchUserRolesAction,
  FETCH_USER_ROLES,
  fetchQuoteDeclinedReasonsAction,
  FETCH_QUOTE_DECLINE_REASONS,
  SubmitDeclineReasonsAction,
  SUBMIT_QUOTE_DECLINE_REASONS,
  SetDeclineMessageAction,
  SetDeclineReasonsAction,
  SET_DECLINE_MESSAGE,
  SET_DECLINE_REASONS,
  SetQuoteNumberAction,
  SET_QUOTE_NUMBER,
  SET_QUOTE_ID,
  SetQuoteIdAction,
  SubmitQuoteAcceptedAction,
  SUBMIT_QUOTE_ACCEPTED,
  ResetDeclineReasonsAction,
  RESET_DECLINE_REASONS,
  ResetDeclineMessageAction,
  RESET_DECLINE_MESSAGE,
  SET_DECLINE_ERROR,
  SetDeclineErrorAction,
  RESET_DECLINE_ERROR,
  ResetDeclineErrorAction,
} from "./types";

export function fetchUserRoles(): fetchUserRolesAction {
  return {
    type: FETCH_USER_ROLES,
  };
}
export function fetchQuoteDeclinedReasons(): fetchQuoteDeclinedReasonsAction {
  return {
    type: FETCH_QUOTE_DECLINE_REASONS.START,
  };
}

export function SubmitQuoteDeclinedReasons(
  resolve?: (response: unknown) => void
): SubmitDeclineReasonsAction {
  return {
    type: SUBMIT_QUOTE_DECLINE_REASONS.START,
    resolve,
  };
}
export function SetDeclineMessage(value: string): SetDeclineMessageAction {
  return {
    type: SET_DECLINE_MESSAGE,
    value,
  };
}

export function SetDeclineReasons(value: number[]): SetDeclineReasonsAction {
  return {
    type: SET_DECLINE_REASONS,
    value,
  };
}

export function SetQuoteNumber(value: string): SetQuoteNumberAction {
  return {
    type: SET_QUOTE_NUMBER,
    value,
  };
}
export function SetQuoteId(value: string): SetQuoteIdAction {
  return {
    type: SET_QUOTE_ID,
    value,
  };
}

export function SubmitQuoteAccepted(
  resolve?: (response: unknown) => void
): SubmitQuoteAcceptedAction {
  return {
    type: SUBMIT_QUOTE_ACCEPTED.START,
    resolve,
  };
}

export function resetDeclineMessage(): ResetDeclineMessageAction {
  return {
    type: RESET_DECLINE_MESSAGE,
  };
}
export function resetDeclineReasons(): ResetDeclineReasonsAction {
  return {
    type: RESET_DECLINE_REASONS,
  };
}
export function setDeclineError(value: string[]): SetDeclineErrorAction {
  return {
    type: SET_DECLINE_ERROR,
    value,
  };
}
export function resetDeclineError(): ResetDeclineErrorAction {
  return {
    type: RESET_DECLINE_ERROR,
  };
}
