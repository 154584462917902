import { AppErrorState, AppErrorActionTypes, RESET_APP_ERROR } from "./types";

export const initialState: AppErrorState = {};

export default function errorReducer(
  state = initialState,
  action: AppErrorActionTypes
): AppErrorState {
  if (action.type === RESET_APP_ERROR) {
    return initialState;
  }
  const { error } = action;
  if (error) {
    return {
      error,
    };
  }

  return state;
}
