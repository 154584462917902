export const FETCH_LANDING_PAGE_CONTENT = "CONTENT/FETCH_LANDING_PAGE_CONTENT";
export const FETCH_LANDING_PAGE_CONTENT_SUCCESS = "CONTENT/FETCH_LANDING_PAGE_CONTENT_SUCCESS";
export const FETCH_LANDING_PAGE_CONTENT_ERROR = "CONTENT/FETCH_LANDING_PAGE_CONTENT_ERROR";

export const FETCH_ORG_LISTING_CONTENT = "CONTENT/FETCH_ORG_LISTING_CONTENT";
export const FETCH_ORG_LISTING_CONTENT_SUCCESS = "CONTENT/FETCH_ORG_LISTING_CONTENT_SUCCESS";
export const FETCH_ORG_LISTING_CONTENT_ERROR = "CONTENT/FETCH_ORG_LISTING_CONTENT_ERROR";

export const FETCH_ADD_USER_CONTENT = "CONTENT/FETCH_ADD_USER_CONTENT";
export const FETCH_ADD_USER_CONTENT_SUCCESS = "CONTENT/FETCH_ADD_USER_CONTENT_SUCCESS";
export const FETCH_ADD_USER_CONTENT_ERROR = "CONTENT/FETCH_ADD_USER_CONTENT_ERROR";

export const FETCH_EDIT_USER_CONTENT = "CONTENT/FETCH_EDIT_USER_CONTENT";
export const FETCH_EDIT_USER_CONTENT_SUCCESS = "CONTENT/FETCH_EDIT_USER_CONTENT_SUCCESS";
export const FETCH_EDIT_USER_CONTENT_ERROR = "CONTENT/FETCH_EDIT_USER_CONTENT_ERROR";

export const FETCH_PENDING_USER_CONTENT = "CONTENT/FETCH_PENDING_USER_CONTENT";
export const FETCH_PENDING_USER_CONTENT_SUCCESS = "CONTENT/FETCH_PENDING_USER_CONTENT_SUCCESS";
export const FETCH_PENDING_USER_CONTENT_ERROR = "CONTENT/FETCH_PENDING_USER_CONTENT_ERROR";

export const FETCH_REVIEW_USER_CONTENT = "CONTENT/FETCH_REVIEW_USER_CONTENT";
export const FETCH_REVIEW_USER_CONTENT_SUCCESS = "CONTENT/FETCH_REVIEW_USER_CONTENT_SUCCESS";
export const FETCH_REVIEW_USER_CONTENT_ERROR = "CONTENT/FETCH_REVIEW_USER_CONTENT_ERROR";

export const FETCH_CATEGORIES_CONTENT = "CONTENT/FETCH_CATEGORIES_CONTENT";
export const FETCH_CATEGORIES_CONTENT_SUCCESS = "CONTENT/FETCH_CATEGORIES_CONTENT_SUCCESS";
export const FETCH_CATEGORIES_CONTENT_ERROR = "CONTENT/FETCH_CATEGORIES_CONTENT_ERROR";

export const FETCH_PRODUCTS_CONTENT = "CONTENT/FETCH_PRODUCTS_CONTENT";
export const FETCH_PRODUCTS_CONTENT_SUCCESS = "CONTENT/FETCH_PRODUCTS_CONTENT_SUCCESS";
export const FETCH_PRODUCTS_CONTENT_ERROR = "CONTENT/FETCH_PRODUCTS_CONTENT_ERROR";

export interface ContentState {
  landingPage?: PageContent;
  orgListing?: PageContent;
  addUser?: PageContent;
  editUser?: PageContent;
  pendingUser?: PageContent;
  reviewUser?: PageContent;
  categories?: PageContent;
  products?: PageContent;
}

export interface PageContent {
  graphic?: {
    url: string;
  };
  heading?: {
    text: string;
  };
  title?: {
    text: string;
  };
  description?: {
    value: string;
    richText?: string;
  };
}

export interface FetchLandingPageContentAction {
  type: typeof FETCH_LANDING_PAGE_CONTENT;
}
export interface FetchLandingPageContentSuccessAction {
  type: typeof FETCH_LANDING_PAGE_CONTENT_SUCCESS;
  payload: PageContent;
}

export interface FetchOrganisationsListingContentAction {
  type: typeof FETCH_ORG_LISTING_CONTENT;
}
export interface FetchOrganisationsListingContentSuccessAction {
  type: typeof FETCH_ORG_LISTING_CONTENT_SUCCESS;
  payload: PageContent;
}

export interface FetchAddUserContentAction {
  type: typeof FETCH_ADD_USER_CONTENT;
}
export interface FetchAddUserContentSuccessAction {
  type: typeof FETCH_ADD_USER_CONTENT_SUCCESS;
  payload: PageContent;
}

export interface FetchEditUserContentAction {
  type: typeof FETCH_EDIT_USER_CONTENT;
}
export interface FetchEditUserContentSuccessAction {
  type: typeof FETCH_EDIT_USER_CONTENT_SUCCESS;
  payload: PageContent;
}

export interface FetchPendingUserContentAction {
  type: typeof FETCH_PENDING_USER_CONTENT;
}
export interface FetchPendingUserContentSuccessAction {
  type: typeof FETCH_PENDING_USER_CONTENT_SUCCESS;
  payload: PageContent;
}

export interface FetchReviewUserContentAction {
  type: typeof FETCH_REVIEW_USER_CONTENT;
}
export interface FetchReviewUserContentSuccessAction {
  type: typeof FETCH_REVIEW_USER_CONTENT_SUCCESS;
  payload: PageContent;
}

export interface FetchCategoriesContentAction {
  type: typeof FETCH_CATEGORIES_CONTENT;
}
export interface FetchCategoriesContentSuccessAction {
  type: typeof FETCH_CATEGORIES_CONTENT_SUCCESS;
  payload: PageContent;
}

export interface FetchProductsContentAction {
  type: typeof FETCH_PRODUCTS_CONTENT;
}
export interface FetchProductsContentSuccessAction {
  type: typeof FETCH_PRODUCTS_CONTENT_SUCCESS;
  payload: PageContent;
}

export type ContentActionTypes =
  | FetchLandingPageContentAction
  | FetchLandingPageContentSuccessAction
  | FetchOrganisationsListingContentAction
  | FetchOrganisationsListingContentSuccessAction
  | FetchAddUserContentAction
  | FetchAddUserContentSuccessAction
  | FetchEditUserContentAction
  | FetchEditUserContentSuccessAction
  | FetchPendingUserContentAction
  | FetchPendingUserContentSuccessAction
  | FetchReviewUserContentAction
  | FetchReviewUserContentSuccessAction
  | FetchCategoriesContentAction
  | FetchCategoriesContentSuccessAction
  | FetchProductsContentAction
  | FetchProductsContentSuccessAction;
