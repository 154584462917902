const apiErrors = {
  apiErrors: [
    {
      id: "BE-101-002",
      name: "isEmailValid",
    },
    {
      id: "BE-101-001",
      name: "userAlreadyExists",
    },
    {
      id: "BE-101-006",
      name: "userAlreadyExists",
    },
  ],
};

export default apiErrors;
