import React, { FC, useEffect, useState } from "react";
import { BodyCopy, Checkbox } from "acca-design-system";
import { useDispatch, useSelector } from "react-redux";
import { PageContentLoader } from "@acca-portals/shared";
import {
  fetchQuoteDeclinedReasons,
  SetDeclineReasons,
  SetDeclineMessage,
  setDeclineError,
} from "../../../../state/lookups/actions";
import {
  getQuoteDeclinedReasons,
  getDeclinedMessage,
  getSelectedReasons,
} from "../../../../state/lookups/selectors";
import staticContent from "./DeclineModal.content";
import InputWithValidation, {
  Validations,
} from "../../../../components/InputWithValidation/InputWithValidation";
import { isAlphanumericValue, isMaximumLength } from "../../../../utilities/validation-rules";

import "./declineModal.scss";

export interface DeclineModalProps {
  isSubmitting?: boolean;
}

const DeclineModal: FC<DeclineModalProps> = ({ isSubmitting }) => {
  const dispatch = useDispatch();
  const declineReasons = useSelector(getQuoteDeclinedReasons);
  const declinedMessage = useSelector(getDeclinedMessage);
  const selectedReasons = useSelector(getSelectedReasons);
  const [invalidFields, setInvalidFields] = useState([]);
  const handleChange = (e) => {
    if (selectedReasons.indexOf(e.target.value) === -1) {
      dispatch(SetDeclineReasons([...selectedReasons, e.target.value]));
    } else {
      dispatch(SetDeclineReasons(selectedReasons.filter((reasons) => reasons !== e.target.value)));
    }
  };
  const handleMessageChange = (e) => {
    dispatch(SetDeclineMessage(e.target.value));
  };
  const onFieldValidate = (errors: Validations, fieldId: string) => {
    if (errors.length === 0) {
      return setInvalidFields(invalidFields.filter((id) => id !== fieldId));
    }
    return setInvalidFields((prevState) => prevState.concat([fieldId]));
  };
  useEffect(() => {
    if (!declineReasons) {
      dispatch(fetchQuoteDeclinedReasons());
    }
    if (invalidFields) {
      dispatch(setDeclineError(invalidFields));
    }
  }, [dispatch, declineReasons, invalidFields]);

  const pageReady = declineReasons;
  if (!pageReady) {
    return <PageContentLoader />;
  }

  return (
    <>
      <form id="Decline-form" className="declineQuote">
        <h1 className="u-font-heading h400 u-margin-bottom-2 u-text-center">
          {staticContent.title}
        </h1>
        <h2 className="h800 u-margin-bottom-1">{staticContent.reasonHeading}</h2>
        <p className="u-margin-bottom-1">{staticContent.optionsHeading}</p>
        <BodyCopy className="u-margin-bottom-3">
          <div className="checkboxList">
            {declineReasons && (
              <>
                {declineReasons.map((list, i) => {
                  return (
                    <Checkbox
                      key={i}
                      id={list.id}
                      value={list.id}
                      text={list.value}
                      checked={selectedReasons.map(Number).indexOf(list.id) > -1}
                      onChange={handleChange}
                    />
                  );
                })}
              </>
            )}
          </div>
          <InputWithValidation
            id="message"
            type="textarea"
            labelText={staticContent?.messageHeading}
            onChange={handleMessageChange}
            value={declinedMessage}
            validations={[isAlphanumericValue, isMaximumLength]}
            onValidate={onFieldValidate}
          />
        </BodyCopy>
      </form>
    </>
  );
};

export default DeclineModal;
