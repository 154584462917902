import { takeLatest, call, select } from "redux-saga/effects";
import { ICallApi } from "@acca-portals/shared";
import apiConfig from "../../configs/apiConfig";
import {
  FETCH_REVIEW_USER_REQUEST,
  FetchReviewUserAction,
  ACCEPT_REVIEW_USER_REQUEST,
  AcceptReviewUserAction,
  DeclineReviewUserAction,
  DECLINE_REVIEW_USER_REQUEST,
} from "./types";
import { getReviewUserRoleId, getReviewUserRoleName, getReviewUserDeclineMsg } from "./selectors";
import { useSaga } from "../utility/hooks/useSaga";

function* handlefetchReviewUser({ orgId, orgRoleId, requestId }: FetchReviewUserAction) {
  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.user.reviewUser(orgId, orgRoleId, requestId),
    method: "GET",
  };

  yield call(
    useSaga,
    [FETCH_REVIEW_USER_REQUEST.success, FETCH_REVIEW_USER_REQUEST.error],
    requestArgs
  );
}

function* handleAcceptReviewUser({
  orgId,
  orgRoleId,
  requestId,
  timeout,
  resolve,
}: AcceptReviewUserAction) {
  const reviewDetails: any = {
    status: "Approved",
    role: {
      id: yield select(getReviewUserRoleId),
      name: yield select(getReviewUserRoleName),
    },
  };

  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.user.reviewUser(orgId, orgRoleId, requestId),
    method: "PATCH",
    data: reviewDetails,
  };

  yield call(
    useSaga,
    [ACCEPT_REVIEW_USER_REQUEST.success, ACCEPT_REVIEW_USER_REQUEST.error],
    requestArgs,
    resolve,
    null,
    timeout
  );
}

function* handleDeclineReviewUser({
  orgId,
  orgRoleId,
  requestId,
  resolve,
}: DeclineReviewUserAction) {
  const reviewDetails: any = {
    message: yield select(getReviewUserDeclineMsg),
  };

  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.user.reviewUser(orgId, orgRoleId, requestId),
    method: "DELETE",
    data: reviewDetails,
  };

  yield call(
    useSaga,
    [DECLINE_REVIEW_USER_REQUEST.success, DECLINE_REVIEW_USER_REQUEST.error],
    requestArgs,
    resolve
  );
}

export default function* revieweUserSagas(): unknown {
  yield takeLatest(FETCH_REVIEW_USER_REQUEST.START, handlefetchReviewUser);
  yield takeLatest(ACCEPT_REVIEW_USER_REQUEST.START, handleAcceptReviewUser);
  yield takeLatest(DECLINE_REVIEW_USER_REQUEST.START, handleDeclineReviewUser);
}
