export const RESET_APP_ERROR = "APP_ERROR/RESET";

export interface AppErrorState {
  error?: null | Error;
}

export interface ResetAppErrorAction {
  type: typeof RESET_APP_ERROR;
  error?: Error;
}

export interface ErrorAction {
  type: string;
  error?: Error;
}

export type AppErrorActionTypes = ResetAppErrorAction | ErrorAction;
