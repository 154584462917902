import React, { FC, useEffect, useState } from "react";
import {
  Checkbox,
  ToggleControlGroup,
  FormField,
  Select,
  Button,
  IconAdd16,
  Card,
  IconClose16,
  Alert,
} from "acca-design-system";
import { useDispatch, useSelector } from "react-redux";
import { getContactId, getUserName, getUserEmail } from "../../state/userProfile/selectors";
import {
  SetTotalSignatories,
  FetchAgreementSignatories,
  SetAgreementError,
  SetSelectedSignatories,
} from "../../state/agreementsAndQuotes/actions";
import {
  getAgreementSignatories,
  getAgreementError,
  getSelectedSignatories,
} from "../../state/agreementsAndQuotes/selectors";
import InputWithValidation, { Validations } from "../InputWithValidation/InputWithValidation";
import { isAlphanumericValue, isNotEmpty, isEmail } from "../../utilities/validation-rules";
import staticContent from "./AgreementAcceptModal.content";
import "./agreement-accept-modal.scss";
import { getOrgId } from "../../state/org/selectors";
import REQUIRED_SIGNATORIES from "../../constants/requiredSignatories";

const AgreementAcceptModal: FC = () => {
  const dispatch = useDispatch();
  const primaryContactId = useSelector(getContactId);
  const userName = useSelector(getUserName);
  const userEmail = useSelector(getUserEmail);
  const agreementSignatories = useSelector(getAgreementSignatories);
  const orgId = useSelector(getOrgId);
  const [existingSignatories, setExistingSignatories] = useState([]);
  const [selected, setSelected] = useState([]);
  const [additionalSignatories, setAdditionalSignatories] = useState([]);
  const [invalidFields, setInvalidFields] = useState([]);
  const agreementError = useSelector(getAgreementError);
  const selectedSignatories = useSelector(getSelectedSignatories);

  const onFieldValidate = (errors: Validations, fieldId: string) => {
    if (errors.length === 0) {
      return setInvalidFields(invalidFields.filter((id) => id !== fieldId));
    }
    return setInvalidFields((prevState) => prevState.concat([fieldId]));
  };
  const handleMultiChange = (i, e) => {
    const newFormValues = [...additionalSignatories];
    newFormValues[i][e.target.id] = e.target.value;
    setAdditionalSignatories(newFormValues);
  };

  const addFormFields = () => {
    setAdditionalSignatories([
      ...additionalSignatories,
      { firstName: "", lastName: "", email: "" },
    ]);
  };

  const removeFormFields = (i) => {
    const newFormValues = [...additionalSignatories];
    newFormValues.splice(i, 1);
    setAdditionalSignatories(newFormValues);
  };

  const handleChange = (e) => {
    if (selected.indexOf(e.target.value) === -1) {
      setSelected([...selected, e.target.value]);
    } else {
      setSelected(selected.filter((s) => s !== e.target.value));
    }
  };
  const signatories = [
    ...selected.map((user) => ({ contactId: user, name: userName, email: userEmail })),
    ...existingSignatories.map(({ value, label, subLabel }) => ({
      contactId: value,
      name: label,
      email: subLabel,
    })),
    ...additionalSignatories,
  ];

  const duplicatesFilter = signatories.filter(
    (item, index, array) =>
      array.findIndex((user) => user.email === item.email.toLowerCase()) === index
  );

  let filteredSignatories = [];

  if (duplicatesFilter.length >= 1) {
    filteredSignatories = duplicatesFilter.map((user) =>
      user.contactId
        ? { contactId: user.contactId }
        : { firstName: user.firstName, lastName: user.lastName, email: user.email }
    );
  }

  useEffect(() => {
    dispatch(FetchAgreementSignatories(orgId));
  }, [dispatch]);

  useEffect(() => {
    if (invalidFields) {
      dispatch(SetAgreementError(invalidFields));
    }
    if (selected || existingSignatories || additionalSignatories) {
      dispatch(SetTotalSignatories(filteredSignatories));
      dispatch(SetSelectedSignatories(signatories.length));
    }
  }, [dispatch, invalidFields, selected, existingSignatories, additionalSignatories]);

  const option1 = {
    id: primaryContactId,
    value: primaryContactId,
    text: userName,
  };

  const args = {
    id: "my-select",
    multiple: true,
    options: agreementSignatories.map((user) => ({
      label: user.firstName,
      subLabel: user.email,
      value: user.contactId,
    })),
    placeholder: staticContent.dropdownPlaceholder,
  };
  const isAgreementAcceptValid =
    selectedSignatories < REQUIRED_SIGNATORIES.REQUIRED_AGREEMENT_SIGNATORIES;

  return (
    <>
      <form id="AgreementReject-form" className="agreement-accept-modal">
        <h1 className="u-font-heading h500 u-margin-bottom-2 u-text-center">
          {staticContent.title}
        </h1>
        <h4>{staticContent.messageHeading}</h4>
        <Card className="u-margin-top-2 u-margin-bottom-2">
          <ToggleControlGroup inline>
            <Checkbox
              checked={selected.indexOf(option1.value) > -1}
              onChange={handleChange}
              boxed
              {...option1}
            />
          </ToggleControlGroup>
          <FormField>
            <label htmlFor="my-select" className="form-label u-margin-top-2">
              {staticContent.selectSignatories}
            </label>
            <Select
              {...args}
              value={existingSignatories}
              onChange={(e) => {
                setExistingSignatories(e);
              }}
            />
          </FormField>

          {additionalSignatories.map((element, index) => (
            <div>
              {index ? null : (
                <h4 className="u-margin-top-2">{staticContent.additionalSignatories}</h4>
              )}
              <div
                className="agreement-accept-modal__form-block u-padding-2 u-margin-top-2 u-margin-bottom-2"
                key={index}
              >
                <Button
                  className="agreement-accept-modal__close"
                  text="close"
                  iconPosition="right"
                  icon={IconClose16}
                  variant="link"
                  onClick={() => removeFormFields(index)}
                />
                <FormField>
                  <InputWithValidation
                    id="firstName"
                    type="text"
                    labelText="FirstName"
                    validations={[isAlphanumericValue, isNotEmpty]}
                    onValidate={onFieldValidate}
                    value={element.firstName || ""}
                    onChange={(e) => handleMultiChange(index, e)}
                  />
                </FormField>
                <FormField>
                  <InputWithValidation
                    id="lastName"
                    type="text"
                    labelText="LastName"
                    validations={[isAlphanumericValue, isNotEmpty]}
                    onValidate={onFieldValidate}
                    value={element.lastName || ""}
                    onChange={(e) => handleMultiChange(index, e)}
                  />
                </FormField>
                <FormField>
                  <InputWithValidation
                    id="email"
                    type="text"
                    labelText="Email address"
                    validations={[isNotEmpty, isEmail]}
                    onValidate={onFieldValidate}
                    value={element.email || ""}
                    onChange={(e) => handleMultiChange(index, e)}
                  />
                </FormField>
              </div>
            </div>
          ))}
        </Card>
        {isAgreementAcceptValid && (
          <Button
            iconPosition="left"
            icon={IconAdd16}
            text={staticContent.buttonCTA}
            variant="link"
            onClick={() => addFormFields()}
          />
        )}
        {selectedSignatories &&
          selectedSignatories >= REQUIRED_SIGNATORIES.REQUIRED_AGREEMENT_SIGNATORIES && (
            <Alert type="info">
              <p>{staticContent.acceptModalInfoMessage}</p>
            </Alert>
          )}
      </form>
    </>
  );
};

export default AgreementAcceptModal;
