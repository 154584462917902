import {
  NewUserDetailsState,
  NewUserActionTypes,
  SUBMIT_NEW_USER_SUCCESS,
  SUBMIT_NEW_USER_ERROR,
  SET_FIRSTNAME,
  SET_LASTNAME,
  SET_EMAIL,
  SET_ROLE,
  SET_MESSAGE,
  RESET_USER_DETAILS,
  SET_ROLE_ID,
  SET_ERROR_CODE,
  RESET_ERROR_CODE,
} from "./types";

export const initialState: NewUserDetailsState = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  role: "",
  roleId: "",
  message: "",
  errorCode: "",
};

export default function newUserDetailsReducer(
  state = initialState,
  action: NewUserActionTypes
): NewUserDetailsState {
  switch (action.type) {
    case SUBMIT_NEW_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SUBMIT_NEW_USER_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_FIRSTNAME:
      return {
        ...state,
        firstName: action.value,
      };
    case SET_LASTNAME:
      return {
        ...state,
        lastName: action.value,
      };
    case SET_EMAIL:
      return {
        ...state,
        emailAddress: action.value,
      };
    case SET_ROLE:
      return {
        ...state,
        role: action.value,
      };
    case SET_ROLE_ID:
      return {
        ...state,
        roleId: action.value,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.value,
      };
    case RESET_USER_DETAILS:
      return initialState;
    case SET_ERROR_CODE:
      return {
        ...state,
        errorCode: action.value,
      };
    case RESET_ERROR_CODE:
      return {
        ...state,
        errorCode: null,
      };
    default:
      return state;
  }
}
