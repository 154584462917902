import apiConfig from "../services/api/apiConfig";
import { useLookups } from "./useLookups";

export interface IMarketingUnsubscribeReason {
  id: string;
  value: string;
}

export const useMarketingUnsubscribeReasons = () =>
  useLookups<IMarketingUnsubscribeReason[]>(
    apiConfig.endpoints.lookups.marketingUnsubscribeReasons,
    "marketingUnsubscribeReasons"
  );

export default IMarketingUnsubscribeReason;
