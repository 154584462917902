import React, { FC } from "react";
import classnames from "classnames";
import Container from "../Container/Container";

interface IProps {
  className?: string;
}

const Section: FC<IProps> = ({ children, className, ...rest }) => (
  <Container className={classnames("section", className)} {...rest}>
    <div className="cell">{children}</div>
  </Container>
);

export default Section;
