import { takeLatest, call } from "redux-saga/effects";
import { ICallApi } from "@acca-portals/shared";
import apiConfig from "../../configs/apiConfig";
import { FETCH_USER_PROFILE, FetchUserProfileAction } from "./types";
import { useSaga } from "../utility/hooks/useSaga";

function* handleFetchUserProfile({ resolve }: FetchUserProfileAction) {
  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.user.userProfile,
    method: "GET",
  };

  yield call(useSaga, [FETCH_USER_PROFILE.success, FETCH_USER_PROFILE.error], requestArgs, resolve);
}

export default function* userProfileSagas(): unknown {
  yield takeLatest(FETCH_USER_PROFILE.START, handleFetchUserProfile);
}
