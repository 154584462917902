import { useQuery } from "react-query";
import ApiService from "../services/api/ApiService";

const getLookups = async <T>(url: string, key: string) => {
  const result = await ApiService.CallApi({
    url,
    method: "GET",
  });

  return result.data[key] as T;
};

export const useLookups = <T>(url: string, key: string) =>
  useQuery<T, Error>(key, () => getLookups<T>(url, key), {
    cacheTime: 1000 * 60 * 5,
  });
