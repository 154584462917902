import { typeCreator } from "../utility/types";

export const PENDING_DELETE_INVITE = typeCreator("PENDING_USER", "DELETE_INVITE");
export const PENDING_RESEND_INVITE = typeCreator("PENDING_USER", "RESEND_INVITE");
export interface PendingUserDetailsState {
  firstName: string;
  lastName: string;
  emailAddress: string;
  userRole: string;
}
export interface PendingDeleteInviteAction {
  type: typeof PENDING_DELETE_INVITE.START;
  orgId: string;
  orgRoleId: string;
  requestId: string;
  resolve?: (response: unknown) => void;
}

export interface PendingDeleteInviteSuccessAction {
  type: typeof PENDING_DELETE_INVITE.SUCCESS;
}

export interface PendingDeleteInviteErrorAction {
  type: typeof PENDING_DELETE_INVITE.ERROR;
}
export interface PendingResendInviteAction {
  type: typeof PENDING_RESEND_INVITE.START;
  orgId: string;
  orgRoleId: string;
  requestId: string;
  resolve?: (response: unknown) => void;
}
export interface PendingResendInviteSuccessAction {
  type: typeof PENDING_RESEND_INVITE.SUCCESS;
}

export interface PendingResendInviteErrorAction {
  type: typeof PENDING_RESEND_INVITE.ERROR;
}

export type PendingUserActionTypes =
  | PendingDeleteInviteAction
  | PendingDeleteInviteSuccessAction
  | PendingDeleteInviteErrorAction
  | PendingResendInviteAction
  | PendingResendInviteSuccessAction
  | PendingResendInviteErrorAction;
