import React, { FC } from "react";
import { Footer, FooterSection, FooterLink } from "acca-design-system";

interface Section {
  title: string;
  links: { text: string; href: string }[];
}

export interface SiteFooterProps {
  content?: {
    sites: Section;
    useful: Section;
    popular: Section;
  };
}

const SiteFooter: FC<SiteFooterProps> = ({ content }) => {
  const renderSection = ({ title, links }: Section) => {
    return (
      <FooterSection className="cell medium-8" heading={title}>
        {links &&
          links.map((link, j) => {
            const { href, text } = link;
            return <FooterLink key={j} link={href} name={text} />;
          })}
      </FooterSection>
    );
  };
  return !content ? null : (
    <Footer>
      {content.sites && renderSection(content.sites)}
      {content.useful && renderSection(content.useful)}
      {content.popular && renderSection(content.popular)}
    </Footer>
  );
};

export default SiteFooter;
