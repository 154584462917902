import { RootState } from "../rootReducer";

export const getUpdatedUserRole = (state: RootState) => state.editUserDetails.updatedUserRole;
export const getUpdatedUserRoleId = (state: RootState) => state.editUserDetails.updatedUserRoleId;
export const getJoinedDate = (state: RootState) => state.editUserDetails.createdDate;
export const getRoleID = (state: RootState) => state.editUserDetails.userRoleId;
export const getRole = (state: RootState) => state.editUserDetails.userRole;
export const getEditUserDetails = (state: RootState) => {
  return {
    detailsList: {
      email: state.editUserDetails?.email,
      nationality: state.editUserDetails?.nationality,
      officeAddress: state.editUserDetails?.officeAddress,
      city: state.editUserDetails?.city,
      state: state.editUserDetails?.state,
      county: state.editUserDetails?.county,
      postalCode: state.editUserDetails?.postalCode,
      jobTitle: state.editUserDetails?.jobTitle,
      userRole: state.editUserDetails?.userRole,
    },
    labels: {
      email: "Email",
      nationality: "Nationality",
      officeAddress: "Office address",
      city: "Town/city",
      state: "Province/state",
      county: "County/district",
      postalCode: "Postcode/zip",
      jobTitle: "Job title",
      userRole: "User role",
    },
  };
};
export const getFullName = (state: RootState) => state.editUserDetails?.name;
