import React, { FC, ReactNode, useState, useEffect } from "react";
import {
  BrowserTitle,
  GreetingsTakeover,
  WelcomeMessage,
  PageContentLoader,
} from "@acca-portals/shared";
import { PageHeader, Breadcrumb } from "acca-design-system";
import { PageContent } from "../../state/content/types";
import apiConfig from "../../configs/apiConfig";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";

import "./default-page-layout.scss";

export interface Links {
  text: string;
  to: string;
}

export interface DefaultPageLayoutProps {
  breadcrumb?: Array<Links>;
  content?: PageContent | string;
  pageSubTitle?: string;
  pageSubTitleFontSize?: string;
  pageTitle?: string;
  titleContentText?: string;
  trackPageViewStatus?: boolean;
  showGreetings?: boolean;
  firstName?: string;
  userCount?: ReactNode;
  children?: React.ReactNode;
  loading?: boolean;
  error?: Error;
}

const DefaultPageLayout: FC<DefaultPageLayoutProps> = ({
  titleContentText,
  pageTitle,
  pageSubTitle,
  pageSubTitleFontSize = "h300",
  userCount,
  breadcrumb,
  trackPageViewStatus = true,
  showGreetings = false,
  firstName,
  children,
  error,
  loading,
}) => {
  const [greetingsComplete, setGreetingsComplete] = useState(false);
  const { greetingsTimeout } = apiConfig.timeout;
  const showGreetingsTakeover = !greetingsComplete;

  useEffect(() => {
    const greetingsTimer = setTimeout(() => setGreetingsComplete(true), greetingsTimeout);
    return () => clearTimeout(greetingsTimer);
  }, [greetingsTimeout]);
  const renderMain = () => {
    if (error) {
      return <ErrorPage isTakeOver={false} error={error} />;
    }

    if (loading) {
      return <PageContentLoader />;
    }
    return children;
  };
  const showPageHeader = pageTitle || pageSubTitle || userCount || breadcrumb;

  return (
    <div id={window.location.pathname}>
      <BrowserTitle
        content={{ browserTitle: { message: titleContentText } }}
        trackPageView={() => {
          return trackPageViewStatus;
        }}
      />
      {showPageHeader && (
        <PageHeader
          className="admin-header u-margin-bottom-2"
          pageTitle={pageTitle}
          quote={pageSubTitle && pageSubTitle}
          quoteAdditional={userCount && userCount}
          quoteFontSize={pageSubTitleFontSize}
        >
          {breadcrumb && <Breadcrumb links={breadcrumb} />}
        </PageHeader>
      )}
      <>
        {showGreetings && (
          <>
            <GreetingsTakeover fadeOut={!showGreetingsTakeover} firstName={firstName} />
            {!showGreetingsTakeover && (
              <WelcomeMessage
                firstName={firstName}
                className="grid-container fluid u-margin-bottom-2"
              />
            )}
          </>
        )}
        {renderMain()}
      </>
    </div>
  );
};

export default DefaultPageLayout;
