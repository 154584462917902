import { INotification, NotificationCard, NotificationsBar } from "@acca-portals/shared";
import React from "react";
import { useHistory } from "react-router";
import ROUTES from "../../constants/routes";

interface IProps {
  notifications: INotification[];
}

const Notifications = ({ notifications }: IProps) => {
  const history = useHistory();

  if (notifications.length === 0) {
    return null;
  }

  const handleClick = ({ notificationCode, content, entityId }: INotification) => {
    switch (notificationCode) {
      case "QUOTE_AVAILABLE":
      case "QUOTE_AVAILABLE_DUE":
      case "QUOTE_UPDATE_AVAILABLE":
      case "QUOTE_UPDATE_AVAILABLE_DUE":
        history.push(`${ROUTES.agreementInfoPage}?refNumber=${entityId}`);
        return;
      default:
        window.location.replace(content.button.href);
    }
  };

  return (
    <NotificationsBar>
      {notifications.map((notification) => (
        <NotificationCard
          key={notification.id}
          topic={notification.content.topic}
          title={notification.content.title}
          priority={notification.priority}
          buttonText={notification.content.button?.text}
          onClick={() => handleClick(notification)}
        />
      ))}
    </NotificationsBar>
  );
};

export default Notifications;
