import { takeLatest, call } from "redux-saga/effects";
import { ICallApi } from "@acca-portals/shared";
import apiConfig from "../../configs/apiConfig";
import {
  PENDING_RESEND_INVITE,
  PendingDeleteInviteAction,
  PendingResendInviteAction,
  PENDING_DELETE_INVITE,
} from "./types";
import { useSaga } from "../utility/hooks/useSaga";

function* handleDeleteInvite({ orgId, orgRoleId, requestId, resolve }: PendingDeleteInviteAction) {
  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.invite.deleteInvite(orgId, orgRoleId, requestId),
    method: "DELETE",
  };

  yield call(
    useSaga,
    [PENDING_DELETE_INVITE.success, PENDING_DELETE_INVITE.error],
    requestArgs,
    resolve
  );
}

function* handleResendInvite({ orgId, orgRoleId, requestId, resolve }: PendingResendInviteAction) {
  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.invite.resendInvite(orgId, orgRoleId, requestId),
    method: "PATCH",
    data: { resend: true },
  };

  yield call(
    useSaga,
    [PENDING_RESEND_INVITE.success, PENDING_RESEND_INVITE.error],
    requestArgs,
    resolve
  );
}

export default function* pendingUserSagas(): unknown {
  yield takeLatest(PENDING_RESEND_INVITE.START, handleResendInvite);
  yield takeLatest(PENDING_DELETE_INVITE.START, handleDeleteInvite);
}
