import React, { useEffect, useState, FC } from "react";
import { LoadingDots } from "acca-design-system";

import "./page-content-loader.scss";

export interface PageContentLoaderProps {
  customMessage?: string;
}

const PageContentLoader: FC<PageContentLoaderProps> = ({ customMessage }) => {
  const [showSlowLoadingMessage, setShowSlowLoadingMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSlowLoadingMessage(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="page-content-loader">
      <div className="page-content-loader__inner">
        <LoadingDots />
        {!customMessage && showSlowLoadingMessage && (
          <p className="u-margin-top-2">Please wait, we&apos;re just getting your details</p>
        )}
        {customMessage && <p className="u-margin-top-2">{customMessage}</p>}
      </div>
    </div>
  );
};

export default PageContentLoader;
