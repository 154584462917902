import { RootState } from "../rootReducer";

export const getLandingPageContent = (state: RootState) => state.content.landingPage;
export const getOrganisationsListingContent = (state: RootState) => state.content.orgListing;
export const getAddUserContent = (state: RootState) => state.content.addUser;
export const getEditUserContent = (state: RootState) => state.content.editUser;
export const getPendingUserContent = (state: RootState) => state.content.pendingUser;
export const getRevieweUserContent = (state: RootState) => state.content.reviewUser;
export const getCategoriesContent = (state: RootState) => state.content.categories;
export const getProductsContent = (state: RootState) => state.content.products;
