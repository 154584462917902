import React, { FC, FormEvent, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Parser from "html-react-parser";

import { Card, Button, BodyCopy } from "acca-design-system";
import { toast } from "react-toastify";

import {
  Container,
  PageContentLoader,
  SubmittingMessage,
  replaceTemplates,
} from "@acca-portals/shared";
import staticContent from "./AddUserPage.content";
import ROUTES from "../../constants/routes";
import InputWithValidation, {
  Validations,
} from "../../components/InputWithValidation/InputWithValidation";

import {
  resetErrorCode,
  resetUserDetails,
  setEmail,
  setFirstname,
  setLastname,
  setMessage,
  setRole,
  setRoleId,
  submitNewUser,
} from "../../state/newUserDetails/actions";
import {
  getEmail,
  getErrorCode,
  getFirstname,
  getHasRequiredFields,
  getLastname,
  getMessage,
  getUserRole,
} from "../../state/newUserDetails/selectors";
import { isAlphanumericValue, isEmail, isMaxLength } from "../../utilities/validation-rules";
import {
  getOrganisationName,
  getUserExistingRole,
} from "../../state/organisationOverview/selectors";

import VALIDATION_MESSAGES from "../../constants/validationMessages";
import apiErrors from "../../configs/apiErrors";
import { fetchAddUserContent } from "../../state/content/actions";
import { PageContent } from "../../state/content/types";
import { getAddUserContent } from "../../state/content/selectors";
import RoleSelector from "../../components/RoleSelector/RoleSelector";
import { fetchOrganisationOverview } from "../../state/organisationOverview/action";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";
import { getOrgRole } from "../../state/userProfile/selectors";
import { getViewerRolesId, getViewerRolesValue } from "../../state/lookups/selectors";
import { fetchUserRoles } from "../../state/lookups/actions";

const AddUserPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const content: PageContent = useSelector(getAddUserContent);
  const firstname = useSelector(getFirstname);
  const lastname = useSelector(getLastname);
  const email = useSelector(getEmail);
  const message = useSelector(getMessage);
  const hasRequiredFields = useSelector(getHasRequiredFields);
  const errorCode = useSelector(getErrorCode);
  const { orgId, orgRoleId } = useParams<{ orgId: string; orgRoleId: string }>();
  const orgName = useSelector((state) => getOrganisationName(state, orgId, orgRoleId));
  const orgRole = useSelector((state) => getOrgRole(state, orgId, orgRoleId));
  const existingUserRole = useSelector((state) =>
    getUserExistingRole(state, orgId, orgRoleId, email)
  );
  const [invalidFields, setInvalidFields] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState("");
  const defaultRoleId = useSelector(getViewerRolesId);
  const defaultRoleValue = useSelector(getViewerRolesValue);

  const breadcrumb = [
    {
      text: "Organisations",
      to: "/admin/organisations",
    },
    {
      text: orgName,
      to: `${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`,
    },
    {
      text: content?.heading.text,
      to: ROUTES.addUser,
    },
  ];

  useEffect(() => {
    if (!content) {
      dispatch(fetchAddUserContent());
    }
    if (!orgName) {
      dispatch(fetchOrganisationOverview(orgId, orgRoleId));
    }
    if (!defaultRoleId) {
      dispatch(fetchUserRoles());
    } else {
      dispatch(setRole(defaultRoleValue));
      dispatch(setRoleId(defaultRoleId));
    }
  }, [dispatch, defaultRoleId]);

  useEffect(() => {
    if (errorCode) {
      const customError = apiErrors.apiErrors.filter((errorEntry) => errorEntry.id === errorCode);
      if (customError.length) {
        const customErrorTemplate = replaceTemplates(VALIDATION_MESSAGES[customError[0].name], {
          role: `${existingUserRole}`,
          orgRole: `${orgRole}`,
          orgName: `${orgName}`,
        });
        setCustomErrorMessage(customErrorTemplate);
      }
    }

    if (!errorCode && isSubmitted) {
      toast.success(
        replaceTemplates(staticContent.toastSuccessMessage, {
          username: `${email}`,
        })
      );
      dispatch(resetUserDetails());
      history.push(`${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`);
    }
  }, [isSubmitted]);

  const pageReady = content && !!orgName;
  if (!pageReady) {
    return <PageContentLoader />;
  }

  const isValid = hasRequiredFields && invalidFields.length === 0;

  const userSubmit = () =>
    new Promise((resolve) => dispatch(submitNewUser(orgId, orgRoleId, resolve)));

  const onFieldValidate = (errors: Validations, fieldId: string) => {
    if (errors.length === 0) {
      return setInvalidFields(invalidFields.filter((id) => id !== fieldId));
    }
    return setInvalidFields((prevState) => prevState.concat([fieldId]));
  };

  const handleRoleChange = (e) => {
    dispatch(setRole(e.target.value));
    dispatch(setRoleId(e.target.id));
  };

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsSubmitted(false);

    dispatch(resetErrorCode());

    await userSubmit();
    setIsSubmitting(false);
    setIsSubmitted(true);
  };

  const onFormCancel = async (e: FormEvent) => {
    e.preventDefault();

    dispatch(resetUserDetails());

    toast.error(staticContent.toastCancelMessage);
    history.push(`${ROUTES.organisationsListing}/${orgId}/${orgRoleId}`);
  };

  return (
    <>
      {!isSubmitting ? (
        <DefaultPageLayout
          pageSubTitle={content?.heading.text}
          titleContentText={staticContent?.pageHeading}
          pageTitle={staticContent.title}
          breadcrumb={breadcrumb}
        >
          <Container>
            <Card className="u-padding-bottom-2 u-margin-top-0 u-bg-white u-full-browser-width u-margin-left-1 u-margin-right-1">
              <div className="u-content-container-medium">
                <h3 className="u-margin-bottom-1">{content?.title.text}</h3>
                <BodyCopy className="u-margin-bottom-2">
                  {Parser(
                    replaceTemplates(content?.description.value, {
                      orgName: `${orgName}`,
                    })
                  )}
                </BodyCopy>
                <form autoComplete="off" className="u-margin-bottom-2">
                  <InputWithValidation
                    id="firstname"
                    labelText={staticContent?.firstnameInputLabel}
                    onChange={(event) => {
                      dispatch(setFirstname(event.target.value));
                    }}
                    value={firstname}
                    validations={[isAlphanumericValue]}
                    onValidate={onFieldValidate}
                  />
                  <InputWithValidation
                    id="lastname"
                    labelText={staticContent?.lastnameInputLabel}
                    onChange={(event) => {
                      dispatch(setLastname(event.target.value));
                    }}
                    value={lastname}
                    validations={[isAlphanumericValue]}
                    onValidate={onFieldValidate}
                  />
                  <InputWithValidation
                    id="email"
                    labelText={staticContent?.emailInputLabel}
                    onChange={(event) => {
                      dispatch(setEmail(event.target.value));
                    }}
                    value={email}
                    validations={[isEmail]}
                    onValidate={onFieldValidate}
                    apiError={!!errorCode}
                    customErrorMessage={customErrorMessage}
                  />
                  <div className="u-padding-bottom-2 u-padding-top-2">
                    <p className="u-padding-bottom-1 u-font-bold">{staticContent.userRoleLabel}</p>
                    <RoleSelector onChange={handleRoleChange} stateSelector={getUserRole} />
                  </div>
                  <InputWithValidation
                    id="message"
                    type="textarea"
                    labelText={staticContent?.messageInputLabel}
                    onChange={(event) => {
                      dispatch(setMessage(event.target.value));
                    }}
                    value={message}
                    validations={[isMaxLength]}
                    onValidate={onFieldValidate}
                  />
                </form>
                <Button
                  text={staticContent.submitButtonLabel}
                  onClick={onFormSubmit}
                  disabled={!isValid}
                  type="submit"
                  className="u-margin-right-2"
                />
                <Button
                  text={staticContent.cancelButtonLabel}
                  type="button"
                  onClick={onFormCancel}
                  variant="link"
                />
              </div>
            </Card>
          </Container>
        </DefaultPageLayout>
      ) : (
        <SubmittingMessage title="Saving" />
      )}
    </>
  );
};

export default AddUserPage;
