import { takeLatest, select, call } from "redux-saga/effects";
import { ICallApi } from "@acca-portals/shared";
import apiConfig from "../../configs/apiConfig";
import { useSaga } from "../utility/hooks/useSaga";

import { FETCH_ORGANISATIONS } from "./types";

import { getContactId } from "../userProfile/selectors";

function* handleFetchOrganisations() {
  const contactId = yield select(getContactId);

  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.user.organisationListing(contactId),
    method: "GET",
  };

  yield call(useSaga, [FETCH_ORGANISATIONS.success, FETCH_ORGANISATIONS.error], requestArgs);
}

export default function* organisationsSagas(): unknown {
  yield takeLatest(FETCH_ORGANISATIONS.START, handleFetchOrganisations);
}
