import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card } from "acca-design-system";
import staticContent from "./AgreementCard.content";
import "./AgreementCard.scss";
import ROUTES from "../../../../constants/routes";
import formatDate from "../../../../utilities/format-date";
import AGREEMENT_STATUS from "../../../../constants/agreementStatus";
import QUOTE_STATUS from "../../../../constants/quoteStatus";

export interface AgreementCardProps {
  name?: string;
  headline?: string;
  imageUrl: string;
  includedItems?: Array<string>;
  validTo?: string;
  status?: string;
  isEmpty?: boolean;
  agreementId?: string;
  quoteNumber?: string;
  stale?: boolean;
}

const AgreementCard: FC<AgreementCardProps> = ({
  name,
  headline,
  imageUrl,
  includedItems,
  validTo,
  status,
  isEmpty,
  agreementId,
  quoteNumber,
  stale,
}) => {
  const emptyStyle = {
    backgroundImage: `url(${imageUrl})`,
  };
  const numberOfExcemptions =
    includedItems && includedItems.filter((item) => item.includes("Exemption")).length;
  const numberOfSubscriptions =
    includedItems && includedItems.filter((item) => item.includes("Subscription")).length;
  const numberOfRegistrations =
    includedItems && includedItems.filter((item) => item.includes("Registration")).length;
  const hasRibbon =
    status === QUOTE_STATUS.IN_PROGRESS ||
    status === QUOTE_STATUS.AWAITING ||
    status === AGREEMENT_STATUS.INCOMPLETE.NOT_YET_ACCEPTED ||
    status === AGREEMENT_STATUS.INCOMPLETE.SIGNED ||
    status === AGREEMENT_STATUS.INCOMPLETE.SIGNATURE_PENDING ||
    status === AGREEMENT_STATUS.INCOMPLETE.SIGN_PEND_DEADLINE_IMMINENT ||
    status === AGREEMENT_STATUS.COMPLETE ||
    status === AGREEMENT_STATUS.INCOMPLETE.ACTIVE_DEADLINE_IMMINENT;
  const ribbonBg = () => {
    if (
      status === QUOTE_STATUS.IN_PROGRESS ||
      status === QUOTE_STATUS.AWAITING ||
      status === AGREEMENT_STATUS.INCOMPLETE.NOT_YET_ACCEPTED ||
      status === AGREEMENT_STATUS.INCOMPLETE.SIGNED ||
      status === AGREEMENT_STATUS.INCOMPLETE.SIGNATURE_PENDING ||
      status === AGREEMENT_STATUS.INCOMPLETE.SIGN_PEND_DEADLINE_IMMINENT ||
      status === AGREEMENT_STATUS.INCOMPLETE.ACTIVE_DEADLINE_IMMINENT
    )
      return "progress-ribbon";
    if (status === AGREEMENT_STATUS.COMPLETE) return "active-ribbon";
    return "";
  };
  const history = useHistory();
  const cardRoute = () => {
    if (agreementId) {
      history.push(`${ROUTES.agreementInfoPage}?refNumber=${agreementId}`);
    } else {
      history.push(`${ROUTES.agreementInfoPage}?refNumber=${quoteNumber}`);
    }
  };
  const ribbonTxt = () => {
    if (
      status === QUOTE_STATUS.AWAITING ||
      status === QUOTE_STATUS.IN_PROGRESS ||
      status === AGREEMENT_STATUS.INCOMPLETE.NOT_YET_ACCEPTED ||
      status === AGREEMENT_STATUS.INCOMPLETE.SIGNED ||
      status === AGREEMENT_STATUS.INCOMPLETE.SIGNATURE_PENDING ||
      status === AGREEMENT_STATUS.INCOMPLETE.SIGN_PEND_DEADLINE_IMMINENT ||
      status === AGREEMENT_STATUS.INCOMPLETE.ACTIVE_DEADLINE_IMMINENT
    )
      return `${staticContent.inProgressText}`;
    if (status === AGREEMENT_STATUS.COMPLETE) return `${staticContent.activeText}`;
    return "";
  };
  const activeClass =
    status === AGREEMENT_STATUS.INCOMPLETE.ACTIVE_DEADLINE_IMMINENT ||
    status === AGREEMENT_STATUS.INCOMPLETE.SIGN_PEND_DEADLINE_IMMINENT;

  return isEmpty ? (
    <div className="agreement-card-empty cell">
      <div className="agreement-card-empty__gradient" />
      <div className="agreement-card-empty__image" style={emptyStyle} />
      <div className="agreement-card-empty__content">
        <div className="agreement-card-empty__headline">Set up your first agreement</div>
        <div className="agreement-card-empty__description">
          {staticContent.emptyCardDescription}
        </div>
        <Button variant="primary" href="#" text={staticContent.contactUsCtaText} />
      </div>
    </div>
  ) : (
    <div className="agreement-card cell">
      <Card onClick={cardRoute}>
        <div className="agreement-card-image-wrapper">
          {hasRibbon && <div className={ribbonBg()}>{ribbonTxt()}</div>}
          <img className="agreement-image" src={imageUrl} alt="img" />
        </div>
        <div className={`content-wrapper ${activeClass && "content-wrapper--active"}`}>
          {name && <div className="agreement-name">{name}</div>}
          {headline && <div className="agreement-headline">{headline}</div>}
          {stale && <div className="agreement-status">{status.replace("_", " ")}</div>}

          {includedItems && (
            <div className="agreement-contents">
              <div className="agreement-contents-headline">{staticContent.includedItemsText}</div>
              <div className="agreement-contents-items">
                {numberOfExcemptions >= 1 && (
                  <div className="agreement-item">
                    {numberOfExcemptions} {staticContent.exemptionsText}
                  </div>
                )}
                {numberOfSubscriptions >= 1 && (
                  <div className="agreement-item">
                    {numberOfSubscriptions} {staticContent.subscriptionsText}
                  </div>
                )}
                {numberOfRegistrations >= 1 && (
                  <div className="agreement-item">{staticContent.registrationsText}</div>
                )}
              </div>
            </div>
          )}

          {quoteNumber && status === QUOTE_STATUS.IN_PROGRESS && (
            <div className="agreement-cta">
              <div className="separator" />
              <Button variant="primary" href="#" text={staticContent.ctaText.quote} />
            </div>
          )}

          {((status === AGREEMENT_STATUS.INCOMPLETE.NOT_YET_ACCEPTED ||
            status === AGREEMENT_STATUS.INCOMPLETE.ACTIVE_DEADLINE_IMMINENT) && (
            <div className="agreement-cta">
              <div className="separator" />
              <Button
                variant="primary"
                href="#"
                text={staticContent.ctaText.viewAgreement}
                isReversed={status === AGREEMENT_STATUS.INCOMPLETE.ACTIVE_DEADLINE_IMMINENT}
              />
            </div>
          )) || (
            <div className="agreement-validity">
              {staticContent.validityText} {formatDate(validTo)}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default AgreementCard;
