import React from "react";
import "./card-footnote.scss";

interface ICardFootnoteProps {
  text: string;
}

const CardFootnote = ({ text }: ICardFootnoteProps) => (
  <div className="card-footnote">
    <strong>{text}</strong>
  </div>
);

export default CardFootnote;
