import React, { FC } from "react";
import { useSelector } from "react-redux";

import { Card, IconProfile24, Button } from "acca-design-system";
import { replaceTemplates } from "@acca-portals/shared";
import formatDate from "../../../../utilities/format-date";
import content from "./NotificationCard.content";
import "./NotificationCard.scss";
import { UserRequestData } from "../../../../state/organisationOverview/types";
import ROUTES from "../../../../constants/routes";
import { isUserAdminForOrganisation } from "../../../../state/userProfile/selectors";

export interface NotificationCardProps {
  notificationList: Array<UserRequestData>;
  orgId: string;
  orgRoleId: string;
  orgName?: string;
}

const NotificationCard: FC<NotificationCardProps> = ({
  notificationList,
  orgId,
  orgRoleId,
  orgName,
}) => {
  const { requestedToJoin, reviewButtonText } = content;
  const isUserAdmin = useSelector((state) => isUserAdminForOrganisation(state, orgName));
  return (
    <>
      {notificationList &&
        notificationList
          .sort((x, y) => +new Date(x.createdDate) - +new Date(y.createdDate))
          .map((notification, i) => {
            return (
              <div key={i} className="review-notifications">
                <Card
                  className="notification-card"
                  backgroundColor="red"
                  iconSize="large"
                  icon={IconProfile24}
                >
                  <div className="notification-card__inner">
                    <div className="notification-card__left-area">
                      <h3 className="h500">
                        {notification.firstName} {notification.lastName}
                      </h3>
                      <p>{notification.email}</p>
                    </div>
                    <div className="notification-card__right-area">
                      <p className="notification-card__request-text">
                        {replaceTemplates(requestedToJoin, {
                          date: formatDate(notification.createdDate),
                        })}
                      </p>
                      <Button
                        text={reviewButtonText}
                        isReversed
                        to={
                          isUserAdmin &&
                          `${ROUTES.reviewUser}/${orgId}/${orgRoleId}/${notification.requestId}`
                        }
                      />
                    </div>
                  </div>
                </Card>
              </div>
            );
          })}
    </>
  );
};

export default NotificationCard;
