import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosResponse } from "axios";
import { ApiService, IUpdateErrorResponse } from "@acca-portals/shared";
import apiConfig from "../configs/apiConfig";

export interface IMarketingConsent {
  enabled: boolean;
  paused: boolean;
  pauseDetails?: {
    period: number;
    endDate: string;
  };
  unsubscribeReason: string;
}

export interface IContactDetails {
  contactId: string;
  accountId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  consents: {
    b2bMarketing: IMarketingConsent;
  };
}

export const useContactDetails = (contactId: string) =>
  useQuery<IContactDetails, Error>(`contactDetails_${contactId}`, async () => {
    const response = await ApiService.CallApi<IContactDetails>({
      url: apiConfig.endpoints.user.contactDetails(contactId),
      method: "GET",
    });

    return response.data;
  });

interface IUpdateBody {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const useUpdateContactDetails = (contactId: string) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<unknown>, IUpdateErrorResponse, IUpdateBody>(
    (body) =>
      ApiService.CallApi({
        url: apiConfig.endpoints.user.contactDetails(contactId),
        method: "PATCH",
        data: body,
      }),
    {
      onSuccess: (response) => {
        // on successful update, contact details is out of date
        // this triggers a refetch from the server (source of truth)
        queryClient.setQueryData(`contactDetails_${contactId}`, response.data);
      },
    }
  );
};
