import { put, call, takeLatest } from "redux-saga/effects";
import { ApiService, ICallApi, YieldReturn } from "@acca-portals/shared";
import apiConfig from "../../configs/apiConfig";
import {
  FETCH_ADD_USER_CONTENT,
  FETCH_EDIT_USER_CONTENT,
  FETCH_LANDING_PAGE_CONTENT,
  FETCH_ORG_LISTING_CONTENT,
  FETCH_PENDING_USER_CONTENT,
  FETCH_REVIEW_USER_CONTENT,
  FETCH_CATEGORIES_CONTENT,
  FETCH_PRODUCTS_CONTENT,
} from "./types";

interface Content {
  url: string;
  isPublic: boolean;
}

function* handleFetchContent(type: string, { url, isPublic }: Content) {
  try {
    const requestArgs: ICallApi = {
      url,
      method: "GET",
      isPublic,
    };
    const response: YieldReturn<typeof ApiService.CallApi> = yield call(
      ApiService.CallApi,
      requestArgs
    );

    return yield put({
      type: `${type}_SUCCESS`,
      payload: response.data,
    });
  } catch (error) {
    return yield put({
      type: `${type}_ERROR`,
      error,
    });
  }
}

const contentMap: Record<string, Content> = {
  [FETCH_LANDING_PAGE_CONTENT]: { url: apiConfig.endpoints.templates.landingPage, isPublic: true },
  [FETCH_ORG_LISTING_CONTENT]: {
    url: apiConfig.endpoints.templates.organisationsListing,
    isPublic: true,
  },
  [FETCH_ADD_USER_CONTENT]: {
    url: apiConfig.endpoints.templates.addUser,
    isPublic: true,
  },
  [FETCH_EDIT_USER_CONTENT]: {
    url: apiConfig.endpoints.templates.editUser,
    isPublic: true,
  },
  [FETCH_PENDING_USER_CONTENT]: {
    url: apiConfig.endpoints.templates.pendingUser,
    isPublic: true,
  },
  [FETCH_REVIEW_USER_CONTENT]: {
    url: apiConfig.endpoints.templates.reviewUser,
    isPublic: true,
  },
  [FETCH_CATEGORIES_CONTENT]: {
    url: apiConfig.endpoints.templates.categories,
    isPublic: true,
  },
  [FETCH_PRODUCTS_CONTENT]: {
    url: apiConfig.endpoints.templates.products,
    isPublic: true,
  },
};

export default function* contentSagas() {
  const keys = Object.keys(contentMap);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    yield takeLatest(key, ({ type }) => handleFetchContent(type, contentMap[key]));
  }
}
