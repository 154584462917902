import ROUTES from "../../constants/routes";

export const navigation = [
  {
    id: "Dashboard",
    name: "Dashboard",
    icon: "IconDashboard16",
    to: "/",
    notifications: 0,
  },
  {
    id: "Agreements",
    name: "Agreements",
    icon: "IconDocuments16",
    to: ROUTES.agreements,
    notifications: 0,
  },
  {
    id: "MyDetails",
    name: "My details",
    icon: "IconDocuments16",
    to: ROUTES.myDetailsPage,
    subLinks: [
      {
        id: "MyDetails",
        name: "My details",
        icon: "IconDocuments16",
        to: ROUTES.myDetailsPage,
      },
      {
        id: "AccountDetails",
        name: "Account details",
        to: ROUTES.accountDetails,
      },
      {
        id: "ContactDetails",
        name: "Contact details",
        to: ROUTES.contactDetailsPage,
      },
      {
        id: "MarketingConsent",
        name: "Marketing consent",
        to: ROUTES.marketingConsentPage,
      },
      {
        id: "LoginDetails",
        name: "Login details",
        to: ROUTES.loginDetailsPage,
      },
    ],
  },
];
