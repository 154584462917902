export default {
  sites: {
    title: "Our Sites",
    links: [
      {
        text: "ACCA Careers Recruiter Centre",
        href: "https://recruiter.accaglobal.com",
        order: 1,
      },
      {
        text: "ACCAglobal.com",
        href: "https://www.accaglobal.com",
        order: 2,
      },

      {
        text: "Professional Insights",
        href: "https://www.accaglobal.com/gb/en/professional-insights.html",
        order: 3,
      },
    ],
  },
  useful: {
    title: "Useful links",
    links: [
      {
        text: "Approved Employer programme",
        href: "https://www.accaglobal.com/gb/en/employer/approved-employer-programme.html",
        order: 1,
      },
      {
        text: "ACCA qualifications and learning",
        href: "https://www.accaglobal.com/gb/en/qualifications/glance.html",
        order: 2,
      },
      {
        text: "Contact us",
        href: "https://www.accaglobal.com/gb/en/footertoolbar/contact-us.html",
        order: 3,
      },
    ],
  },
  popular: {
    title: "Popular links",
    links: [
      {
        text: "Data protection",
        href: "https://www.accaglobal.com/data-protection",
        order: 1,
      },
      {
        text: "Study resources",
        href: "https://www.accaglobal.com/gb/en/student/exam-support-resources.html",
        order: 2,
      },
      {
        text: "ACCA Learning",
        href: "https://www.accaglobal.com/gb/en/qualifications/glance.html",
        order: 3,
      },
    ],
  },
};
