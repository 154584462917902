import React from "react";
import { Button, IconArrowRight16 } from "acca-design-system";
import "./notification-card.scss";

interface INotificationCardProps {
  topic?: string;
  title: string;
  buttonText: string;
  onClick: () => any;
  priority?: "LOW" | "MEDIUM" | "HIGH";
}

const NotificationCard = ({
  topic,
  title,
  buttonText,
  priority = "LOW",
  onClick,
}: INotificationCardProps) => (
  <button
    onClick={onClick}
    className={`notification-card notification-card--${priority.toLowerCase()}`}
  >
    {topic && <p className="notification-card__topic">{topic}</p>}
    <p className="notification-card__title">{title}</p>
    <Button
      className="notification-card__button"
      variant="link"
      tabIndex="-1"
      text={buttonText}
      isReversed={priority === "HIGH"}
      icon={IconArrowRight16}
      iconPosition="right"
    />
  </button>
);

export default NotificationCard;
