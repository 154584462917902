/* eslint-disable @typescript-eslint/no-explicit-any */
import MockAdapter from "axios-mock-adapter";

export interface ApiMock {
  url: string | RegExp;
  method: string;
  statusCode?: number;
  body?: Record<string, unknown>;
  delay: number;
}

const mockResponse: (call: ApiMock) => Promise<any> = (call) =>
  new Promise((resolve) => {
    window.setTimeout(() => {
      resolve([call.statusCode, JSON.stringify(call.body)]);
    }, call.delay);
  });

export const createMocks: (mocker: MockAdapter, mocks: Record<string, ApiMock>) => void = (
  mocker,
  mocks
) => {
  Object.keys(mocks).forEach((key) => {
    const call: ApiMock = mocks[key];
    switch (call.method) {
      case "GET":
        mocker.onGet(call.url).reply(() => mockResponse(call));
        break;
      case "POST":
        mocker.onPost(call.url).reply(() => mockResponse(call));
        break;
      case "PUT":
        mocker.onPut(call.url).reply(() => mockResponse(call));
        break;
      case "PATCH":
        mocker.onPatch(call.url).reply(() => mockResponse(call));
        break;
      case "DELETE":
        mocker.onAny(call.url).reply(() => mockResponse(call));
        break;
      default:
        break;
    }
  });
};

export const createMock: (
  url: string | RegExp,
  body: any,
  config?: Record<string, unknown>
) => ApiMock = (url, body, config) => {
  const { statusCode, method, delay } = {
    statusCode: 200,
    method: "GET",
    delay: 0,
    ...config,
  };

  return {
    url,
    method,
    statusCode,
    body,
    delay,
  };
};
