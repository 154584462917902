import React, { ChangeEvent, FC, useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { FormField, FormFieldValidation } from "acca-design-system";

export type Validations = Array<(value: string) => { result: boolean; message?: string }>;

export interface ApiError {
  errorCode: boolean;
}
export interface FieldValidationProps {
  id: string;
  labelText: string;
  onChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  type?: string;
  validations?: Validations;
  onValidate?: (errors: Validations, id: string) => void;
  showRequiredLabel?: boolean;
  textareaRows?: number;
  apiError?: boolean;
  customErrorMessage?: string;
}

const FieldValidation: FC<FieldValidationProps> = ({
  id,
  labelText,
  onChange,
  type = "text",
  value,
  validations,
  onValidate,
  showRequiredLabel = true,
  textareaRows = 5,
  apiError,
  customErrorMessage,
}) => {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([]);
  const [lostFocus, setLostFocus] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setIsValid(errors.length === 0);

    if (onValidate) {
      onValidate(errors, id);
    }
    if (customErrorMessage && ref?.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [errors, customErrorMessage]);

  const labelClasses = classNames("form-label", {
    required: showRequiredLabel,
  });

  const validateInput = (inputValue: string) => {
    return setErrors(
      validations.map((validationRule) => validationRule(inputValue)).filter((rule) => !rule.result)
    );
  };

  return (
    <FormField valid={isValid} className={apiError && !lostFocus ? "form-field--invalid" : ""}>
      <label htmlFor={id} className={labelClasses}>
        {labelText}
      </label>
      {type === "textarea" && (
        <textarea
          onChange={(e) => {
            validateInput(e.target.value);
            onChange(e);
          }}
          id={id}
          name={id}
          rows={textareaRows}
          onBlur={() => setLostFocus(true)}
        />
      )}
      {type !== "textarea" && (
        <input
          id={id}
          onChange={(e) => {
            validateInput(e.target.value);
            onChange(e);
          }}
          type={type}
          value={value}
          onBlur={() => setLostFocus(true)}
        />
      )}
      {!isValid && lostFocus && (
        <FormFieldValidation
          list={errors.map(({ message }, i) => ({
            fieldId: `${id}_${i}`,
            valid: false,
            messageLabel: "Error",
            message,
          }))}
        />
      )}
      {apiError && !lostFocus && (
        <div className="form-field-validation" role="list">
          <div className="form-field-validation__item" role="listitem">
            <div role="alert" className="alert alert--error alert--inline">
              Error
            </div>
            <span ref={ref} className="form-field-validation__item-message">
              {customErrorMessage}
            </span>
          </div>
        </div>
      )}
    </FormField>
  );
};

export default FieldValidation;
