import React, { FC, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconChevronUp16, IconChevronDown16 } from "acca-design-system";
import classNames from "classnames";

import {
  updateOrgListingFilter,
  resetOrgListingFilter,
} from "../../state/organisationListing/actions";
import { getFilterOptions, getSelectedFilters } from "../../state/organisationListing/selectors";

import "./filter-list.scss";

export interface FilterListProps {
  title: string;
}

const FilterList: FC<FilterListProps> = ({ title }) => {
  const dispatch = useDispatch();
  const filterRef = useRef(null);
  const filterOptions = useSelector(getFilterOptions);
  const selectedFilters = useSelector(getSelectedFilters);
  const [active, setActive] = useState(false);

  useEffect(() => {
    // Close filter if clicked on outside of element
    const handleClickOutside = (e) => {
      if (filterRef.current && !filterRef.current.contains(e.target)) {
        setActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef]);

  return (
    <div className="filter-wrapper" ref={filterRef}>
      <Button
        className="filter-button"
        onClick={() => setActive(!active)}
        text={title}
        icon={(active && IconChevronUp16) || IconChevronDown16}
        variant="outlined"
        iconPosition="right"
      />
      <div className={`filter-list ${active && "active"}`}>
        <div className="filter-list__inner">
          {filterOptions.map((item, i) => {
            const classes = classNames("filter-list__item", {
              "button--active": selectedFilters.includes(item),
            });
            return (
              <Button
                key={i}
                text={item}
                className={classes}
                variant="link"
                onClick={() => dispatch(updateOrgListingFilter(item))}
              />
            );
          })}

          <Button
            text="Clear options"
            className="filter-list__item filter-list__reset"
            variant="nav"
            onClick={() => dispatch(resetOrgListingFilter())}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterList;
