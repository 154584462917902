import { RootState } from "../rootReducer";

export const getPendingUserInvitation = (
  state: RootState,
  orgId: string,
  orgRoleId: string,
  contactId: string
) => {
  if (orgId && orgRoleId && contactId && state?.organisationOverview) {
    const orgDetails = state?.organisationOverview?.results[orgId];
    const orgRoleDetails = orgDetails && orgDetails[orgRoleId];
    const invitation =
      orgRoleDetails &&
      orgRoleDetails?.invitations?.find((invite) => invite.contactId === contactId);
    return invitation;
  }
  return null;
};
