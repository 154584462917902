import React, { FC } from "react";
import { PageHeader } from "acca-design-system";
import { Section } from "@acca-portals/shared";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";

const DeleteAccountPage: FC = () => {
  const renderMain = () => {
    return (
      <>
        <PageHeader
          className="u-margin-bottom-2"
          pageTitle="Delete Account"
          quote="Delete Account"
        />
        <Section>
          <h1>Coming soon.</h1>
        </Section>
      </>
    );
  };

  return <DefaultPageLayout>{renderMain()}</DefaultPageLayout>;
};

export default DeleteAccountPage;
