/* eslint-disable camelcase */
import React, { FC, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageContentLoader, AuthService } from "@acca-portals/shared";
import { ErrorBoundary, useErrorHandler } from "react-error-boundary";
import QueryString from "query-string";
import { useLocation } from "react-router-dom";

import AUTH_ERROR_CODES from "../../constants/authErrorCodes";
import { fetchUserProfile } from "../../state/userProfile/actions";
import { resetAppError } from "../../state/appError/actions";
import { getAppError } from "../../state/appError/selectors";

import RootPage from "../../pages/RootPage/RootPage";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";

import { UserProfile } from "../../state/userProfile/types";

interface IdTokenClaims {
  idTokenClaims: {
    extension_accaid?: string;
  };
}

const AppInitialiser: FC = () => {
  const dispatch = useDispatch();
  const appError = useSelector(getAppError);
  const { search } = useLocation();
  useErrorHandler(appError);

  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    const getUserProfile = (): Promise<UserProfile> =>
      new Promise((resolve) => dispatch(fetchUserProfile(resolve)));

    const fetchOrgRoleFromQueryString = () => {
      const qs = QueryString.parse(search);
      const cleanQueryString = (query) => (Array.isArray(query) ? query[0] : query);
      const orgIdFromQs = cleanQueryString(qs.orgId);
      const orgRoleIdFromQs = cleanQueryString(qs.orgRoleId);

      if (!orgIdFromQs || !orgRoleIdFromQs) {
        return;
      }

      sessionStorage.setItem("orgId", orgIdFromQs);
      sessionStorage.setItem("orgRoleId", orgRoleIdFromQs);
    };

    fetchOrgRoleFromQueryString();

    async function fetchAuthService() {
      try {
        const auth = (await AuthService.loadAuthService()) as IdTokenClaims;
        const isExistingUser = !!(auth && auth.idTokenClaims.extension_accaid);

        if (isExistingUser) {
          await getUserProfile();
        }
        setIsAuthLoaded(true);
      } catch ({ errorMessage }) {
        if (errorMessage && errorMessage.includes(AUTH_ERROR_CODES.forgotPassword)) {
          AuthService.forgotPassword();
        }
      }
    }
    fetchAuthService();
    return () => {
      componentIsMounted.current = false;
    };
  }, [dispatch, search]);

  const isPageReady = isAuthLoaded;

  if (!isPageReady) {
    return <PageContentLoader />;
  }

  return <RootPage />;
};

const WithErrorHandling: FC = () => {
  const dispatch = useDispatch();
  return (
    <ErrorBoundary
      onReset={() => dispatch(resetAppError())}
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <ErrorPage isTakeOver error={error} resetErrorBoundary={resetErrorBoundary} />
      )}
    >
      <AppInitialiser />
    </ErrorBoundary>
  );
};

export default WithErrorHandling;
