import { AnyAction } from "redux";
import { typeCreator } from "../utility/types";

export const FETCH_REVIEW_USER_REQUEST = typeCreator("REVIEW_USER", "FETCH_REVIEW_USER_REQUEST");
export const ACCEPT_REVIEW_USER_REQUEST = typeCreator("REVIEW_USER", "ACCEPT_REVIEW_USER_REQUEST");
export const DECLINE_REVIEW_USER_REQUEST = typeCreator(
  "REVIEW_USER",
  "DECLINE_REVIEW_USER_REQUEST"
);

export const SET_REVIEW_USER_ROLEID = "REVIEW_USER/SET_REVIEW_USER_ROLEID";
export const SET_REVIEW_USER_ROLENAME = "REVIEW_USER/SET_REVIEW_USER_ROLENAME";
export const SET_REVIEW_USER_DECLINE_MSG = "REVIEW_USER/SET_REVIEW_USER_DECLINE_MSG";
export const SET_REVIEW_USER_IS_ACCEPTED = "REVIEW_USER/SET_REVIEW_USER_IS_ACCEPTED";
export const SET_REVIEW_USER_IS_DECLINED = "REVIEW_USER/SET_REVIEW_USER_IS_DECLINED";

export interface userRequest {
  email?: string;
  nationality?: string;
  officeAddress?: string;
  city?: string;
  state?: string;
  county?: string;
  postalCode?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  jobTitle?: string;
  createdDate?: string;
  message?: string;
  userRoleId?: string;
  userRole?: string;
}
export interface ReviewUser {
  userRequest: userRequest;
  reviewRoleId: string;
  reviewRoleName: string;
  reviewDeclineMessage: string;
  isDeclined: boolean;
  isAccepted: boolean;
}

export type ReviewUserState = ReviewUser;

export interface FetchReviewUserAction extends AnyAction {
  type: typeof FETCH_REVIEW_USER_REQUEST.START;
  orgId: string;
  orgRoleId: string;
  requestId: string;
}

export interface FetchReviewUserSuccessAction extends AnyAction {
  type: typeof FETCH_REVIEW_USER_REQUEST.SUCCESS;
  payload: userRequest;
}
export interface SetReviewRoleAction extends AnyAction {
  type: typeof SET_REVIEW_USER_ROLENAME;
  value: string;
}

export interface SetReviewRoleIdAction extends AnyAction {
  type: typeof SET_REVIEW_USER_ROLEID;
  value: string;
}
export interface SetReviewDeclineMsgAction extends AnyAction {
  type: typeof SET_REVIEW_USER_DECLINE_MSG;
  value: string;
}
export interface AcceptReviewUserAction extends AnyAction {
  type: typeof ACCEPT_REVIEW_USER_REQUEST.START;
  orgId: string;
  orgRoleId: string;
  requestId: string;
  timeout?: number;
  resolve?: (response: unknown) => void;
}

export interface AcceptReviewUserSuccessAction extends AnyAction {
  type: typeof ACCEPT_REVIEW_USER_REQUEST.SUCCESS;
}

export interface AcceptReviewUserErrorAction extends AnyAction {
  type: typeof ACCEPT_REVIEW_USER_REQUEST.ERROR;
}
export interface DeclineReviewUserAction extends AnyAction {
  type: typeof DECLINE_REVIEW_USER_REQUEST.START;
  orgId: string;
  orgRoleId: string;
  requestId: string;
  resolve?: (response: unknown) => void;
}

export interface DeclineReviewUserSuccessAction extends AnyAction {
  type: typeof DECLINE_REVIEW_USER_REQUEST.SUCCESS;
}

export interface DeclineReviewUserErrorAction extends AnyAction {
  type: typeof DECLINE_REVIEW_USER_REQUEST.ERROR;
}
export interface SetReviewIsDeclinedAction extends AnyAction {
  type: typeof SET_REVIEW_USER_IS_DECLINED;
  value: boolean;
}
export interface SetReviewIsAcceptedAction extends AnyAction {
  type: typeof SET_REVIEW_USER_IS_ACCEPTED;
  value: boolean;
}

export type ReviewUserActionTypes =
  | FetchReviewUserAction
  | FetchReviewUserSuccessAction
  | SetReviewRoleAction
  | SetReviewRoleIdAction
  | AcceptReviewUserAction
  | AcceptReviewUserSuccessAction
  | AcceptReviewUserErrorAction
  | SetReviewDeclineMsgAction
  | DeclineReviewUserAction
  | DeclineReviewUserSuccessAction
  | DeclineReviewUserErrorAction
  | SetReviewIsDeclinedAction
  | SetReviewIsAcceptedAction;
