import React, { FC, useEffect, useState } from "react";
import { AxiosError } from "axios";

import { BodyCopy, Button } from "acca-design-system";
import { AppMessage, AuthService } from "@acca-portals/shared";
import errorIllustration from "../../assets/images/error-500-small.svg";

import content from "./ErrorPage.content";

interface ErrorPageProps {
  isTakeOver: boolean;
  resetErrorBoundary?: () => void;
  error: null | Error;
}

const ErrorPage: FC<ErrorPageProps> = ({ error, resetErrorBoundary, isTakeOver = true }) => {
  const [pageContent, setPageContent] = useState(content.standard);
  const [isTimeoutError, setIsTimeoutError] = useState(false);
  const { preTitle, title, message, buttonText, footer } = pageContent;
  const illustrationSrc = pageContent.illustration && errorIllustration;

  useEffect(() => {
    const appError = error as AxiosError;
    const errorCode = appError?.response?.data?.code;
    setIsTimeoutError(errorCode && errorCode.includes("998"));

    if (isTimeoutError) {
      setPageContent(content.timeout);
    }
  }, [error]);

  return (
    <AppMessage
      preTitle={preTitle}
      title={title}
      message={message}
      className={isTakeOver ? "u-bg-white" : null}
      buttons={[
        {
          text: buttonText,
          onClick: () => {
            if (!isTimeoutError) {
              return resetErrorBoundary();
            }
            return window.location.reload();
          },
          iconPosition: "right",
        },
      ]}
      footer={
        <>
          <h3 className="h700 u-font-heading u-margin-bottom-1">{footer.title}</h3>
          <BodyCopy>
            <p>
              {footer.message}{" "}
              <strong>
                <a
                  className="u-black"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={footer.link.href}
                >
                  {footer.link.text}
                </a>
              </strong>
            </p>
            <p>
              <Button onClick={() => AuthService.logout()} text="Log out" />
            </p>
          </BodyCopy>
        </>
      }
      illustrationSrc={illustrationSrc}
    />
  );
};

export default ErrorPage;
