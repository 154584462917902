import React, { FC } from "react";
import { isMorning, isAfternoon } from "../../utilities/dateUtils";

export interface WelcomeMessageProps {
  firstName?: string;
  className?: string;
}

export const getGreeting = (): string => {
  const now = new Date(Date.now());
  if (isMorning(now)) {
    return "Good morning";
  }

  if (isAfternoon(now)) {
    return "Good afternoon";
  }

  return "Good evening";
};

const WelcomeMessage: FC<WelcomeMessageProps> = ({ firstName, className = "" }) => (
  <h1 className={`welcome h500 u-font-heading ${className}`}>
    {firstName ? `${getGreeting()}, ${firstName}` : getGreeting()}
  </h1>
);

export default WelcomeMessage;
