function actionCreator(type: string, ...argNames: string[]) {
  return (...args: unknown[]) => {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}

export default actionCreator;
