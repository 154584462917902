export default {
  pageTitle: "Admin",
  firstnameInputLabel: "First name",
  lastnameInputLabel: "Last name",
  emailInputLabel: "Email",
  userRoleLabel: "User role",
  resendInvitationButtonLabel: "Resend invitation to join",
  cancelInvitePopupButtonLabel: "Cancel invite",
  cancelInviteButtonLabel: "Cancel invite to join",
  cancelPopupDescription: "Your invite will be cancelled.",
  userTitleTimestamp: "Invite sent on {joinedDate}",
  resendToastMessage: "New invitation emailed to {email}",
  cancelToastMessage: "Invitation to join cancelled",
};
