import { useMutation, useQuery } from "react-query";

import { AxiosResponse } from "axios";
import { ApiService, IUpdateErrorResponse } from "@acca-portals/shared";
import apiConfig from "../services/api/apiConfig";

export interface IUserDetails {
  accaId: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  primaryContactId: string;
  jobTitle: string;
  countryOfResidence: string;
  nationality: string;
}

export const useUserDetails = () =>
  useQuery<IUserDetails, Error>("userDetails", async () => {
    const response = await ApiService.CallApi<IUserDetails>({
      url: apiConfig.endpoints.user.userProfile,
      method: "GET",
    });

    return response.data;
  });

interface IUpdateBody {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const useUpdateUserDetails = () => {
  return useMutation<AxiosResponse<unknown>, IUpdateErrorResponse, IUpdateBody>((body) =>
    ApiService.CallApi({
      url: apiConfig.endpoints.user.userProfile,
      method: "PATCH",
      data: body,
    })
  );
};
