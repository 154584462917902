import { takeLatest, call, select } from "redux-saga/effects";
import { ICallApi } from "@acca-portals/shared";
import apiConfig from "../../configs/apiConfig";
import { DELETE_USER, FETCH_USER, SUBMIT_EDIT_USER, SubmitEditUserAction } from "./types";
import { useSaga } from "../utility/hooks/useSaga";

import { getUpdatedUserRole, getUpdatedUserRoleId } from "./selectors";

function* handleSubmitEditUser({ orgId, orgRoleId, contactId, resolve }: SubmitEditUserAction) {
  const role = {
    role: {
      id: yield select(getUpdatedUserRoleId),
      name: yield select(getUpdatedUserRole),
    },
  };

  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.user.patchUser(orgId, orgRoleId, contactId),
    method: "PATCH",
    data: role,
  };

  yield call(useSaga, [SUBMIT_EDIT_USER.success, SUBMIT_EDIT_USER.error], requestArgs, resolve);
}

function* handleFetchUser({ orgId, orgRoleId, contactId }: SubmitEditUserAction) {
  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.user.fetchUser(orgId, orgRoleId, contactId),
    method: "GET",
  };
  yield call(useSaga, [FETCH_USER.success, FETCH_USER.error], requestArgs);
}

function* handleDeleteUser({ orgId, orgRoleId, contactId, resolve }: SubmitEditUserAction) {
  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.user.deleteUser(orgId, orgRoleId, contactId),
    method: "DELETE",
  };

  yield call(useSaga, [DELETE_USER.success, DELETE_USER.error], requestArgs, resolve);
}

export default function* newUserSagas(): unknown {
  yield takeLatest(SUBMIT_EDIT_USER.START, handleSubmitEditUser);
  yield takeLatest(FETCH_USER.START, handleFetchUser);
  yield takeLatest(DELETE_USER.START, handleDeleteUser);
}
