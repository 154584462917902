import { AnyAction } from "redux";
import { typeCreator } from "../utility/types";

export const FETCH_USER_PROFILE = typeCreator("USER_PROFILE", "FETCH_USER_PROFILE");
interface Role {
  roleId: string;
  role: string;
  objectId: string;
  objectName: string;
  roleType: string;
  roleTypeId: string;
}
interface Persona {
  contactId: string;
  email: string;
  name?: string;
  roles?: Array<Role>;
}
export interface UserProfile {
  primaryContactId?: string;
  accaId?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  nationality?: string;
  countryOfResidence?: string;
  jobTitle?: string;
  personas?: Array<Persona>;
}

export type UserProfileState = UserProfile;

export interface FetchUserProfileAction extends AnyAction {
  type: typeof FETCH_USER_PROFILE.START;
  resolve: (response: unknown) => void;
}

export interface FetchUserProfileSuccessAction extends AnyAction {
  type: typeof FETCH_USER_PROFILE.SUCCESS;
  payload: UserProfile;
}

export type UserProfileActionTypes = FetchUserProfileAction | FetchUserProfileSuccessAction;
