import React, { FC } from "react";
import classnames from "classnames";

export interface ContainerProps {
  children: JSX.Element[] | JSX.Element;
  className?: string;
  innerClassName?: string;
  gridClasses?: string[];
}

const Container: FC<ContainerProps> = ({ children, className, innerClassName, gridClasses }) => {
  const classes = classnames("grid-container", "fluid", className);
  const gridClass = classnames(gridClasses, innerClassName);

  return (
    <div className={classes}>
      <div className={gridClass}>{children}</div>
    </div>
  );
};

Container.defaultProps = {
  gridClasses: ["grid-x", "grid-margin-y", "grid-margin-x"],
};

export default Container;
