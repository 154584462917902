export const SET_ORG_ID = "SET_ORG/SET_ORG_ID";
export const SET_ORG_ROLE_ID = "SET_ORG/SET_ORG_ROLE_ID";

export interface OrgState {
  orgId: string | null;
  orgRoleId: string | null;
}

export interface SetOrgIdAction {
  type: typeof SET_ORG_ID;
  value: string | null;
}

export interface SetOrgRoleIdAction {
  type: typeof SET_ORG_ROLE_ID;
  value: string | null;
}

export type OrgActionTypes = SetOrgIdAction | SetOrgRoleIdAction;
