import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "acca-design-system";
import { Container, PageContentLoader } from "@acca-portals/shared";

import { fetchOrganisations, updatePageNumber } from "../../state/organisationListing/actions";
import {
  getFilteredOrgListing,
  getShouldShowLoadMoreButton,
  getCurrentPageNumber,
  getShowFilter,
} from "../../state/organisationListing/selectors";

import { PageContent } from "../../state/content/types";
import { getOrganisationsListingContent } from "../../state/content/selectors";
import { fetchOrganisationsListingContent } from "../../state/content/actions";

import FilterList from "../../components/FilterList/FilterList";
import OrganisationListingCard from "./components/OrganisationListingCard/OrganisationListingCard";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";

const OrganisationsListingPage: FC = () => {
  const dispatch = useDispatch();
  const content: PageContent = useSelector(getOrganisationsListingContent);
  const userOrganisations = useSelector(getFilteredOrgListing);
  const shouldShowLoadMoreButton = useSelector(getShouldShowLoadMoreButton);
  const currentPageNumber = useSelector(getCurrentPageNumber);
  const showFilter = useSelector(getShowFilter);

  useEffect(() => {
    if (!content) {
      dispatch(fetchOrganisationsListingContent());
    }

    if (!userOrganisations) {
      dispatch(fetchOrganisations());
    }
  }, [dispatch]);

  const pageReady = content && userOrganisations;
  if (!pageReady) {
    return <PageContentLoader />;
  }

  return (
    <>
      <DefaultPageLayout
        titleContentText={content?.heading?.text}
        pageTitle="Admin"
        pageSubTitle={content?.heading?.text}
      >
        <Container className="section">
          <div className="cell">
            {showFilter && <FilterList title="Filter" />}
            {userOrganisations.result.map((page) =>
              page.map(({ organisationId, organisation, roles }, i) => (
                <OrganisationListingCard
                  key={i}
                  organisationId={organisationId}
                  organisation={organisation}
                  roles={roles}
                />
              ))
            )}
          </div>
          {shouldShowLoadMoreButton && (
            <div className="u-content-center u-margin-top-2 u-margin-bottom-3">
              <Button
                text="View More"
                variant="outlined"
                onClick={() => {
                  dispatch(updatePageNumber(currentPageNumber + 1));
                }}
              />
            </div>
          )}
        </Container>
      </DefaultPageLayout>
    </>
  );
};

export default OrganisationsListingPage;
