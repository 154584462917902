import React, { FC } from "react";
import { IconTimelineTick32, IconTimelineLock32, IconTimelineCircles32 } from "acca-design-system";
import formatDateMonthYear from "../../utilities/format-date";
import staticContent from "./QATimelineEvents.content";
import Unhighlighted from "./Cards/Unhighlighted";
import Highlighted from "./Cards/Highlighted";
import { Agreement, Quote } from "../../state/agreementsAndQuotes/types";
import QUOTE_STATUS from "../../constants/quoteStatus";
import AGREEMENT_STATUS from "../../constants/agreementStatus";
import "./qa-timeline-events.scss";

export interface QATimelineEventsProps {
  quoteData?: Quote;
  agreementData?: Agreement;
}

const QATimelineEvents: FC<QATimelineEventsProps> = ({ quoteData, agreementData }) => {
  const isQuote = !!quoteData;
  const preAgreement = (
    <Unhighlighted
      id="preAgreement"
      icon={IconTimelineLock32}
      title={staticContent.agreement.preAgree.title}
      description={`${staticContent.agreement.preAgree.description}`}
      cardStatus="inactive"
    />
  );
  const preSign = (
    <Unhighlighted
      id="preSign"
      icon={IconTimelineLock32}
      title={staticContent.agreement.preSign.title}
      description={`${staticContent.agreement.preSign.description}`}
      cardStatus="inactive"
    />
  );
  const completed = (
    <Unhighlighted
      id="wonWon"
      icon={IconTimelineTick32}
      title={staticContent.agreement.agreed.title}
      description={`${staticContent.agreement.agreed.description} ${
        agreementData && formatDateMonthYear(agreementData.modifiedDate)
      }`}
      cardStatus="inactive"
    />
  );
  const quoteAcceptedAwaitingAcca = (
    <Unhighlighted
      id="quoteAcceptedAwaitingAcca"
      icon={IconTimelineCircles32}
      title={staticContent.quote.accepted.title}
      description={`${staticContent.completed} ${
        (isQuote && formatDateMonthYear(quoteData.modifiedDate)) ||
        formatDateMonthYear(agreementData.publishedDate)
      }`}
      cardStatus="active"
    />
  );
  const quoteAccepted = (
    <Unhighlighted
      id="quoteAccepted"
      icon={IconTimelineTick32}
      title={staticContent.agreement.acceptedQuote.title}
      description={`${staticContent.completed} ${
        (isQuote && formatDateMonthYear(quoteData.modifiedDate)) ||
        formatDateMonthYear(agreementData.quoteAcceptedDate)
      }`}
      cardStatus={(isQuote && "active") || "inactive"}
    />
  );
  const quoteDeclined = (
    <Unhighlighted
      id="quoteDeclined"
      icon={IconTimelineTick32}
      title={staticContent.quote.declined.title}
      description={`${staticContent.quote.declined.description} ${
        isQuote && formatDateMonthYear(quoteData.modifiedDate)
      }`}
      cardStatus="active"
    />
  );
  const signed = !isQuote && (
    <Unhighlighted
      id="signed"
      icon={IconTimelineTick32}
      title={staticContent.agreement.signed.title}
      description={`${staticContent.agreement.signed.description} ${formatDateMonthYear(
        agreementData.agreementSignedDate
      )}`}
      cardStatus="active"
    />
  );
  const signaturesCompleted = !isQuote && (
    <Unhighlighted
      id="signed"
      icon={IconTimelineTick32}
      title={staticContent.agreement.signedAndActivated.title}
      description={`${staticContent.agreement.signedAndActivated.description} ${formatDateMonthYear(
        agreementData.agreementSignedDate
      )}`}
      cardStatus="inactive"
    />
  );
  const expired = !isQuote && (
    <Unhighlighted
      id="expired"
      icon={IconTimelineCircles32}
      title={staticContent.agreement.expired.title}
      description={`${staticContent.agreement.expired.description} ${formatDateMonthYear(
        agreementData.agreementEndDate
      )}`}
      cardStatus="active"
      hasButton
    />
  );
  const signaturePending = !isQuote && (
    <Unhighlighted
      id="signaturePending"
      icon={IconTimelineCircles32}
      title={staticContent.agreement.signaturePending.title}
      description={staticContent.agreement.signaturePending.description}
      cardStatus="active"
    />
  );
  const inactive = !isQuote && (
    <Unhighlighted
      id="inactive"
      icon={IconTimelineCircles32}
      title={staticContent.agreement.inactive.title}
      description={staticContent.agreement.inactive.description}
      cardStatus="active"
      hasButton
    />
  );
  const withAcca = !isQuote && (
    <Unhighlighted
      id="withAccs"
      icon={IconTimelineCircles32}
      title={staticContent.agreement.withAcca.title}
      description={`${staticContent.agreement.withAcca.description} ${formatDateMonthYear(
        agreementData.modifiedDate
      )}`}
      cardStatus="active"
    />
  );
  const declinedAgreement = !isQuote && (
    <Unhighlighted
      id="declined"
      icon={IconTimelineCircles32}
      title={staticContent.agreement.declined.title}
      description={`${staticContent.agreement.declined.description} ${formatDateMonthYear(
        agreementData.modifiedDate
      )}`}
      cardStatus="active"
    />
  );
  const cancelledAgreement = !isQuote && (
    <Unhighlighted
      id="cancelled"
      icon={IconTimelineTick32}
      title={staticContent.agreement.cancelled.title}
      description={`${staticContent.agreement.cancelled.description} ${formatDateMonthYear(
        agreementData.cancelledDate
      )}`}
      cardStatus="active"
    />
  );
  const activeState = (
    <Highlighted isQuote={isQuote} quoteData={quoteData} agreementData={agreementData} />
  );
  const activateAgreement = (
    <Highlighted isQuote={false} quoteData={quoteData} agreementData={agreementData} />
  );
  const acceptAgreement = (
    <Highlighted isQuote={false} quoteData={quoteData} agreementData={agreementData} />
  );

  if (isQuote) {
    switch (quoteData.status) {
      case QUOTE_STATUS.IN_PROGRESS:
        return (
          <>
            {preAgreement}
            {preSign}
            {activeState}
          </>
        );
      case QUOTE_STATUS.DECLINED:
        return (
          <>
            {preAgreement}
            {preSign}
            {quoteDeclined}
          </>
        );
      case QUOTE_STATUS.ACCEPTED:
        return (
          <>
            {preAgreement}
            {quoteAcceptedAwaitingAcca}
            {quoteAccepted}
          </>
        );
      case QUOTE_STATUS.EXPIRED:
        return (
          <>
            {preAgreement}
            {activeState}
          </>
        );
      case QUOTE_STATUS.AWAITING:
        return (
          <>
            {preAgreement}
            {preSign}
            {quoteDeclined}
          </>
        );
      default:
        return <p>No details available</p>;
    }
  } else {
    switch (agreementData.status) {
      case AGREEMENT_STATUS.INCOMPLETE.ACTIVE_DEADLINE_IMMINENT:
        return (
          <>
            {preAgreement}
            {acceptAgreement}
            {quoteAccepted}
          </>
        );
      case AGREEMENT_STATUS.INCOMPLETE.NOT_YET_ACCEPTED:
        return (
          <>
            {preAgreement}
            {acceptAgreement}
            {quoteAccepted}
          </>
        );
      case AGREEMENT_STATUS.INCOMPLETE.SIGNATURE_PENDING:
        return (
          <>
            {preAgreement}
            {signaturePending}
            {quoteAccepted}
          </>
        );
      case AGREEMENT_STATUS.INCOMPLETE.SIGNED:
        return (
          <>
            {preAgreement}
            {signed}
            {quoteAccepted}
          </>
        );
      case AGREEMENT_STATUS.INCOMPLETE.INACTIVE:
        return (
          <>
            {preAgreement}
            {preSign}
            {inactive}
            {quoteAccepted}
          </>
        );
      case AGREEMENT_STATUS.STALE.ON_HOLD:
        return (
          <>
            {preAgreement}
            {withAcca}
            {quoteAccepted}
          </>
        );
      case AGREEMENT_STATUS.STALE.EXPIRED:
        return (
          <>
            {expired}
            {completed}
            {signaturesCompleted}
            {quoteAccepted}
          </>
        );
      case AGREEMENT_STATUS.STALE.DECLINED:
        return (
          <>
            {preAgreement}
            {declinedAgreement}
            {quoteAccepted}
          </>
        );
      case AGREEMENT_STATUS.STALE.CANCELLED:
        return (
          <>
            {preAgreement}
            {cancelledAgreement}
            {quoteAccepted}
          </>
        );
      case AGREEMENT_STATUS.COMPLETE:
        return (
          <>
            {completed}
            {signaturesCompleted}
            {quoteAccepted}
          </>
        );
      default:
        return <p>No details available</p>;
    }
  }
};

export default QATimelineEvents;
