import React, { ChangeEvent, FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { RadioButton, ToggleControlGroup } from "acca-design-system";
import { sentenceCase } from "@acca-portals/shared";
import { getUserRoles } from "../../state/lookups/selectors";
import { fetchUserRoles } from "../../state/lookups/actions";
import { RootState } from "../../state/rootReducer";

export interface RoleSelectorProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  stateSelector: (state: RootState) => string;
  className?: string;
}

const RoleSelector: FC<RoleSelectorProps> = ({ onChange, stateSelector, className }) => {
  const dispatch = useDispatch();
  const userRoles = useSelector(getUserRoles);
  const userRole = useSelector(stateSelector);

  useEffect(() => {
    if (!userRoles) dispatch(fetchUserRoles());
  }, [dispatch]);

  const renderOption = (key, value) => {
    return (
      <RadioButton
        id={key}
        key={key}
        value={value}
        text={sentenceCase(value.toLowerCase())}
        onChange={onChange}
        checked={userRole === value}
        boxed
      />
    );
  };

  return (
    <ToggleControlGroup id="userRole" className={className}>
      {userRoles?.map((userRoleEntry) => {
        return renderOption(userRoleEntry.id, userRoleEntry.value);
      })}
    </ToggleControlGroup>
  );
};

export default RoleSelector;
