import React, { FC } from "react";
import {
  Card,
  BodyCopy,
  PageHeader,
  IconQualification32,
  IconExchange32,
  IconMarketing32,
  IconPassword32,
} from "acca-design-system";
import HTMLReactParser from "html-react-parser";
import { useHistory } from "react-router-dom";
import { Section, useContent } from "@acca-portals/shared";

import apiConfig from "../../configs/apiConfig";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";
import ROUTES from "../../constants/routes";

interface IMyDetailsPageContent {
  logindescription: {
    value: string;
  };
  logintitle: {
    text: string;
  };
  marketingdescription: {
    value: string;
  };
  marketingtitle: {
    text: string;
  };
  contactdetailsdescription: {
    value: string;
  };
  contactdetailstitle: {
    text: string;
  };
  accountDetailsDescription: {
    value: string;
  };
  accountDetailsTitle: {
    text: string;
  };
  pageheading: {
    text: string;
  };
  pagetitle: {
    text: string;
  };
}

const MyDetailsPage: FC = () => {
  const history = useHistory();
  const content = useContent<IMyDetailsPageContent>(apiConfig.endpoints.templates.myDetailsPage);

  const pageError = content.error;
  const isPageReady = content.isSuccess;

  const renderMain = () => {
    if (pageError || !isPageReady) {
      return null;
    }

    const {
      accountDetailsTitle,
      accountDetailsDescription,
      contactdetailsdescription,
      contactdetailstitle,
      logindescription,
      logintitle,
      marketingdescription,
      marketingtitle,
      pageheading,
      pagetitle,
    } = content.data;
    return (
      <>
        <PageHeader
          className="u-margin-bottom-2"
          pageTitle={pagetitle?.text}
          quote={pageheading?.text}
        />
        <Section>
          <Card
            onClick={() => history.push(ROUTES.accountDetails)}
            icon={IconQualification32}
            iconSize="large"
            className="u-margin-bottom-1"
          >
            <div className="u-content-container-medium">
              <h3 className="section-title">{accountDetailsTitle?.text}</h3>
              <BodyCopy>{HTMLReactParser(accountDetailsDescription?.value)}</BodyCopy>
            </div>
          </Card>
          <Card
            onClick={() => history.push(ROUTES.contactDetailsPage)}
            icon={IconExchange32}
            iconSize="large"
            className="u-margin-bottom-1"
          >
            <div className="u-content-container-medium">
              <h3 className="section-title">{contactdetailstitle?.text}</h3>
              <BodyCopy>{HTMLReactParser(contactdetailsdescription?.value)}</BodyCopy>
            </div>
          </Card>
          <Card
            onClick={() => history.push(ROUTES.marketingConsentPage)}
            icon={IconMarketing32}
            iconSize="large"
            className="u-margin-bottom-1"
          >
            <div className="u-content-container-medium">
              <h3 className="section-title">{marketingtitle?.text}</h3>
              <BodyCopy>{HTMLReactParser(marketingdescription?.value)}</BodyCopy>
            </div>
          </Card>
          <Card
            onClick={() => history.push(ROUTES.loginDetailsPage)}
            icon={IconPassword32}
            iconSize="large"
            className="u-margin-bottom-1"
          >
            <div className="u-content-container-medium">
              <h3 className="section-title">{logintitle?.text}</h3>
              <BodyCopy>{HTMLReactParser(logindescription?.value)}</BodyCopy>
            </div>
          </Card>
        </Section>
      </>
    );
  };

  return (
    <DefaultPageLayout loading={!isPageReady} error={pageError}>
      {renderMain()}
    </DefaultPageLayout>
  );
};

export default MyDetailsPage;
