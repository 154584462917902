const AGREEMENT_STATUS = {
  COMPLETE: "ACTIVE",
  INCOMPLETE: {
    SIGNATURE_PENDING: "SIGNATURE_PENDING",
    SIGN_PEND_DEADLINE_IMMINENT: "SIGN_PEND_DEADLINE_IMMINENT",
    SIGNED: "SIGNED",
    ACTIVE_DEADLINE_IMMINENT: "ACTIVE_DEADLINE_IMMINENT",
    NOT_YET_ACCEPTED: "PUBLISHED",
    INACTIVE: "INACTIVE",
  },
  STALE: {
    ON_HOLD: "ON_HOLD",
    EXPIRED: "EXPIRED",
    DECLINED: "DECLINED",
    CANCELLED: "CANCELLED",
  },
};

export default AGREEMENT_STATUS;
