import React, { FC, useState, useEffect } from "react";
import { Alert, PreferenceCard, BodyCopy } from "acca-design-system";
import { CardFootnote, replaceTemplates } from "@acca-portals/shared";
import HTMLReactParser from "html-react-parser";
import DATE_FORMATS from "../../constants/dates";
import { formatDate } from "../../utilities/dateUtils";
import MarketingPauseCard from "./MarketingPauseCard/MarketingPauseCard";
import MarketingUnsubscribeReasonCard from "./MarketingUnsubscribeReasonCard/MarketingUnsubscribeReasonCard";
import staticContent from "./MarketingConsentCard.content";

interface IOption {
  id: string;
  value: string;
  label: string;
}

const NO_OPTION: IOption = {
  id: "no",
  value: "no",
  label: "No",
};

const YES_OPTION: IOption = {
  id: "yes",
  value: "yes",
  label: "Yes",
};

const PAUSE_OPTION: IOption = {
  id: "paused",
  value: "paused",
  label: "Paused",
};

enum STEPS {
  VIEW,
  PAUSING,
  UNSUBSCRIBE_REASON,
}

interface IMarketingConsentCardProps {
  content: {
    title: string;
    description: string;
  };
  type: string;
  consent: {
    enabled: boolean;
    paused: boolean;
    pauseDetails?: {
      period: number;
      endDate: string;
    };
  };
  className?: string;
  onUpdate: (body: any) => Promise<any>;
}

const MarketingConsentCard: FC<IMarketingConsentCardProps> = ({
  type,
  content,
  consent,
  className,
  onUpdate,
}) => {
  const [isPaused, setIsPaused] = useState(null);
  const { paused, enabled, pauseDetails } = consent;
  const period = pauseDetails?.period;

  const [consentUpdatedMessage, setConsentUpdatedMessage] = useState<string>();
  const [step, setStep] = useState(STEPS.VIEW);
  const [selectedOption, setSelectedOption] = useState<IOption>();
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (paused) {
      setSelectedOption(PAUSE_OPTION);
    } else {
      setSelectedOption(enabled ? YES_OPTION : NO_OPTION);
    }
  }, [paused, enabled]);

  const options =
    selectedOption === PAUSE_OPTION
      ? [NO_OPTION, PAUSE_OPTION, YES_OPTION]
      : [NO_OPTION, YES_OPTION];

  const update = (body) => {
    setIsUpdating(true);

    return onUpdate({
      consents: {
        [type]: body,
      },
    }).finally(() => setIsUpdating(false));
  };

  const handleToggle = (option: IOption) => {
    if (option === selectedOption) {
      return;
    }

    setConsentUpdatedMessage(null);
    setIsPaused(null);

    if (option === YES_OPTION) {
      update({ enabled: true }).then(() => {
        setStep(STEPS.VIEW);
        setSelectedOption(YES_OPTION);
      });
    }

    if (option === NO_OPTION) {
      setStep(STEPS.PAUSING);
      setSelectedOption(NO_OPTION);
    }
  };

  const showUnsubscribedView = () => {
    setStep(STEPS.VIEW);
    setConsentUpdatedMessage(staticContent.unsubscribedFootnote);
  };

  const handleUnsubscribeReasonSubmit = (reasonId: string) => {
    update({ enabled: false, unsubscribeReason: reasonId });
    showUnsubscribedView();
  };

  const handleMarketingPauseSubmit = (pausedays: number, selectedPauseOption: boolean) => {
    if (!selectedPauseOption) {
      setIsPaused(false);
      update({ paused: false, enabled: false }).then(() => {
        setSelectedOption(NO_OPTION);
        setStep(STEPS.UNSUBSCRIBE_REASON);
      });
    } else {
      update({
        paused: true,
        enabled: false,
        pauseDetails: {
          period: pausedays,
        },
      }).then(() => {
        setSelectedOption(PAUSE_OPTION);
        setConsentUpdatedMessage(staticContent.pausedFootnote);
        setStep(STEPS.VIEW);
        setIsPaused(selectedPauseOption);
      });
    }
  };

  return (
    <div className="marketing-consent-card">
      <PreferenceCard
        heading={content.title}
        handleToggle={handleToggle}
        selectedOption={selectedOption}
        options={options}
        className={className}
        loading={isUpdating}
      >
        <>
          <BodyCopy>{HTMLReactParser(content.description)}</BodyCopy>
          {isPaused && (
            <BodyCopy className="u-margin-top-2 notice">
              <Alert inline>{HTMLReactParser(staticContent.alertMessage)}</Alert>
              <p>
                {replaceTemplates(staticContent.subscriptionMessage, {
                  subscriptionDate: formatDate(
                    pauseDetails?.endDate,
                    DATE_FORMATS.FORMAT_DAY_ABBREVIATEDMONTH_YEAR
                  ),
                })}
              </p>
            </BodyCopy>
          )}
        </>
      </PreferenceCard>
      {consentUpdatedMessage && <CardFootnote text={consentUpdatedMessage} />}
      {step === STEPS.PAUSING && (
        <MarketingPauseCard
          type={type}
          period={period}
          isUpdating={isUpdating}
          onSubmit={handleMarketingPauseSubmit}
        />
      )}
      {step === STEPS.UNSUBSCRIBE_REASON && (
        <MarketingUnsubscribeReasonCard
          onSubmit={handleUnsubscribeReasonSubmit}
          onDismiss={showUnsubscribedView}
        />
      )}
    </div>
  );
};

export default MarketingConsentCard;
