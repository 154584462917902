export default {
  title: "A digital signature is required to activate this agreement",
  messageHeading:
    "We use DocuSign for signing agreements. Please select who is eligible and authorised to sign the agreement. New signatories can be added with name and email address (up to a maximum of 3).",
  selectSignatories: "Select other signatories",
  additionalSignatories: "Additional signatories",
  dropdownPlaceholder: "Please select signatory",
  buttonCTA: "Add new signatory",
  acceptModalInfoMessage: "You have reached a maximum of 3 signatories.",
};
