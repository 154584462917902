import {
  OrganisationListingState,
  OrganisationActionTypes,
  FETCH_ORGANISATIONS,
  UPDATE_ORG_LISTING_FILTER,
  RESET_ORG_LISTING_FILTER,
  UPDATE_PAGE_NUMBER,
} from "./types";

export const initialState: OrganisationListingState = {
  selectedFilters: [],
  pageNumber: 1,
  itemsPerPage: 5,
};

export default function organisationsReducer(
  state = initialState,
  action: OrganisationActionTypes
): OrganisationListingState {
  switch (action.type) {
    case FETCH_ORGANISATIONS.SUCCESS:
      return {
        ...state,
        organisations: action.payload.organisations,
        requested: action.payload.requested,
      };
    case UPDATE_ORG_LISTING_FILTER: {
      if (state.selectedFilters.includes(action.option)) {
        return {
          ...state,
          selectedFilters: [],
          pageNumber: 1,
        };
      }
      return {
        ...state,
        selectedFilters: [action.option],
        pageNumber: 1,
      };
    }
    case RESET_ORG_LISTING_FILTER: {
      return {
        ...state,
        selectedFilters: [],
        pageNumber: 1,
      };
    }
    case UPDATE_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.pageNumber,
      };
    }
    default:
      return state;
  }
}
