const baseUrl = process.env.AEM_BASE_URL;

const bundleImages = {
  bundleImages: [
    `${baseUrl}/content/dam/api/products/product1.jpg`,
    `${baseUrl}/content/dam/api/products/product2.jpg`,
    `${baseUrl}/content/dam/api/products/product3.jpg`,
    `${baseUrl}/content/dam/api/products/product4.jpg`,
    `${baseUrl}/content/dam/api/products/product5.jpg`,
    `${baseUrl}/content/dam/api/products/product6.jpg`,
  ],
};

export default bundleImages;
