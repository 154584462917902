import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentList } from "acca-design-system";
import { Container, PageContentLoader, useNotifications } from "@acca-portals/shared";
import { fetchOrganisationOverview } from "../../state/organisationOverview/action";
import { getOrganisation } from "../../state/organisationOverview/selectors";
import { getUserFirstName } from "../../state/userProfile/selectors";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";
import ROUTES from "../../constants/routes";
import { fetchOrganisations } from "../../state/organisationListing/actions";
import { getOrgId, getOrgRoleId } from "../../state/org/selectors";
import Notifications from "../../components/Notifications/Notifications";

const LandingPage: FC = () => {
  const firstName = useSelector(getUserFirstName);
  const dispatch = useDispatch();
  const { notificationsLoading, notifications } = useNotifications();

  const orgId = useSelector(getOrgId);
  const orgRoleId = useSelector(getOrgRoleId);
  const org = useSelector((state) => getOrganisation(state, orgId, orgRoleId));

  useEffect(() => {
    dispatch(fetchOrganisations());
    dispatch(fetchOrganisationOverview(orgId, orgRoleId));
  }, [dispatch, orgId, orgRoleId]);

  const pageReady = org && !notificationsLoading;
  if (!pageReady) {
    return <PageContentLoader />;
  }

  return (
    <DefaultPageLayout
      titleContentText="Landing Page"
      pageTitle="Landing Page"
      showGreetings
      firstName={firstName}
    >
      {notifications && (
        <Notifications notifications={notifications.filter((n) => n.priority !== "LOW")} />
      )}

      <Container className="section">
        <ContentList>
          <ul>
            <li>
              Go to <Link to={ROUTES.organisationsListing}>Your organisations</Link>
            </li>
          </ul>
        </ContentList>
      </Container>
    </DefaultPageLayout>
  );
};

export default LandingPage;
