export const SUBMIT_NEW_USER = "ADD_USER/SUBMIT_NEW_USER";
export const SUBMIT_NEW_USER_SUCCESS = "ADD_USER/SUBMIT_NEW_USER_SUCCESS";
export const SUBMIT_NEW_USER_ERROR = "ADD_USER/SUBMIT_NEW_USER_ERROR";

export const SET_FIRSTNAME = "ADD_USER/SET_FIRSTNAME";
export const SET_LASTNAME = "ADD_USER/SET_LASTNAME";
export const SET_EMAIL = "ADD_USER/SET_EMAIL";
export const SET_ROLE = "ADD_USER/SET_ROLE";
export const SET_ROLE_ID = "ADD_USER/SET_ROLE_ID";
export const SET_MESSAGE = "ADD_USER/SET_MESSAGE";
export const RESET_USER_DETAILS = "ADD_USER/RESET_USER_DETAILS";
export const SET_ERROR_CODE = "ADD_USER/SET_ERROR_CODE";
export const RESET_ERROR_CODE = "ADD_USER/RESET_ERROR_CODE";

export interface NewUserDetailsState {
  firstName: string;
  lastName: string;
  emailAddress: string;
  role: string;
  roleId: string;
  message: string;
  error?: Error;
  errorCode?: string;
}

export interface SubmitNewUserAction {
  type: typeof SUBMIT_NEW_USER;
  orgId: string;
  orgRoleId: string;
  resolve?: (response: unknown) => void;
}

export interface SubmitNewUserSuccessAction {
  type: typeof SUBMIT_NEW_USER_SUCCESS;
  payload: {
    organisationId?: string;
    accaId?: string;
  };
}

export interface SubmitNewUserErrorAction {
  type: typeof SUBMIT_NEW_USER_ERROR;
  error?: Error;
  errorCode?: string;
  resolve?: (response: unknown) => void;
}

export interface SetFirstNameAction {
  type: typeof SET_FIRSTNAME;
  value: string;
}

export interface SetLastNameAction {
  type: typeof SET_LASTNAME;
  value: string;
}

export interface SetEmailAction {
  type: typeof SET_EMAIL;
  value: string;
}

export interface SetRoleAction {
  type: typeof SET_ROLE;
  value: string;
}

export interface SetRoleIdAction {
  type: typeof SET_ROLE_ID;
  value: string;
}

export interface SetMessageAction {
  type: typeof SET_MESSAGE;
  value: string;
}

export interface ResetUserDetailsAction {
  type: typeof RESET_USER_DETAILS;
}

export interface SetErrorCodeAction {
  type: typeof SET_ERROR_CODE;
  value: string;
}

export interface ResetErrorCodeAction {
  type: typeof RESET_ERROR_CODE;
}

export type NewUserActionTypes =
  | SubmitNewUserAction
  | SubmitNewUserSuccessAction
  | SubmitNewUserErrorAction
  | SetFirstNameAction
  | SetLastNameAction
  | SetEmailAction
  | SetRoleAction
  | SetRoleIdAction
  | SetMessageAction
  | ResetUserDetailsAction
  | SetErrorCodeAction
  | ResetErrorCodeAction;
