import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { SizeMe } from "react-sizeme";
import {
  BodyCopy,
  Button,
  Card,
  IconArrowLeft16,
  Modal,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHeadCell,
  Timeline,
} from "acca-design-system";
import {
  Container,
  PageContentLoader,
  replaceTemplates,
  SubmittingMessage,
} from "@acca-portals/shared";
import QueryString from "query-string";
import PdfViewer from "../../components/PdfViewer/PdfViewer";
import formatDateMonthYear from "../../utilities/format-date";
import { fetchAgreements, fetchAgreementInfo } from "../../state/agreementsAndQuotes/actions";
import {
  getQuote,
  getAgreement,
  getTimelineSubmitting,
} from "../../state/agreementsAndQuotes/selectors";
import { getOrgId, getOrgRoleId } from "../../state/org/selectors";
import QATimelineEvents from "../../components/QATimelineEvents/QATimelineEvents";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";
import staticContent from "./AgreementQuoteInfoPage.content";
import QUOTE_CATEGORY from "../../constants/quoteCategory";
import ZERO_VALUE from "../../constants/zeroValue";
import "./agreement-quote-info-page.scss";

const AgreementQuoteInfoPage: FC = () => {
  const [showDocumentModal, setShowDocumentModal] = useState(null);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { refNumber } = QueryString.parse(search);
  const quote = useSelector((state) => getQuote(state, refNumber));
  const agreement = useSelector((state) => getAgreement(state));
  const orgId = useSelector(getOrgId);
  const orgRoleId = useSelector(getOrgRoleId);
  const isSubmitting = useSelector(getTimelineSubmitting);
  const isQuote = !!quote;
  const pageReady = (agreement && agreement.id === refNumber) || isQuote;

  useEffect(() => {
    if (!pageReady) {
      dispatch(fetchAgreements(orgId, orgRoleId));
      dispatch(fetchAgreementInfo(orgId, orgRoleId, refNumber));
    }
  }, [dispatch, quote, agreement]);

  if (!pageReady) {
    return <PageContentLoader />;
  }

  const categories =
    isQuote &&
    quote.productsList
      .map((item) => item.category)
      .reduce((categoriesArray, currentCategory) => {
        if (categoriesArray.indexOf(currentCategory) === -1) {
          categoriesArray.push(currentCategory);
        }
        return categoriesArray;
      }, [])
      .sort((x, y) => {
        return (
          (x === QUOTE_CATEGORY.EXEMPTIONS && -1) || (y === QUOTE_CATEGORY.EXEMPTIONS && 1) || 0
        );
      });

  const currencySymbol = (isQuote && quote.currencySymbol) || agreement.currencySymbol;
  const currencyRegExp = new RegExp(currencySymbol, "g");
  const tableHeading =
    isQuote && !categories.includes("Exemptions")
      ? quote.productsList[0].category
      : staticContent.quote.exemptions;

  const pageTitle = isQuote ? staticContent.quote.pageTitle : staticContent.agreement.pageTitle;
  const bundle = isQuote
    ? { quoteName: quote.productBundle }
    : { agreementName: agreement.productBundle };
  const activatedDate = agreement && formatDateMonthYear(agreement.modifiedDate);
  const agreementTitleAndId =
    !isQuote && `${staticContent.agreement.idTitle} ${agreement.agreementNumber}`;

  if (isSubmitting) {
    return <SubmittingMessage title="Saving" />;
  }

  let title = replaceTemplates(pageTitle, bundle);
  if (!isQuote) {
    if (agreement.status === "SIGNATURE_PENDING") {
      title = staticContent.agreement.pageTitleAgreementSignatuerPending;
    } else if (agreement.status === "SIGNED") {
      title = staticContent.agreement.pageTitleAgreementSigned;
    } else if (agreement.status === "ACTIVE") {
      title = staticContent.agreement.pageTitleAgreementActive;
    } else {
      title = staticContent.agreement.agreementTitle;
    }
  }
  return (
    <DefaultPageLayout
      titleContentText={staticContent.shared.titleContentText}
      pageSubTitleFontSize="h500"
    >
      <SizeMe>
        {({ size }) => (
          <Container className="section">
            <section className="cell u-margin-bottom-4">
              <Button
                className="u-margin-bottom-2"
                iconPosition="left"
                icon={IconArrowLeft16}
                variant="link"
                to="/admin/agreements"
                text={staticContent.shared.backButton}
              />
              <h1 className="h400 u-margin-bottom-2">{title}</h1>
              {!isQuote && agreement.status === "SIGNED" && (
                <p className="u-margin-bottom-4">
                  {staticContent.agreement.activateDate} {activatedDate}
                </p>
              )}

              <h2 className="h600 u-margin-bottom-2">
                {(isQuote && `${staticContent.quote.idTitle} ${quote.quoteNumber}`) ||
                  agreementTitleAndId}
              </h2>
              <Timeline visibleEvents={4} buttonText="">
                <QATimelineEvents quoteData={quote} agreementData={agreement} />
              </Timeline>
            </section>

            {quote && (
              <section className="cell">
                <h2>
                  {replaceTemplates(staticContent.quote.detailsTitle, {
                    quoteName: quote.productBundle,
                  })}
                </h2>
                <p>
                  {replaceTemplates(staticContent.quote.detailsDuration, {
                    duration: `from: ${quote.validFrom} untill: ${quote.validTo}`,
                  })}
                </p>
                <p>{quote.productBundleDescription}</p>

                {quote.productsList && (
                  <Card className="u-margin-top-2">
                    <Table className="products-table">
                      <thead>
                        <tr className="product-row_label">
                          <TableHeadCell scope="row">{tableHeading}</TableHeadCell>
                          <TableHeadCell scope="row">
                            {staticContent.quote.standardPrice} {currencySymbol}
                          </TableHeadCell>
                          <TableHeadCell scope="row">
                            {staticContent.quote.price} {currencySymbol}
                          </TableHeadCell>
                        </tr>
                      </thead>
                      <TableBody>
                        {categories.map((cat, idx) => {
                          const isExemptions = cat === QUOTE_CATEGORY.EXEMPTIONS;
                          return (
                            <React.Fragment key={`product-bundle-row-${idx}`}>
                              <TableRow className="product-bundle-row">
                                <TableCell
                                  colspan="3"
                                  className={
                                    cat === tableHeading ? "product-bundle-cell--first" : ""
                                  }
                                >
                                  <strong>
                                    {isExemptions ? (
                                      <span className="responsive-screen-reader-text">
                                        {quote.productBundle}
                                      </span>
                                    ) : (
                                      cat && cat.replace(/ACCA/g, "")
                                    )}
                                  </strong>
                                </TableCell>
                              </TableRow>

                              {quote.productsList.map((item, i) => {
                                if (item.category === cat) {
                                  return (
                                    <React.Fragment key={`category-row-${i}`}>
                                      <TableRow>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>
                                          {item.pricing.standardPrice.replace(currencyRegExp, "")}
                                        </TableCell>
                                        <TableCell>
                                          {item.pricing.offerPrice.replace(currencyRegExp, "")}
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                            </React.Fragment>
                          );
                        })}
                        <TableRow key="product-total-row_label" className="product-total-row_label">
                          <TableCell> </TableCell>
                          <TableCell>
                            {staticContent.quote.standardPrice} {currencySymbol}
                          </TableCell>
                          <TableCell>
                            {staticContent.quote.price} {currencySymbol}
                          </TableCell>
                        </TableRow>
                        <TableRow key="product-total-row_price" className="product-total-row_price">
                          <TableCell>
                            {replaceTemplates(staticContent.quote.total, {
                              currencySymbol: quote.currencySymbol,
                            })}
                          </TableCell>
                          <TableCell>{quote.totalAmount.replace(currencyRegExp, "")}</TableCell>
                          <TableCell>{quote.discountAmount.replace(currencyRegExp, "")}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    {quote.totalLineDiscountAmount.replace(currencyRegExp, "") !==
                      ZERO_VALUE.NO_SAVINGS && (
                      <h3 className="savings-statement u-text-right">
                        {replaceTemplates(staticContent.quote.savingsStatement, {
                          totalLineDiscountAmount: quote.totalLineDiscountAmount,
                        })}
                      </h3>
                    )}
                  </Card>
                )}
              </section>
            )}
            {!quote && (
              <section className="cell">
                <h2>{staticContent.agreement.agreementTitle}</h2>
                {agreement.ownerContactDetails && (
                  <>
                    <h3 className="u-margin-top-2">{staticContent.agreement.acca}</h3>
                    {agreement.ownerContactDetails.name && (
                      <p className="u-margin-top-1">{agreement.ownerContactDetails.name}</p>
                    )}
                    {agreement.ownerContactDetails.phoneNumber && (
                      <p>{agreement.ownerContactDetails.phoneNumber}</p>
                    )}
                    {agreement.ownerContactDetails.emailAddress && (
                      <p>{agreement.ownerContactDetails.emailAddress}</p>
                    )}
                  </>
                )}
                {agreement.partnerContactDetails && (
                  <>
                    <h3 className="u-margin-top-2">{agreement.partnerContactDetails.name}</h3>
                    <p className="u-margin-top-1 u-margin-bottom-3">
                      {agreement.partnerContactDetails.addressLine1 && (
                        <>
                          {agreement.partnerContactDetails.addressLine1}
                          <br />
                        </>
                      )}
                      {agreement.partnerContactDetails.addressLine2 && (
                        <>
                          {agreement.partnerContactDetails.addressLine2}
                          <br />
                        </>
                      )}
                      {agreement.partnerContactDetails.city && (
                        <>
                          {agreement.partnerContactDetails.city}
                          <br />
                        </>
                      )}
                      {agreement.partnerContactDetails.postalCode && (
                        <>
                          {agreement.partnerContactDetails.postalCode}
                          <br />
                        </>
                      )}
                      {agreement.partnerContactDetails.countryOfResidence && (
                        <>
                          {agreement.partnerContactDetails.countryOfResidence}
                          <br />
                        </>
                      )}
                    </p>
                  </>
                )}
                {agreement.agreementStartDate && agreement.agreementEndDate && (
                  <p>{`
                ${staticContent.agreement.agreementTermFrom} 
                ${formatDateMonthYear(agreement.agreementStartDate)} 
                ${staticContent.agreement.agreementTermUntill} 
                ${formatDateMonthYear(agreement.agreementEndDate)}`}</p>
                )}

                {agreement.document && (
                  <PdfViewer
                    content={agreement.document.content}
                    text={staticContent.agreement.document}
                    triggerFullScreen={() => setShowDocumentModal(true)}
                    documentTitle={agreement.document.fileName}
                    documentWidth={size.width}
                  />
                )}
              </section>
            )}
          </Container>
        )}
      </SizeMe>
      {showDocumentModal && (
        <Modal
          className="documentModal"
          closeLinkText="Cancel"
          closeModal={() => setShowDocumentModal(false)}
        >
          <SizeMe>
            {({ size }) => (
              <BodyCopy className="u-margin-bottom-3 u-content-center">
                <PdfViewer
                  content={agreement.document.content}
                  text={staticContent.agreement.document}
                  showFullscreen
                  documentWidth={size.width}
                />
              </BodyCopy>
            )}
          </SizeMe>
        </Modal>
      )}
    </DefaultPageLayout>
  );
};

export default AgreementQuoteInfoPage;
