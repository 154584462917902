import { AnyAction } from "redux";
import { typeCreator } from "../utility/types";

export const FETCH_ORGANISATIONS = typeCreator("ORGANISATION_LISTING", "FETCH_ORGANISATIONS");

export const UPDATE_ORG_LISTING_FILTER = "ORGANISATION_LISTING/UPDATE_ORG_LISTING_FILTER";
export const RESET_ORG_LISTING_FILTER = "ORGANISATION_LISTING/RESET_ORG_LISTING_FILTER";

export const UPDATE_PAGE_NUMBER = "ORGANISATION_LISTING/UPDATE_PAGE_NUMBER";

export interface Country {
  iso3: string;
  iso2: string;
  name: string;
}
export interface Address {
  line1?: string;
  line2?: string;
  line3?: string;
  city?: string;
  county?: string;
  state?: string;
  postalCode?: string;
  country: Country;
}

export interface Role {
  orgRole: string;
  requestDate?: string;
  orgRoleid: string;
}

export interface Organisation {
  name: string;
  officeType: string;
  address: Address;
}

export interface OrganisationEntity {
  organisationId: string;
  organisation: Organisation;
  roles?: Array<Role>;
}

export interface OrganisationListingState extends Organisations {
  selectedFilters?: Array<string>;
  pageNumber: number;
  itemsPerPage: number;
}

export interface Organisations {
  organisations?: Array<OrganisationEntity>;
  requested?: Array<OrganisationEntity>;
}

export interface FetchOrganisationsAction extends AnyAction {
  type: typeof FETCH_ORGANISATIONS.START;
}

export interface FetchOrganisationsSuccessAction extends AnyAction {
  type: typeof FETCH_ORGANISATIONS.SUCCESS;
  payload: Organisations;
}

export interface UpdateOrgListingFilterAction extends AnyAction {
  type: typeof UPDATE_ORG_LISTING_FILTER;
  option: string;
}

export interface ResetOrgListingFilterAction extends AnyAction {
  type: typeof RESET_ORG_LISTING_FILTER;
}

export interface UpdatePageNumberAction extends AnyAction {
  type: typeof UPDATE_PAGE_NUMBER;
  pageNumber: number;
}

export type OrganisationActionTypes =
  | FetchOrganisationsAction
  | FetchOrganisationsSuccessAction
  | UpdateOrgListingFilterAction
  | ResetOrgListingFilterAction
  | UpdatePageNumberAction;
