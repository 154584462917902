import { AnyAction } from "redux";
import { typeCreator } from "../utility/types";

export const FETCH_USER_ROLES = "LOOKUPS/FETCH_USER_ROLES";
export const FETCH_USER_ROLES_SUCCESS = "LOOKUPS/FETCH_USER_ROLES_SUCCESS";
export const FETCH_USER_ROLES_ERROR = "LOOKUPS/FETCH_USER_ROLES_ERROR";
export const FETCH_QUOTE_DECLINE_REASONS = typeCreator("LOOKUPS", "FETCH_QUOTE_DECLINE_REASONS");
export const SUBMIT_QUOTE_DECLINE_REASONS = typeCreator("LOOKUPS", "SUBMIT_QUOTE_DECLINE_REASONS");
export const SUBMIT_QUOTE_ACCEPTED = typeCreator("LOOKUPS", "SUBMIT_QUOTE_ACCEPTED");
export const SET_DECLINE_MESSAGE = "LOOKUPS/SET_DECLINE_MESSAGE";
export const SET_DECLINE_REASONS = "LOOKUPS/SET_DECLINE_REASONS";
export const SET_QUOTE_NUMBER = "LOOKUPS/SET_QUOTE_NUMBER";
export const SET_QUOTE_ID = "LOOKUPS/SET_QUOTE_ID";
export const RESET_DECLINE_MESSAGE = "LOOKUPS/RESET_DECLINE_MESSAGE";
export const RESET_DECLINE_REASONS = "LOOKUPS/RESET_DECLINE_REASONS";
export const SET_DECLINE_ERROR = "LOOKUPS/SET_DECLINE_ERROR";
export const RESET_DECLINE_ERROR = "LOOKUPS/RESET_DECLINE_ERROR";

export interface UserRole {
  id: string;
  value: string;
  userDefault: boolean;
  adminDefault: boolean;
}

export interface ReasonsList {
  id: number;
  value: string;
}

export type UserRoles = Array<UserRole>;
export type quoteDeclineReasons = Array<ReasonsList>;

export interface submitDeclineReasons {
  id?: string;
  status?: string;
  message?: string;
  declineReason?: number[];
}

export interface submitQuoteAccepted {
  id?: string;
  status?: string;
}

export interface LookupState {
  userRoles?: UserRoles;
  quoteDeclineReasons?: quoteDeclineReasons;
  submitDeclineReasons?: submitDeclineReasons;
  declineMessage?: string;
  declineReasonsList?: number[];
  quoteNumber?: string;
  quoteId?: string;
  submitQuoteAccepted?: submitQuoteAccepted;
  declineError?: string[];
}

export interface fetchUserRolesAction extends AnyAction {
  type: typeof FETCH_USER_ROLES;
}

export interface fetchUserRolesSuccessAction extends AnyAction {
  type: typeof FETCH_USER_ROLES_SUCCESS;
  payload: {
    userRoles: UserRoles;
  };
}
export interface fetchQuoteDeclinedReasonsAction extends AnyAction {
  type: typeof FETCH_QUOTE_DECLINE_REASONS.START;
}

export interface fetchQuoteDeclinedReasonsSuccessAction extends AnyAction {
  type: typeof FETCH_QUOTE_DECLINE_REASONS.SUCCESS;
  payload: {
    quoteDeclineReasons: quoteDeclineReasons;
  };
}
export interface SubmitDeclineReasonsAction extends AnyAction {
  type: typeof SUBMIT_QUOTE_DECLINE_REASONS.START;
  resolve?: (response: unknown) => void;
}

export interface SubmitDeclineReasonsSuccessAction extends AnyAction {
  type: typeof SUBMIT_QUOTE_DECLINE_REASONS.SUCCESS;
  payload: {
    id?: string;
    status?: string;
    message?: string;
    declineReason?: number[];
  };
}

export interface SubmitDeclineReasonsErrorAction extends AnyAction {
  type: typeof SUBMIT_QUOTE_DECLINE_REASONS.ERROR;
}

export interface SetDeclineMessageAction extends AnyAction {
  type: typeof SET_DECLINE_MESSAGE;
  value: string;
}

export interface SetDeclineReasonsAction extends AnyAction {
  type: typeof SET_DECLINE_REASONS;
  value: number[];
}

export interface SetQuoteNumberAction extends AnyAction {
  type: typeof SET_QUOTE_NUMBER;
  value: string;
}

export interface SetQuoteIdAction extends AnyAction {
  type: typeof SET_QUOTE_ID;
  value: string;
}

export interface SubmitQuoteAcceptedAction extends AnyAction {
  type: typeof SUBMIT_QUOTE_ACCEPTED.START;
  resolve?: (response: unknown) => void;
}

export interface SubmitQuoteAcceptedSuccessAction extends AnyAction {
  type: typeof SUBMIT_QUOTE_ACCEPTED.SUCCESS;
  payload: {
    id?: string;
    status?: string;
  };
}

export interface SubmitQuoteAcceptedErrorAction extends AnyAction {
  type: typeof SUBMIT_QUOTE_ACCEPTED.ERROR;
}

export interface ResetDeclineMessageAction extends AnyAction {
  type: typeof RESET_DECLINE_MESSAGE;
}

export interface ResetDeclineReasonsAction extends AnyAction {
  type: typeof RESET_DECLINE_REASONS;
}
export interface SetDeclineErrorAction extends AnyAction {
  type: typeof SET_DECLINE_ERROR;
  value: string[];
}
export interface ResetDeclineErrorAction extends AnyAction {
  type: typeof RESET_DECLINE_ERROR;
}
export type LookupActionTypes =
  | fetchUserRolesAction
  | fetchUserRolesSuccessAction
  | fetchQuoteDeclinedReasonsAction
  | fetchQuoteDeclinedReasonsSuccessAction
  | SubmitDeclineReasonsAction
  | SubmitDeclineReasonsSuccessAction
  | SubmitDeclineReasonsErrorAction
  | SetDeclineReasonsAction
  | SetDeclineMessageAction
  | SetQuoteNumberAction
  | SetQuoteIdAction
  | SubmitQuoteAcceptedAction
  | SubmitQuoteAcceptedSuccessAction
  | SubmitQuoteAcceptedErrorAction
  | ResetDeclineMessageAction
  | ResetDeclineReasonsAction
  | SetDeclineErrorAction
  | ResetDeclineErrorAction;
