/**
 * @name sentenceCase
 * @description converts text string to sentence case.
 * @param str - string to be converted
 */

const sentenceCase = (str: string) => {
  return str
    .replace(/[a-z]/i, (letter: string) => {
      return letter.toUpperCase();
    })
    .trim();
};

export default sentenceCase;
