export const typeCreator = (base: string, action: string) => {
  const START = `${base}/${action}`;
  const SUCCESS = `${base}/${action}_SUCCESS`;
  const ERROR = `${base}/${action}_ERROR`;
  return {
    START,
    SUCCESS,
    ERROR,
    success: (payload: unknown, meta: unknown) => ({
      type: SUCCESS,
      payload,
      meta,
    }),
    error: (errorReason: unknown) => ({
      type: ERROR,
      error: true,
      payload: errorReason,
    }),
  };
};
