import apiConfig from "../services/api/apiConfig";
import { useLookups } from "./useLookups";

export interface ICountriesLookupEntry {
  iso2: string;
  iso3: string;
  name: string;
  validationLevel: string;
  asciiLanguageCode: string;
  phonePrefix: string;
}

export const useCountries = () =>
  useLookups<ICountriesLookupEntry[]>(apiConfig.endpoints.lookups.countries, "countries");

/**
 * @returns ordered list of phone prefixes e.g. ["+1", "+7", ..., "+44"]
 */
export const asPhonePrefixList = (countries: ICountriesLookupEntry[]) => {
  return countries
    .map(({ phonePrefix }) => phonePrefix)
    .filter((item, index, array) => item && array.indexOf(item) === index)
    .sort((a, b) => (parseInt(a, 10) < parseInt(b, 10) ? -1 : 1));
};
