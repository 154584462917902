import { AnyAction } from "redux";
import { Organisation } from "../organisationListing/types";
import { typeCreator } from "../utility/types";

export const FETCH_ORG_OVERVIEW_REQUEST = typeCreator("ORG_OVERVIEW", "FETCH_ORG_OVERVIEW_REQUEST");
export const UPDATE_ORG_SORTBY = "ORG_OVERVIEW/UPDATE_ORG_SORTBY";
export interface UserRequestData {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  createdDate: string;
  contactId: string;
  userRoleId: string;
  userRole: string;
  requestId: string;
}
export interface User {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  createdDate: string;
  contactId: string;
  userRoleId: string;
  userRole: string;
}

export interface Invitation extends User {
  requestId?: string;
  status?: string;
}
export interface OrganisationOverview {
  userRequests?: Array<UserRequestData>;
  invitations?: Array<Invitation>;
  users?: Array<User>;
  organisation?: Organisation;
}

export type OrganisationOverviewEntity = Record<string, OrganisationOverview>;

export interface OrganisationOverviewState {
  sortBy: string;
  results?: Record<string, OrganisationOverviewEntity>;
}

export interface FetchOrganisationOverviewAction extends AnyAction {
  type: typeof FETCH_ORG_OVERVIEW_REQUEST.START;
  orgId: string;
  orgRoleId: string;
}

export interface FetchOrganisationOverviewSuccessAction extends AnyAction {
  type: typeof FETCH_ORG_OVERVIEW_REQUEST.SUCCESS;
  payload: OrganisationOverview;
  meta: {
    orgId: string;
    orgRoleId: string;
  };
}
export interface UpdateOrgSortByAction extends AnyAction {
  type: typeof UPDATE_ORG_SORTBY;
  sortBy: string;
}

export type OrganisationOverviewActionTypes =
  | FetchOrganisationOverviewAction
  | FetchOrganisationOverviewSuccessAction
  | UpdateOrgSortByAction;
