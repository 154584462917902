export default {
  exemptionsText: "exam exemptions",
  registrationsText: "Initial registrations",
  subscriptionsText: "years subscriptions",
  includes: "Includes",
  total: "Total ",
  savingsStatement: "Save {savings} per person",
  completed: "<strong>Completed</strong> on",
  quote: {
    accepted: {
      title: "Quote accepted. Awaiting next steps from ACCA",
      description: "<strong>Completed</strong> on ",
    },
    declined: {
      title: "Quote reviewed - awaiting next steps from ACCA",
      description: "<strong>Completed</strong> on ",
    },
    active: {
      title: "Your {quoteName} is ready",
      info: {
        title: "Agreement details for ",
        duration: "Quote runs from: {fromDate} untill: {toDate}",
        description: "bla",
        price: {
          standard: "STANDARD PRICE {currency}",
          offer: "OFFER PRICE {currency}",
        },
      },
      buttons: {
        queryQuote: "Talk to us about this quote",
        accept: "Accept quote",
      },
    },
  },
  agreement: {
    talkToUs: "Talk to us about this agreement",
    active: {
      title: "Your {agreementName} is ready to sign and activate",
      description: "We use DocuSign to review, sign and activate agreements",
      buttons: {
        queryAgreement: "Discuss agreement",
        activate: "Sign agreement",
      },
    },
    acceptedQuote: {
      title: "Accepted quote.",
      description: "<strong>Completed</strong> on ",
    },
    preSign: {
      title: "Digital signature required to activate agreement",
      description: "Following the acceptance of the quote",
    },
    preAgree: {
      title: "Activated agreement",
      description: "Following the approval and signing of the agreement",
    },
    agreed: {
      title: "Agreement activated",
      description: "<strong>Completed</strong> on ",
    },
    signed: {
      title: "Agreement signed",
      description: "<strong>Completed</strong> on ",
    },
    signedAndActivated: {
      title: "Agreement signed, awaiting activation date",
      description: "<strong>Completed</strong> on ",
    },
    signaturePending: {
      title: "Agreement waiting to be signed",
      description: "DocuSign email sent containing link to sign the agreement document.",
    },
    inactive: {
      title: "Agreement inactive",
      description: "<strong>Completed</strong> on ",
    },
    expired: {
      title: "Agreement expired",
      description: "<strong>Expired</strong> on",
    },
    declined: {
      title: "Agreement declined",
      description: "<strong>Declined</strong> on ",
    },
    cancelled: {
      title: "Agreement cancelled",
      description: "<strong>Cancelled</strong> on ",
    },
    withAcca: {
      title: "Agreement reviewed - awaiting next steps from ACCA",
      description: "<strong>Completed</strong> on ",
    },
  },
  successHeading: "Thank you for accepting the quote",
  successDescLineOne: "Our team will review the details and prepare an agreement.",
  successDescLineTwo: "We'll let you know when it's available on your account.",
};
