import { spawn } from "redux-saga/effects";

import userProfileSagas from "./userProfile/sagas";
import contentSagas from "./content/sagas";
import organisationListingSagas from "./organisationListing/sagas";
import lookupSagas from "./lookups/sagas";
import newUserSagas from "./newUserDetails/sagas";
import editUserSagas from "./editUserDetails/sagas";
import organisationOverviewSagas from "./organisationOverview/sagas";
import reviewUserSagas from "./reviewUser/sagas";
import pendingUserSagas from "./pendingUser/sagas";
import agreementsSagas from "./agreementsAndQuotes/sagas";

export default function* rootSaga() {
  yield spawn(userProfileSagas);
  yield spawn(organisationListingSagas);
  yield spawn(lookupSagas);
  yield spawn(newUserSagas);
  yield spawn(editUserSagas);
  yield spawn(contentSagas);
  yield spawn(organisationOverviewSagas);
  yield spawn(reviewUserSagas);
  yield spawn(pendingUserSagas);
  yield spawn(agreementsSagas);
}
