import {
  AgreementsActionTypes,
  AgreementsState,
  FETCH_AGREEMENTS,
  FETCH_AGREEMENT_INFO,
  SET_TIMELINE_IS_SUBMITTING,
  SUBMIT_AGREEMENT_ACCEPTED,
  SUBMIT_AGREEMENT_REJECT_REASONS,
  SET_AGREEMENT_ID,
  SET_AGREEMENT_ERROR,
  SET_REJECT_MESSAGE,
  RESET_AGREEMENT_ERROR,
  RESET_REJECT_MESSAGE,
  FETCH_AGREEMENT_SIGNATORIES,
  SET_TOTAL_SIGNATORIES,
  RESET_TOTAL_SIGNATORIES,
  SET_SELECTED_SIGNATORIES_LENGTH,
  RESET_SELECTED_SIGNATORIES_LENGTH,
} from "./types";

export const initialState: AgreementsState = {
  timelineSubmitting: false,
  rejectMessage: "",
  agreementId: "",
  agreementError: [],
  agreementSignatories: [],
  totalSignatories: [],
  agreementInfo: null,
  selectedSignatories: 0,
};

export default function agreementsReducer(
  state = initialState,
  action: AgreementsActionTypes
): AgreementsState {
  switch (action.type) {
    case FETCH_AGREEMENTS.SUCCESS:
      return {
        ...state,
        agreements: action.payload.agreements,
        quotes: action.payload.quotes,
      };
    case FETCH_AGREEMENT_INFO.SUCCESS:
      return {
        ...state,
        agreementInfo: action.payload,
      };
    case SET_TIMELINE_IS_SUBMITTING:
      return {
        ...state,
        timelineSubmitting: action.value,
      };
    case SUBMIT_AGREEMENT_REJECT_REASONS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_REJECT_MESSAGE:
      return {
        ...state,
        rejectMessage: action.value,
      };

    case SET_AGREEMENT_ID:
      return {
        ...state,
        agreementId: action.value,
      };
    case SUBMIT_AGREEMENT_ACCEPTED.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_REJECT_MESSAGE:
      return {
        ...state,
        rejectMessage: "",
      };

    case SET_AGREEMENT_ERROR:
      return {
        ...state,
        agreementError: action.value,
      };
    case RESET_AGREEMENT_ERROR:
      return {
        ...state,
        agreementError: [],
      };
    case FETCH_AGREEMENT_SIGNATORIES.SUCCESS:
      return {
        ...state,
        agreementSignatories: action.payload,
      };
    case SET_TOTAL_SIGNATORIES:
      return {
        ...state,
        totalSignatories: action.value,
      };
    case RESET_TOTAL_SIGNATORIES:
      return {
        ...state,
        totalSignatories: [],
      };
    case SET_SELECTED_SIGNATORIES_LENGTH:
      return {
        ...state,
        selectedSignatories: action.value,
      };
    case RESET_SELECTED_SIGNATORIES_LENGTH:
      return {
        ...state,
        selectedSignatories: 0,
      };
    default:
      return state;
  }
}
