import { RootState } from "../rootReducer";

export const getUserOrganisations = (state: RootState) => {
  const { organisations, requested = [] } = state.organisationListing;

  if (!organisations) {
    return null;
  }

  const orgs = requested.length > 0 ? requested.concat(organisations) : [...organisations];
  const cleanedOrgs = orgs.filter(
    (a, idx, b) => b.findIndex((entry) => entry.organisationId === a.organisationId) === idx
  );
  const sortedAlphabetically = cleanedOrgs.sort((a, b) => {
    const textA = a.organisation.name.toUpperCase();
    const textB = b.organisation.name.toUpperCase();
    return textA.localeCompare(textB);
  });

  return sortedAlphabetically;
};

export const getFilterOptions = (state: RootState) => {
  const userOrganisations = getUserOrganisations(state);
  const filterOptions = [];

  if (!userOrganisations) {
    return filterOptions;
  }

  const officeTypeMissing = userOrganisations.some(({ organisation }) => !!organisation.officeType);

  userOrganisations.forEach(({ organisation }) => {
    const { officeType } = organisation;

    if (!!officeType && !filterOptions.includes(officeType)) {
      filterOptions.push(officeType);
    }
  });

  if (officeTypeMissing) {
    filterOptions.push("no office type specified");
  }

  return filterOptions;
};

export const getSelectedFilters = (state: RootState) => state.organisationListing.selectedFilters;

export const getCurrentPageNumber = (state: RootState) => state.organisationListing.pageNumber;

export const getItemsPerPage = (state: RootState) => state.organisationListing.itemsPerPage;

export const getShowFilter = (state: RootState) => {
  const userOrganisations = getUserOrganisations(state);
  const itemsPerPage = getItemsPerPage(state);

  if (!userOrganisations) {
    return false;
  }

  return userOrganisations.length > itemsPerPage;
};

export const getFilteredOrgListing = (state: RootState) => {
  const selectedFilters = getSelectedFilters(state);
  const userOrganisations = getUserOrganisations(state);
  const pageNumber = getCurrentPageNumber(state);
  const itemsPerPage = getItemsPerPage(state);

  const orgListingGroupedByPage = [];

  if (!userOrganisations) {
    return null;
  }

  const filteredOrgs = userOrganisations.filter(({ organisation }) => {
    if (selectedFilters.length === 0) {
      return userOrganisations;
    }
    return (
      selectedFilters.includes(organisation.officeType) ||
      (!organisation.officeType && selectedFilters.includes("no office type specified"))
    );
  });

  for (let i = 0; i < filteredOrgs.length; i += itemsPerPage) {
    orgListingGroupedByPage.push(filteredOrgs.slice(i, i + itemsPerPage));
  }

  return {
    totalPages: orgListingGroupedByPage.length,
    result: orgListingGroupedByPage.slice(0, pageNumber),
  };
};

export const getShouldShowLoadMoreButton = (state: RootState) => {
  const filteredOrgListing = getFilteredOrgListing(state);
  const currentPageNumber = getCurrentPageNumber(state);

  if (!filteredOrgListing) {
    return false;
  }
  return filteredOrgListing.totalPages > 1 && currentPageNumber < filteredOrgListing.totalPages;
};
