import React, { FormEvent, useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import { IconTelephone32, BodyCopy } from "acca-design-system";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { FormSection, PhoneField, SaveBar, useCountries } from "@acca-portals/shared";
import { IContactDetailsPageContent } from "./types";
import { getContactId } from "../../state/userProfile/selectors";
import { useUpdateContactDetails } from "../../hooks/useContactDetails";
import staticContent from "./ContactDetailsPage.content";

export interface IContactDetailsFormProps {
  content: IContactDetailsPageContent;
  phoneNumber?: string;
}

interface IFormState {
  phoneNumber?: string;
  countryCode?: string;
}

const ContactDetailsForm = ({ content, phoneNumber }: IContactDetailsFormProps) => {
  const { phonedescription, phonetitle } = content;
  const countries = useCountries().data;

  const contactId = useSelector(getContactId);
  const updateContactDetails = useUpdateContactDetails(contactId);

  // State
  const [isEditMode, setIsEditMode] = useState(false);
  const [formState, setFormState] = useState<IFormState>({});
  const [initialFormState, setInitialFormState] = useState<IFormState>({});
  const [isDirty, setIsDirty] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>();
  const hasErrors = errors && Object.values(errors)?.some((e) => e);

  const reset = () => {
    setErrors({});
    setIsDirty(false);
    const fs: IFormState = {
      phoneNumber: phoneNumber?.substr(phoneNumber.indexOf(" ") + 1),
      countryCode: phoneNumber?.substr(0, phoneNumber.indexOf(" ")),
    };
    setInitialFormState(fs);
    setFormState(fs);
  };

  // if editMode is toggled, reset the form
  useEffect(reset, [phoneNumber, isEditMode]);

  // isDirty if the form differs from the initial
  useEffect(() => {
    setIsDirty(!isEqual(initialFormState, formState));
  }, [formState, initialFormState]);

  // Validate
  useEffect(() => {
    // phone number can be blank or min 5.
    if (formState.phoneNumber && formState.phoneNumber.replace(" ", "").length < 5) {
      return setErrors({
        phone: staticContent.phoneNumberMinLengthError,
      });
    }

    // required a countrycode if phone number is set
    if (formState.phoneNumber && !formState.countryCode) {
      return setErrors({
        phone: staticContent.countryCodeRequiredError,
      });
    }

    return setErrors({});
  }, [formState]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (hasErrors) {
      return;
    }

    const body = {
      phoneNumber: formState.phoneNumber ? `${formState.countryCode} ${formState.phoneNumber}` : "",
    };

    updateContactDetails.mutate(body, {
      onSuccess: () => {
        setIsEditMode(false);
      },
      onError: (error) => {
        if (error.response.status === 400) {
          setErrors({
            phone: staticContent.phoneNumberInvalidAPIError,
          });
        }
      },
    });
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <FormSection
        icon={IconTelephone32}
        isEditMode={isEditMode}
        onEditToggleClick={() => setIsEditMode(!isEditMode)}
      >
        <h3 className="section-title">{phonetitle?.text}</h3>
        <BodyCopy>{HTMLReactParser(phonedescription?.value)}</BodyCopy>
        {!isEditMode && (
          <dl className="u-margin-top-2">
            <dt>{staticContent.phoneNumberLabel}</dt>
            <dd>{phoneNumber || "-"}</dd>
          </dl>
        )}
        {isEditMode && (
          <PhoneField
            countries={countries}
            label={staticContent.phoneNumberLabel}
            countryCode={formState.countryCode}
            phoneNumber={formState.phoneNumber}
            error={errors.phone}
            onCountryCodeChange={(value) => setFormState({ ...formState, countryCode: value })}
            onPhoneNumberChange={(value) => setFormState({ ...formState, phoneNumber: value })}
          />
        )}
      </FormSection>
      {isEditMode && (
        <SaveBar
          disabled={!isDirty || hasErrors}
          isSubmitting={updateContactDetails.isLoading}
          errorMessage={
            updateContactDetails.error?.response?.status !== 400 &&
            staticContent.genericErrorMessage
          }
          showErrorPrompt={updateContactDetails.isError}
          isDirty={isDirty}
        />
      )}
    </form>
  );
};

export default ContactDetailsForm;
