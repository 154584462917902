import { parseISO, format, isWithinInterval } from "date-fns";

export const formatDate = (date: string, dateFormat: string): string => {
  if (!date) {
    return null;
  }
  return format(parseISO(date), dateFormat);
};

export const isMorning = (date: Date): boolean | null => {
  const lower = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 5);
  const upper = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 59, 59, 999);
  return isWithinInterval(date, { start: lower, end: upper });
};

export const isAfternoon = (date: Date): boolean | null => {
  const lower = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12);
  const upper = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 17, 59, 59, 999);
  return isWithinInterval(date, { start: lower, end: upper });
};
