import {
  FETCH_REVIEW_USER_REQUEST,
  SET_REVIEW_USER_ROLEID,
  SET_REVIEW_USER_ROLENAME,
  ACCEPT_REVIEW_USER_REQUEST,
  SET_REVIEW_USER_DECLINE_MSG,
  DECLINE_REVIEW_USER_REQUEST,
  SET_REVIEW_USER_IS_ACCEPTED,
  SET_REVIEW_USER_IS_DECLINED,
} from "./types";
import actionCreator from "../utility/actionCreator";

const args = ["orgId", "orgRoleId", "requestId"];

export const fetchReviewUser = actionCreator(FETCH_REVIEW_USER_REQUEST.START, ...args);
export const setReviewRoleName = actionCreator(SET_REVIEW_USER_ROLENAME, "value");
export const setReviewRoleId = actionCreator(SET_REVIEW_USER_ROLEID, "value");
export const setReviewDeclineMsg = actionCreator(SET_REVIEW_USER_DECLINE_MSG, "value");
export const AcceptReviewUser = actionCreator(
  ACCEPT_REVIEW_USER_REQUEST.START,
  ...args,
  "timeout",
  "resolve"
);
export const DeclineReviewUser = actionCreator(
  DECLINE_REVIEW_USER_REQUEST.START,
  ...args,
  "resolve"
);
export const setReviewIsDeclined = actionCreator(SET_REVIEW_USER_IS_DECLINED, "value");
export const setReviewIsAccepted = actionCreator(SET_REVIEW_USER_IS_ACCEPTED, "value");
