import React from "react";
import classnames from "classnames";
import { Button, LoadingDots, Alert, BodyCopy } from "acca-design-system";
import Parser from "html-react-parser";
import "./save-bar.scss";

export interface ISaveBarProps {
  showErrorPrompt?: boolean;
  disabled?: boolean;
  hasFocus?: boolean;
  isSubmitting?: boolean;
  isDirty?: boolean;
  errorMessage?: string;
  onSave?: () => any;
}

const SaveBar = ({
  disabled,
  hasFocus,
  isSubmitting,
  errorMessage,
  showErrorPrompt,
  isDirty,
  onSave,
}: ISaveBarProps) => {
  const classes = classnames(
    "form-save-bar form-save-bar--is-sticky u-box-shadow-eiffel u-margin-top-2",
    {
      "form-save-bar--has-focus": hasFocus,
      "form-save-bar--with-error": errorMessage,
    }
  );

  const getText = () => {
    if (isSubmitting) return "Saving...";
    return isDirty ? "Unsaved changes" : "No changes made";
  };

  return (
    <div className={classes}>
      <div className="form-save-bar__inner">
        <span className="form-save-bar__message u-white u-font-heading">{getText()}</span>

        {!!errorMessage && showErrorPrompt && !isSubmitting && (
          <Alert type="error" className="form-save-bar__error">
            <BodyCopy>{Parser(errorMessage)}</BodyCopy>
          </Alert>
        )}
        {!isSubmitting ? (
          <Button onClick={onSave} disabled={disabled} type="submit" text="Save changes" />
        ) : (
          <LoadingDots className="u-padding-1" />
        )}
      </div>
    </div>
  );
};

export default SaveBar;
