import { takeLatest, put, call, select } from "redux-saga/effects";
import { ApiService, ICallApi, YieldReturn } from "@acca-portals/shared";
import apiConfig from "../../configs/apiConfig";
import {
  FETCH_USER_ROLES,
  FETCH_USER_ROLES_ERROR,
  FETCH_USER_ROLES_SUCCESS,
  FETCH_QUOTE_DECLINE_REASONS,
  SUBMIT_QUOTE_DECLINE_REASONS,
  SubmitDeclineReasonsAction,
  SubmitQuoteAcceptedAction,
  SUBMIT_QUOTE_ACCEPTED,
} from "./types";
import { getDeclinedMessage, getSelectedReasons, getQuoteNumber, getQuoteId } from "./selectors";

import { getOrgId, getOrgRoleId } from "../org/selectors";
import { useSaga } from "../utility/hooks/useSaga";

function* handleFetchUserRoles() {
  try {
    const requestArgs: ICallApi = {
      url: apiConfig.endpoints.lookups.userRoles,
      method: "GET",
    };
    const response: YieldReturn<typeof ApiService.CallApi> = yield call(
      ApiService.CallApi,
      requestArgs
    );

    return yield put({
      type: FETCH_USER_ROLES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    return yield put({
      type: FETCH_USER_ROLES_ERROR,
      error,
    });
  }
}

function* handleFetchQuoteDeclinedReasons() {
  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.lookups.quoteDeclineReasons,
    method: "GET",
  };

  yield call(
    useSaga,
    [FETCH_QUOTE_DECLINE_REASONS.success, FETCH_QUOTE_DECLINE_REASONS.error],
    requestArgs
  );
}

function* handleSubmitDeclineReason({ resolve }: SubmitDeclineReasonsAction) {
  const orgId = yield select(getOrgId);
  const orgRoleId = yield select(getOrgRoleId);
  const declineMessage = yield select(getDeclinedMessage);
  const reasons = yield select(getSelectedReasons);
  const quoteNumber = yield select(getQuoteNumber);
  const quoteId = yield select(getQuoteId);

  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.lookups.quoteAcceptDeclineSubmit(orgId, orgRoleId, quoteNumber),
    method: "PATCH",
    data: {
      id: quoteId,
      status: "Decline",
      message: declineMessage,
      declineReason: reasons.map(Number),
    },
  };

  yield call(
    useSaga,
    [SUBMIT_QUOTE_DECLINE_REASONS.success, SUBMIT_QUOTE_DECLINE_REASONS.error],
    requestArgs,
    resolve
  );
}

function* handleSubmitQuoteAccepted({ resolve }: SubmitQuoteAcceptedAction) {
  const orgId = yield select(getOrgId);
  const orgRoleId = yield select(getOrgRoleId);
  const quoteNumber = yield select(getQuoteNumber);
  const quoteId = yield select(getQuoteId);

  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.lookups.quoteAcceptDeclineSubmit(orgId, orgRoleId, quoteNumber),
    method: "PATCH",
    data: {
      id: quoteId,
      status: "Accept",
    },
  };

  yield call(
    useSaga,
    [SUBMIT_QUOTE_ACCEPTED.success, SUBMIT_QUOTE_ACCEPTED.error],
    requestArgs,
    resolve
  );
}

export default function* lookupSagas(): unknown {
  yield takeLatest(FETCH_USER_ROLES, handleFetchUserRoles);
  yield takeLatest(FETCH_QUOTE_DECLINE_REASONS.START, handleFetchQuoteDeclinedReasons);
  yield takeLatest(SUBMIT_QUOTE_DECLINE_REASONS.START, handleSubmitDeclineReason);
  yield takeLatest(SUBMIT_QUOTE_ACCEPTED.START, handleSubmitQuoteAccepted);
}
