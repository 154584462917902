import React, { FC } from "react";
import { FormField, TelephoneField, FormFieldValidation } from "acca-design-system";
import { asPhonePrefixList, ICountriesLookupEntry } from "../../hooks/useCountries";
import Label from "../Label/Label";

export interface IPhoneFieldProps {
  countries?: ICountriesLookupEntry[];
  label: string;
  phoneNumber?: string;
  countryCode?: string;
  error?: string;
  onPhoneNumberChange: (value?: string) => void;
  onCountryCodeChange: (value?: string) => void;
  onPhoneNumberBlur?: () => void;
}

const PhoneField: FC<IPhoneFieldProps> = ({
  countries,
  label,
  phoneNumber,
  countryCode,
  error,
  onPhoneNumberChange,
  onCountryCodeChange,
  onPhoneNumberBlur,
}) => {
  const countryCodeOptions = countries
    ? [
        {
          options: asPhonePrefixList(countries).map((code) => ({
            value: `+${code}`,
            label: `+${code}`,
          })),
        },
      ]
    : [];

  return (
    <FormField className="u-margin-top-2 u-content-container-small">
      <Label id="phone-input" text={label} />
      <TelephoneField
        id="phone"
        countryCode={{ value: countryCode }}
        countryCodes={countryCodeOptions}
        telephone={phoneNumber}
        onCountryCodeChange={(option) => onCountryCodeChange(option.value)}
        onTelephoneChange={(value) => onPhoneNumberChange(value)}
        onTelephoneBlur={onPhoneNumberBlur}
      />
      {error && (
        <FormFieldValidation
          list={[
            {
              fieldId: "phone",
              valid: false,
              messageLabel: "Error",
              message: error,
            },
          ]}
        />
      )}
    </FormField>
  );
};

export default PhoneField;
