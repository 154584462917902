import { INotificationsContent } from "./types";

const content: INotificationsContent = {
  cards: [
    {
      notificationCode: "QUOTE_AVAILABLE",
      topic: "Quotes",
      title: "Your Quote is now available to view and accept",
      button: {
        text: "Review request",
        href: "string",
      },
    },
    {
      notificationCode: "QUOTE_AVAILABLE_DUE",
      topic: "Quotes",
      title: "Don’t miss out on this offer - view and accept quote now",
      button: {
        text: "Review request",
        href: "string",
      },
    },
    {
      notificationCode: "QUOTE_UPDATE_AVAILABLE",
      topic: "Quotes",
      title: "Your updated quote is now available to view and accept ",
      button: {
        text: "Review request",
        href: "string",
      },
    },
    {
      notificationCode: "QUOTE_UPDATE_AVAILABLE_DUE",
      topic: "Quotes",
      title: "Don’t miss out on this offer - view and accept quote now",
      button: {
        text: "Review request",
        href: "string",
      },
    },
  ],
};

export default content;
