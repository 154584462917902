import React, { FC, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import {
  Button,
  IconArrowLeft16,
  IconArrowRight16,
  IconSearch24,
  IconOpenInANewWindow16,
} from "acca-design-system";
import "./pdf-viewer.scss";

interface documentText {
  next?: string;
  previous?: string;
  fullSize?: string;
  exportHeader?: string;
  exportText?: string;
  description?: string;
  page?: string;
}

export interface PdfViewerProps {
  content?: string;
  text?: documentText;
  showFullscreen?: boolean;
  triggerFullScreen?: () => void;
  documentWidth?: number;
  documentTitle?: string;
}

const PdfViewer: FC<PdfViewerProps> = ({
  content,
  text,
  showFullscreen,
  triggerFullScreen,
  documentWidth,
  documentTitle,
}) => {
  const documentData = `data:application/pdf;base64,${content}`;
  const onPageDocWidth = (documentWidth > 595 && 595) || documentWidth;
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(1);
  };

  return (
    <div className={`${!showFullscreen ? "document" : ""}`}>
      <Document
        file={documentData}
        onLoadSuccess={onDocumentLoadSuccess}
        aria-describedby="documentType"
      >
        <Page pageNumber={pageNumber} width={showFullscreen ? documentWidth : onPageDocWidth} />
      </Document>
      <div
        className={`u-margin-top-1 umargin-bottom-1 document__description ${
          showFullscreen ? "u-margin-left-1" : ""
        }`}
      >
        {text.description && <p id="documentType">{text.description}</p>}
        {!showFullscreen && (
          <Button
            icon={IconSearch24}
            iconPosition="left"
            variant="link"
            text={text.fullSize}
            onClick={() => triggerFullScreen()}
          />
        )}
      </div>
      {numPages > 1 && (
        <div
          className={`document__buttons ${
            showFullscreen ? "u-margin-left-1 u-margin-right-1" : ""
          }`}
        >
          <Button
            disabled={pageNumber <= 1}
            text={text.previous}
            onClick={previousPage}
            variant="outlined"
            icon={IconArrowLeft16}
            iconPosition="left"
          />
          <p className="u-margin-top-2 u-margin-bottom-2 document__page-numbers">
            {`${text.page} ${pageNumber || (numPages ? 1 : "--")} of ${numPages || "--"} `}
          </p>
          <Button
            disabled={pageNumber >= numPages}
            text={text.next}
            onClick={nextPage}
            variant="outlined"
            icon={IconArrowRight16}
            iconPosition="right"
          />
        </div>
      )}
      {!showFullscreen && (
        <div className="u-margin-top-4 document__export">
          <h3 className="u-margin-top-1 u-margin-bottom-1">{text.exportHeader}</h3>
          <a
            className="button button--link button--icon button--icon-right"
            href={documentData}
            download={`${documentTitle}.pdf`}
          >
            <span className="button__text">Export</span>
            <IconOpenInANewWindow16 className="button__icon" />
          </a>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
