import {
  EditUserActionTypes,
  EditUserDetailsState,
  FETCH_USER,
  RESET_USER_DETAILS,
  SUBMIT_EDIT_USER,
  SET_ROLE,
  SET_ROLE_ID,
  DELETE_USER,
} from "./types";

export const initialState: EditUserDetailsState = {
  email: "",
  nationality: "",
  officeAddress: "",
  city: "",
  state: "",
  county: "",
  postalCode: "",
  firstName: "",
  lastName: "",
  name: "",
  createdDate: "",
  jobTitle: "",
  userRoleId: "",
  userRole: "",
  updatedUserRoleId: "",
  updatedUserRole: "",
};

export default function editUserDetailsReducer(
  state = initialState,
  action: EditUserActionTypes
): EditUserDetailsState {
  switch (action.type) {
    case SUBMIT_EDIT_USER.SUCCESS:
      return initialState;
    case FETCH_USER.SUCCESS:
      return {
        ...state,
        email: action.payload.user.email,
        nationality: action.payload.user.nationality,
        officeAddress: action.payload.user.officeAddress,
        city: action.payload.user.city,
        state: action.payload.user.state,
        county: action.payload.user.county,
        postalCode: action.payload.user.postalCode,
        firstName: action.payload.user.firstName,
        lastName: action.payload.user.lastName,
        name: action.payload.user.name,
        createdDate: action.payload.user.createdDate,
        jobTitle: action.payload.user.jobTitle,
        userRoleId: action.payload.user.userRoleId,
        userRole: action.payload.user.userRole,
      };
    case SET_ROLE:
      return {
        ...state,
        updatedUserRole: action.value,
      };
    case SET_ROLE_ID:
      return {
        ...state,
        updatedUserRoleId: action.value,
      };
    case RESET_USER_DETAILS:
      return initialState;
    case DELETE_USER.SUCCESS:
      return initialState;
    default:
      return state;
  }
}
