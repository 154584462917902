/**
 * @name replaceTemplates
 * @description Recursively replaces matches for pattern(s) in string with replacement.
 * @param string - string which contains fragments to be replaced
 * @param pairs - key/value where key matches fragment to be replaced
 */
const replaceTemplates = (string: string, pairs: Record<string, string>): string => {
  let res = string;
  Object.keys(pairs).forEach((key) => {
    res = res.replace(new RegExp(`{${key}}`, "g"), pairs[key]);
  });
  return res;
};

export default replaceTemplates;
