import React, { FC } from "react";
import { PageHeader } from "acca-design-system";
import { useSelector } from "react-redux";
import { Section, MarketingConsentCard, useContent } from "@acca-portals/shared";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";
import { getContactId } from "../../state/userProfile/selectors";
import { useContactDetails, useUpdateContactDetails } from "../../hooks/useContactDetails";
import apiConfig from "../../configs/apiConfig";

interface IContent {
  pageTitle: {
    text: string;
  };
  header: {
    text: string;
  };
  description: {
    text: string;
  };
  subheading: {
    text: string;
  };
  marketingTitle: {
    text: string;
  };
  marketingText: {
    value: string;
  };
}

const MarketingConsentPage: FC = () => {
  const contactId = useSelector(getContactId);
  const contactDetails = useContactDetails(contactId);
  const updateContactDetails = useUpdateContactDetails(contactId);
  const content = useContent<IContent>(apiConfig.endpoints.templates.marketingConsentPage);

  const pageError = contactDetails.error || content.error;
  const isPageReady = contactDetails.isSuccess && content.isSuccess;

  const handleUpdate = (body) => updateContactDetails.mutateAsync(body);

  const renderMain = () => {
    if (pageError || !isPageReady) {
      return null;
    }

    const { pageTitle, header, subheading, description, marketingText, marketingTitle } =
      content.data;
    const { b2bMarketing } = contactDetails.data.consents;
    return (
      <>
        <PageHeader className="u-margin-bottom-2" pageTitle={pageTitle.text} quote={header.text} />
        <Section>
          <h3 className="section-title">{subheading.text}</h3>
          <p>{description.text}</p>

          <MarketingConsentCard
            onUpdate={handleUpdate}
            consent={b2bMarketing}
            type="b2bMarketing"
            content={{
              title: marketingTitle?.text,
              description: marketingText.value,
            }}
            className="u-margin-top-2"
          />
        </Section>
      </>
    );
  };

  return (
    <DefaultPageLayout loading={!isPageReady} error={pageError}>
      {renderMain()}
    </DefaultPageLayout>
  );
};

export default MarketingConsentPage;
