const ROUTES = {
  landingPage: "/",
  organisationsListing: "/admin/organisations",
  addUser: "/admin/add-user",
  patchUser: "/admin/edit-user",
  reviewUser: "/admin/manage-user",
  pendingUser: "/admin/pending-user",
  agreements: "/admin/agreements",
  agreementInfoPage: "/admin/agreement-info",
  myDetailsPage: "/admin/my-details",
  accountDetails: "/admin/my-details/account-details",
  contactDetailsPage: "/admin/my-details/contact-details",
  marketingConsentPage: "/admin/my-details/marketing-consent",
  loginDetailsPage: "/admin/my-details/login-details",
  deleteAccount: "/admin/deleteAccount",
};

export default ROUTES;
