const DATES = {
  PLACEHOLDER: "DD/MM/YYYY",
  FORMAT_DAY_MONTH: "d MMMM",
  FORMAT_DAY_MONTH_YEAR: "d MMMM yyyy",
  FORMAT_DATE_FIELD: "dd/MM/yyyy",
  FORMAT_YEAR_MONTH_DAY: "yyyy-MM-dd",
  FORMAT_DAY_ABBREVIATEDMONTH_YEAR: "d LLL yyy",
};

export default DATES;
