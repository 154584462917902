import { takeLatest, call, select } from "redux-saga/effects";
import { ICallApi } from "@acca-portals/shared";
import apiConfig from "../../configs/apiConfig";
import {
  FETCH_AGREEMENTS,
  FetchAgreementsAction,
  FETCH_AGREEMENT_INFO,
  FetchAgreementInfoAction,
  SubmitRejectReasonsAction,
  SUBMIT_AGREEMENT_REJECT_REASONS,
  SubmitAgreementAcceptedAction,
  SUBMIT_AGREEMENT_ACCEPTED,
  FetchAgreementSignatoriesAction,
  FETCH_AGREEMENT_SIGNATORIES,
} from "./types";
import { getAgreementRejectedMessage, getTotalSignatories } from "./selectors";

import { useSaga } from "../utility/hooks/useSaga";

function* handlefetchAgreements({ orgId, orgRoleId, resolve }: FetchAgreementsAction) {
  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.agreements.landingPage(orgId, orgRoleId),
    method: "GET",
  };

  yield call(useSaga, [FETCH_AGREEMENTS.success, FETCH_AGREEMENTS.error], requestArgs, resolve);
}
function* handlefetchAgreementInfo({
  orgId,
  orgRoleId,
  agreementId,
  resolve,
}: FetchAgreementInfoAction) {
  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.agreements.agreementInfo(orgId, orgRoleId, agreementId),
    method: "GET",
  };

  yield call(
    useSaga,
    [FETCH_AGREEMENT_INFO.success, FETCH_AGREEMENT_INFO.error],
    requestArgs,
    resolve
  );
}
function* handleSubmitRejectReason({
  orgId,
  orgRoleId,
  agreementId,
  resolve,
}: SubmitRejectReasonsAction) {
  const rejectMessage = yield select(getAgreementRejectedMessage);

  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.agreements.agreementAcceptRejectSubmit(orgId, orgRoleId, agreementId),
    method: "PATCH",
    data: {
      status: "ON_HOLD",
      comment: rejectMessage,
    },
  };

  yield call(
    useSaga,
    [SUBMIT_AGREEMENT_REJECT_REASONS.success, SUBMIT_AGREEMENT_REJECT_REASONS.error],
    requestArgs,
    resolve
  );
}
function* handleSubmitAccepted({
  orgId,
  orgRoleId,
  agreementId,
  resolve,
}: SubmitAgreementAcceptedAction) {
  const totalSignatories = yield select(getTotalSignatories);

  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.agreements.agreementAcceptRejectSubmit(orgId, orgRoleId, agreementId),
    method: "PATCH",
    data: {
      status: "SIGNATURE_PENDING",
      signatories: totalSignatories,
    },
  };

  yield call(
    useSaga,
    [SUBMIT_AGREEMENT_ACCEPTED.success, SUBMIT_AGREEMENT_ACCEPTED.error],
    requestArgs,
    resolve
  );
}
function* handlefetchAgreementSignatories({ orgId, resolve }: FetchAgreementSignatoriesAction) {
  const requestArgs: ICallApi = {
    url: apiConfig.endpoints.agreements.agreementSignatories(orgId),
    method: "GET",
  };

  yield call(
    useSaga,
    [FETCH_AGREEMENT_SIGNATORIES.success, FETCH_AGREEMENT_SIGNATORIES.error],
    requestArgs,
    resolve
  );
}
export default function* agreementsSagas(): unknown {
  yield takeLatest(FETCH_AGREEMENTS.START, handlefetchAgreements);
  yield takeLatest(FETCH_AGREEMENT_INFO.START, handlefetchAgreementInfo);
  yield takeLatest(SUBMIT_AGREEMENT_REJECT_REASONS.START, handleSubmitRejectReason);
  yield takeLatest(SUBMIT_AGREEMENT_ACCEPTED.START, handleSubmitAccepted);
  yield takeLatest(FETCH_AGREEMENT_SIGNATORIES.START, handlefetchAgreementSignatories);
}
