import {
  ContentState,
  ContentActionTypes,
  FETCH_LANDING_PAGE_CONTENT_SUCCESS,
  FETCH_ORG_LISTING_CONTENT_SUCCESS,
  FETCH_ADD_USER_CONTENT_SUCCESS,
  FETCH_EDIT_USER_CONTENT_SUCCESS,
  FETCH_PENDING_USER_CONTENT_SUCCESS,
  FETCH_REVIEW_USER_CONTENT_SUCCESS,
  FETCH_CATEGORIES_CONTENT_SUCCESS,
  FETCH_PRODUCTS_CONTENT_SUCCESS,
} from "./types";

export const initialState: ContentState = {};

export default function contentReducer(
  state = initialState,
  action: ContentActionTypes
): ContentState {
  switch (action.type) {
    case FETCH_LANDING_PAGE_CONTENT_SUCCESS:
      return {
        ...state,
        landingPage: action.payload,
      };
    case FETCH_ORG_LISTING_CONTENT_SUCCESS:
      return {
        ...state,
        orgListing: action.payload,
      };
    case FETCH_ADD_USER_CONTENT_SUCCESS:
      return {
        ...state,
        addUser: action.payload,
      };
    case FETCH_EDIT_USER_CONTENT_SUCCESS:
      return {
        ...state,
        editUser: action.payload,
      };
    case FETCH_PENDING_USER_CONTENT_SUCCESS:
      return {
        ...state,
        pendingUser: action.payload,
      };
    case FETCH_REVIEW_USER_CONTENT_SUCCESS:
      return {
        ...state,
        reviewUser: action.payload,
      };
    case FETCH_CATEGORIES_CONTENT_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };
    case FETCH_PRODUCTS_CONTENT_SUCCESS:
      return {
        ...state,
        products: action.payload,
      };
    default:
      return state;
  }
}
