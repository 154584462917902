import { call, delay, put } from "redux-saga/effects";
import { ApiService, ICallApi, YieldReturn } from "@acca-portals/shared";

export function* useSaga(
  types: any[],
  requestArgs: ICallApi,
  resolve: (data: unknown) => void = null,
  meta: unknown = null,
  timeout: number = null
) {
  const [success, error] = types;
  try {
    const response: YieldReturn<typeof ApiService.CallApi> = yield call(
      ApiService.CallApi,
      requestArgs
    );
    if (timeout) {
      yield delay(timeout);
    }
    yield put(success(response.data, meta));
    if (resolve) return resolve(response.data);
    return response;
  } catch (reason) {
    return yield put(error(reason));
  }
}
