import { OrgState, OrgActionTypes, SET_ORG_ID, SET_ORG_ROLE_ID } from "./types";

export const initialState: OrgState = {
  orgId: null,
  orgRoleId: null,
};

export default function orgReducer(state = initialState, action: OrgActionTypes): OrgState {
  switch (action.type) {
    case SET_ORG_ID:
      return {
        ...state,
        orgId: action.value,
      };
    case SET_ORG_ROLE_ID:
      return {
        ...state,
        orgRoleId: action.value,
      };
    default:
      return state;
  }
}
