export default {
  pageHeading: "Manage Users",
  title: "Review {username}",
  acceptUser: "Accept user",
  declineUser: "Decline User",
  popupMessage:
    "The user request to join your organisation will be deleted and they will be notified.",
  popupLabel: "Message to user (optional)",
  toastSuccessMessage: "{username} added as {roleType}",
  toastCancelMessage: "User declined. Please wait for page to refresh.",
  requestedAccessTimestamp: "Requested access on {date}",
  userRoleLabel: "Choose a user role",
};
