export default {
  pageTitle: "Admin",
  firstnameInputLabel: "First name",
  lastnameInputLabel: "Last name",
  emailInputLabel: "Email",
  userRoleLabel: "Change user role",
  submitButtonLabel: "Confirm",
  cancelButtonLabel: "Back",
  removeButtonLabel: "Remove user",
  userTitleTimestamp: "User since {joinedDate}",
};
