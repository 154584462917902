import { AnyAction } from "redux";
import { typeCreator } from "../utility/types";

export const FETCH_AGREEMENTS = typeCreator("AGREEMENTS", "FETCH_AGREEMENTS");
export const FETCH_AGREEMENT_INFO = typeCreator("AGREEMENTS", "FETCH_AGREEMENT_INFO");
export const FETCH_AGREEMENT_SIGNATORIES = typeCreator("AGREEMENTS", "FETCH_AGREEMENT_SIGNATORIES");

export const SET_TIMELINE_IS_SUBMITTING = "AGREEMENTS/SET_TIMELINE_IS_SUBMITTING";
export const SUBMIT_AGREEMENT_REJECT_REASONS = typeCreator(
  "AGREEMENTS",
  "SUBMIT_AGREEMENT_REJECT_REASONS"
);
export const SUBMIT_AGREEMENT_ACCEPTED = typeCreator("AGREEMENTS", "SUBMIT_AGREEMENT_ACCEPTED");

export const SET_REJECT_MESSAGE = "AGREEMENTS/SET_REJECT_MESSAGE";
export const SET_AGREEMENT_ID = "AGREEMENTS/SET_AGREEMENT_ID";
export const RESET_REJECT_MESSAGE = "AGREEMENTS/RESET_REJECT_MESSAGE";
export const SET_AGREEMENT_ERROR = "AGREEMENTS/SET_AGREEMENT_ERROR";
export const RESET_AGREEMENT_ERROR = "AGREEMENTS/RESET_AGREEMENT_ERROR";
export const SET_TOTAL_SIGNATORIES = "AGREEMENTS/SET_TOTAL_SIGNATORIES";
export const RESET_TOTAL_SIGNATORIES = "AGREEMENTS/RESET_TOTAL_SIGNATORIES";
export const SET_SELECTED_SIGNATORIES_LENGTH = "AGREEMENTS/SET_SELECTED_SIGNATORIES_LENGTH";
export const RESET_SELECTED_SIGNATORIES_LENGTH = "AGREEMENTS/RESET_SELECTED_SIGNATORIES_LENGTH";

export interface Pricing {
  standardPrice?: string;
  offerPrice?: string;
  currency: string;
}
export interface Document {
  fileName?: string;
  mimeType?: string;
  content?: string;
  link?: string;
}
export interface ProductList {
  id: string;
  name: string;
  description: string;
  code: string;
  category?: string;
  pricing?: Pricing;
}

export interface PartnerContactDetails {
  name: string;
  emailAddress: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  countryOfResidence: string;
  postalCode: string;
}

export interface OwnerContactDetails {
  name: string;
  emailAddress: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  countryOfResidence: string;
  postalCode: string;
  fullAddress: string;
}
export interface Agreement {
  id: string;
  name: string;
  agreementNumber: string;
  document?: Document;
  status: string;
  agreementStartDate: string;
  agreementEndDate: string;
  modifiedDate: string;
  publishedDate: string;
  quoteAcceptedDate: string;
  agreementSignedDate: string;
  cancelledDate: string;
  ownerContactDetails: OwnerContactDetails;
  partnerContactDetails: PartnerContactDetails;
  productBundle: string;
  productBundleDescription: string;
  productsList: Array<ProductList>;
  currencySymbol: string;
}
export interface Quote {
  id: string;
  name: string;
  quoteNumber: string;
  revision?: number;
  validFrom: string;
  validTo: string;
  modifiedDate: string;
  status: string;
  totalAmount: string;
  discountAmount?: string;
  totalLineDiscountAmount?: string;
  productBundle?: string;
  productBundleDescription?: string;
  productDescription?: string;
  productsList?: Array<ProductList>;
  currencySymbol: string;
}

export interface submitRejectReason {
  status?: string;
  comment?: string;
}

export interface agreementSignatory {
  firstName?: string;
  lastName?: string;
  email?: string;
}
export interface contacts {
  contactId?: string;
}
export interface signatories {
  contacts?: Array<contacts>;
  agreementSignatories?: Array<agreementSignatory>;
}
export interface submitAgreementAccepted {
  status?: string;
  signatories?: signatories;
}
export interface AgreementSignatories {
  contactId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}
export interface AgreementsState {
  agreements?: Array<Agreement>;
  agreementInfo?: Agreement;
  quotes?: Array<Quote>;
  timelineSubmitting?: boolean;
  submitDeclineReasons?: submitRejectReason;
  rejectMessage?: string;
  agreementId?: string;
  submitAgreementAccepted?: submitAgreementAccepted;
  agreementError?: string[];
  agreementSignatories?: Array<AgreementSignatories>;
  totalSignatories?: any[];
  selectedSignatories?: number;
}

export interface FetchAgreementsAction extends AnyAction {
  type: typeof FETCH_AGREEMENTS.START;
  resolve: (response: unknown) => void;
}

export interface FetchAgreementsSuccessAction extends AnyAction {
  type: typeof FETCH_AGREEMENTS.SUCCESS;
  payload: {
    agreements?: Array<Agreement>;
    quotes?: Array<Quote>;
  };
}

export interface FetchAgreementInfoAction extends AnyAction {
  type: typeof FETCH_AGREEMENT_INFO.START;
  resolve: (response: unknown) => void;
}

export interface FetchAgreementInfoSuccessAction extends AnyAction {
  type: typeof FETCH_AGREEMENT_INFO.SUCCESS;
  payload: {
    agreements?: Array<Agreement>;
  };
}

export interface SetTimeLineIsSubmitting extends AnyAction {
  type: typeof SET_TIMELINE_IS_SUBMITTING;
  value?: boolean;
}
export interface FetchAgreementSignatoriesAction extends AnyAction {
  type: typeof FETCH_AGREEMENT_SIGNATORIES.START;
  resolve: (response: unknown) => void;
}

export interface FetchAgreementSignatoriesSuccessAction extends AnyAction {
  type: typeof FETCH_AGREEMENT_SIGNATORIES.SUCCESS;
  payload: {
    agreementSignatories?: Array<AgreementSignatories>;
  };
}

export interface SubmitRejectReasonsAction extends AnyAction {
  type: typeof SUBMIT_AGREEMENT_REJECT_REASONS.START;
  resolve?: (response: unknown) => void;
}

export interface SubmitRejectReasonsSuccessAction extends AnyAction {
  type: typeof SUBMIT_AGREEMENT_REJECT_REASONS.SUCCESS;
}

export interface SubmitRejectReasonsErrorAction extends AnyAction {
  type: typeof SUBMIT_AGREEMENT_REJECT_REASONS.ERROR;
}

export interface SetRejectMessageAction extends AnyAction {
  type: typeof SET_REJECT_MESSAGE;
  value: string;
}

export interface SetAgreementIdAction extends AnyAction {
  type: typeof SET_AGREEMENT_ID;
  value: string;
}

export interface SubmitAgreementAcceptedAction extends AnyAction {
  type: typeof SUBMIT_AGREEMENT_ACCEPTED.START;
  resolve?: (response: unknown) => void;
}

export interface SubmitAgreementAcceptedSuccessAction extends AnyAction {
  type: typeof SUBMIT_AGREEMENT_ACCEPTED.SUCCESS;
}

export interface SubmitAgreementAcceptedErrorAction extends AnyAction {
  type: typeof SUBMIT_AGREEMENT_ACCEPTED.ERROR;
}

export interface ResetRejectMessageAction extends AnyAction {
  type: typeof RESET_REJECT_MESSAGE;
}

export interface SetAgreementErrorAction extends AnyAction {
  type: typeof SET_AGREEMENT_ERROR;
  value: string[];
}
export interface ResetAgreementErrorAction extends AnyAction {
  type: typeof RESET_AGREEMENT_ERROR;
}
export interface SetTotalSignatoriesAction extends AnyAction {
  type: typeof SET_TOTAL_SIGNATORIES;
  value: any[];
}
export interface ResetTotalSignatoriesAction extends AnyAction {
  type: typeof RESET_TOTAL_SIGNATORIES;
}
export interface SetSelectedSignatoriesAction extends AnyAction {
  type: typeof SET_SELECTED_SIGNATORIES_LENGTH;
  value: number;
}
export interface ResetSelectedSignatoriesAction extends AnyAction {
  type: typeof RESET_SELECTED_SIGNATORIES_LENGTH;
}
export type AgreementsActionTypes =
  | FetchAgreementsAction
  | FetchAgreementsSuccessAction
  | FetchAgreementInfoAction
  | FetchAgreementInfoSuccessAction
  | SetTimeLineIsSubmitting
  | SubmitRejectReasonsAction
  | SubmitRejectReasonsSuccessAction
  | SubmitRejectReasonsErrorAction
  | SetRejectMessageAction
  | SetAgreementIdAction
  | SubmitAgreementAcceptedAction
  | SubmitAgreementAcceptedSuccessAction
  | SubmitAgreementAcceptedErrorAction
  | ResetRejectMessageAction
  | SetAgreementErrorAction
  | ResetAgreementErrorAction
  | FetchAgreementSignatoriesAction
  | FetchAgreementSignatoriesSuccessAction
  | SetTotalSignatoriesAction
  | ResetTotalSignatoriesAction
  | SetSelectedSignatoriesAction
  | ResetSelectedSignatoriesAction;
