const VALIDATION_MESSAGES = {
  isAscii: "Non-ascii characters are not supported. Please enter ascii characters only",
  isNotEmpty: "This field is required",
  isAlphanumeric: "Cannot contain special characters",
  isLength: "Must be between {minLength} and {maxLength} characters",
  isMinLength: "Must be {minLength} or more characters",
  isMaxLength: "Must be less than {maxLength} characters",
  isEmail: "Must be a valid email format",
  isEmailValid: "Must be a valid email.",
  userAlreadyExists:
    "This user already has {role} permissions for the {orgRole} role for {orgName} ",
};
export default VALIDATION_MESSAGES;
