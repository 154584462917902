import React, { FC, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, IconProfile24, IconAdd16 } from "acca-design-system";
import { Container, PageContentLoader } from "@acca-portals/shared";
import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";
import UserTable from "./components/UserTable/UserTable";
import staticContent from "./OrganisationDetailsPage.content";
import { resetUserDetails } from "../../state/newUserDetails/actions";
import NotificationCard from "./components/NotificationCard/NotificationCard";
import UserCount from "./components/UserCount/UserCount";

import ROUTES from "../../constants/routes";
import {
  getNotifications,
  getUserTableData,
  getOrganisationName,
} from "../../state/organisationOverview/selectors";
import { fetchOrganisationOverview } from "../../state/organisationOverview/action";
import { setReviewIsAccepted, setReviewIsDeclined } from "../../state/reviewUser/actions";
import "./organisation-details-page.scss";

const OrganisationDetailsPage: FC = () => {
  const { reviewHeadingPlural, reviewHeadingSingle } = staticContent;
  const { orgId, orgRoleId } = useParams<{ orgId: string; orgRoleId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const notifications = useSelector((state) => getNotifications(state, orgId, orgRoleId));
  const userTableData = useSelector((state) => getUserTableData(state, orgId, orgRoleId));
  const orgName = useSelector((state) => getOrganisationName(state, orgId, orgRoleId));
  const pageReady = notifications || userTableData;
  const breadcrumb = [
    {
      text: "Organisations",
      to: "/admin/organisations",
    },
    {
      text: "Organisation details",
      to: "/admin/organisation-details",
    },
  ];

  useEffect(() => {
    dispatch(fetchOrganisationOverview(orgId, orgRoleId));
    dispatch(setReviewIsDeclined(false));
    dispatch(setReviewIsAccepted(false));
  }, [dispatch]);

  if (!pageReady) {
    return <PageContentLoader />;
  }

  return (
    <DefaultPageLayout
      titleContentText={staticContent?.pageHeading}
      pageTitle={staticContent.title}
      pageSubTitle={orgName}
      userCount={<UserCount />}
      breadcrumb={breadcrumb}
    >
      <Container className="section">
        <div className="cell">
          {notifications && (
            <>
              {notifications.length < 1 ? (
                ""
              ) : (
                <h3 className="u-margin-bottom-1">
                  {notifications.length <= 1 ? reviewHeadingSingle : reviewHeadingPlural}
                </h3>
              )}

              <NotificationCard
                notificationList={notifications}
                orgId={orgId}
                orgRoleId={orgRoleId}
                orgName={orgName}
              />
            </>
          )}
        </div>
        {userTableData && (
          <div className="cell">
            <Card className="table">
              <div className="table__description">
                <div className="table__header">
                  <IconProfile24 className="table__header-icon" />
                  <h2 className="u-font-heading h500">{staticContent.tableHeader}</h2>
                </div>
                <Button
                  text={staticContent.addNewUser}
                  type="button"
                  onClick={() => {
                    dispatch(resetUserDetails());
                    return history.push(`${ROUTES.addUser}/${orgId}/${orgRoleId}`);
                  }}
                  variant="outlined"
                  icon={IconAdd16}
                  iconPosition="left"
                  className="add-new-user-button"
                />
              </div>
              <h3 className="table__pageSubTitle">{staticContent.tableSubTitle}</h3>
              <UserTable users={userTableData} orgName={orgName} />
            </Card>
          </div>
        )}
      </Container>
    </DefaultPageLayout>
  );
};

export default OrganisationDetailsPage;
