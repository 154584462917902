import {
  FETCH_AGREEMENTS,
  FETCH_AGREEMENT_INFO,
  SET_TIMELINE_IS_SUBMITTING,
  SUBMIT_AGREEMENT_ACCEPTED,
  SUBMIT_AGREEMENT_REJECT_REASONS,
  SET_AGREEMENT_ID,
  SET_REJECT_MESSAGE,
  SET_AGREEMENT_ERROR,
  RESET_REJECT_MESSAGE,
  RESET_AGREEMENT_ERROR,
  FETCH_AGREEMENT_SIGNATORIES,
  SET_TOTAL_SIGNATORIES,
  RESET_TOTAL_SIGNATORIES,
  SET_SELECTED_SIGNATORIES_LENGTH,
  RESET_SELECTED_SIGNATORIES_LENGTH,
} from "./types";
import actionCreator from "../utility/actionCreator";

const args = ["orgId", "orgRoleId"];

export const fetchAgreements = actionCreator(FETCH_AGREEMENTS.START, ...args);
export const fetchAgreementInfo = actionCreator(FETCH_AGREEMENT_INFO.START, ...args, "agreementId");
export const setTimelineSubmitting = actionCreator(SET_TIMELINE_IS_SUBMITTING, "value");
export const submitAgreementAccepted = actionCreator(
  SUBMIT_AGREEMENT_ACCEPTED.START,
  ...args,
  "agreementId",
  "resolve"
);
export const submitAgreementRejectedReasons = actionCreator(
  SUBMIT_AGREEMENT_REJECT_REASONS.START,
  ...args,
  "agreementId",
  "resolve"
);
export const SetAgreementId = actionCreator(SET_AGREEMENT_ID, "value");
export const SetAgreementError = actionCreator(SET_AGREEMENT_ERROR, "value");
export const SetRejectMessage = actionCreator(SET_REJECT_MESSAGE, "value");
export const ResetRejectMessage = actionCreator(RESET_REJECT_MESSAGE);
export const ResetAgreementError = actionCreator(RESET_AGREEMENT_ERROR);
export const FetchAgreementSignatories = actionCreator(FETCH_AGREEMENT_SIGNATORIES.START, "orgId");
export const SetTotalSignatories = actionCreator(SET_TOTAL_SIGNATORIES, "value");
export const ResetTotalSignatories = actionCreator(RESET_TOTAL_SIGNATORIES);
export const SetSelectedSignatories = actionCreator(SET_SELECTED_SIGNATORIES_LENGTH, "value");
export const ResetSelectedSignatories = actionCreator(RESET_SELECTED_SIGNATORIES_LENGTH);
