import { RootState } from "../rootReducer";
import bundleImages from "../../configs/bundleImages";
import AGREEMENT_STATUS from "../../constants/agreementStatus";
import QUOTE_STATUS from "../../constants/quoteStatus";

export const getTimelineSubmitting = (state: RootState) =>
  state.agreementsAndQuotes.timelineSubmitting;

export const getQuotesAndAgreementsData = (state: RootState) => {
  const agreementsPublishedData = [];
  const agreementsSignaturePendingData = [];
  const agreementsSignaturePendingExpiryImminentData = [];
  const agreementsExpiryImminent = [];
  const agreementsSignedData = [];
  const agreementsActiveData = [];
  const agreementsInActiveData = [];
  const agreementsExpiredData = [];
  const agreementsOnHoldData = [];
  const agreementsDeclinedData = [];
  const agreementsCancelledData = [];

  // eslint-disable-next-line no-unused-expressions
  state.agreementsAndQuotes?.agreements?.forEach((item) => {
    const includedItems = item.productsList?.map((productItem) => productItem.category);
    const itemData = {
      name: item.productBundle,
      headline: item.productBundleDescription,
      imageUrl:
        bundleImages.bundleImages[Math.floor(Math.random() * bundleImages.bundleImages.length)],
      agreementEndDate: item.agreementEndDate,
      agreementStartDate: item.agreementStartDate,
      modifiedDate: item.modifiedDate,
      cancelledDate: item.cancelledDate,
      document: item.document,
      publishedOn: item.publishedDate,
      status: item.status,
      includedItems,
      agreementId: item.id,
      agreementNumber: item.agreementNumber,
    };
    switch (item.status) {
      case AGREEMENT_STATUS.COMPLETE:
        return agreementsActiveData.push(itemData);
      case AGREEMENT_STATUS.INCOMPLETE.SIGNATURE_PENDING:
        return agreementsSignaturePendingData.push(itemData);
      case AGREEMENT_STATUS.INCOMPLETE.SIGN_PEND_DEADLINE_IMMINENT:
        return agreementsSignaturePendingExpiryImminentData.push(itemData);
      case AGREEMENT_STATUS.INCOMPLETE.ACTIVE_DEADLINE_IMMINENT:
        return agreementsExpiryImminent.push(itemData);
      case AGREEMENT_STATUS.INCOMPLETE.SIGNED:
        return agreementsSignedData.push(itemData);
      case AGREEMENT_STATUS.INCOMPLETE.NOT_YET_ACCEPTED:
        return agreementsPublishedData.push(itemData);
      case AGREEMENT_STATUS.INCOMPLETE.INACTIVE:
        return agreementsInActiveData.push(itemData);
      case AGREEMENT_STATUS.STALE.ON_HOLD:
        return agreementsOnHoldData.push(itemData);
      case AGREEMENT_STATUS.STALE.EXPIRED:
        return agreementsExpiredData.push(itemData);
      case AGREEMENT_STATUS.STALE.DECLINED:
        return agreementsDeclinedData.push(itemData);
      case AGREEMENT_STATUS.STALE.CANCELLED:
        return agreementsCancelledData.push(itemData);
      default:
        return null;
    }
  });

  const quotesData = state.agreementsAndQuotes?.quotes
    ?.filter((quote) => quote.status === QUOTE_STATUS.IN_PROGRESS)
    .map((item) => {
      const includedItems = item.productsList?.map((productItem) => productItem.name);
      return {
        name: item.productBundle,
        headline: item.productBundleDescription,
        imageUrl:
          bundleImages.bundleImages[Math.floor(Math.random() * bundleImages.bundleImages.length)],
        validTo: item.validTo,
        validFrom: item.validFrom,
        modifiedDate: item.modifiedDate,
        status: item.status,
        includedItems,
        quoteNumber: item.quoteNumber,
      };
    });

  const activeAgreements = [
    ...agreementsExpiryImminent,
    ...agreementsPublishedData,
    ...agreementsSignaturePendingExpiryImminentData,
    ...agreementsSignaturePendingData,
    ...agreementsSignedData,
    ...agreementsActiveData,
  ];

  const staleAgreements = [
    ...agreementsOnHoldData,
    ...agreementsInActiveData,
    ...agreementsExpiredData,
    ...agreementsDeclinedData,
    ...agreementsCancelledData,
  ];
  return {
    activeQuotes: quotesData,
    activeAgreements,
    staleAgreements,
  };
};

export const getQuote = (state: RootState, refNumber: any) => {
  if (state.agreementsAndQuotes?.quotes) {
    return state.agreementsAndQuotes?.quotes.find((quote) => quote.quoteNumber === refNumber);
  }
  return null;
};

export const getAgreement = (state: RootState) => state.agreementsAndQuotes?.agreementInfo;
export const getAgreementRejectedMessage = (state: RootState) =>
  state.agreementsAndQuotes?.rejectMessage;
export const getAgreementId = (state: RootState) => state.agreementsAndQuotes?.agreementId;
export const getAgreementError = (state: RootState) => state.agreementsAndQuotes?.agreementError;
export const getAgreementSignatories = (state: RootState) =>
  state.agreementsAndQuotes?.agreementSignatories;
export const getTotalSignatories = (state: RootState) =>
  state.agreementsAndQuotes?.totalSignatories;
export const getSelectedSignatories = (state: RootState) =>
  state.agreementsAndQuotes?.selectedSignatories;
