import {
  FETCH_ORGANISATIONS,
  UPDATE_ORG_LISTING_FILTER,
  RESET_ORG_LISTING_FILTER,
  UPDATE_PAGE_NUMBER,
} from "./types";
import actionCreator from "../utility/actionCreator";

export const updatePageNumber = actionCreator(UPDATE_PAGE_NUMBER, "pageNumber");
export const fetchOrganisations = actionCreator(FETCH_ORGANISATIONS.START);
export const updateOrgListingFilter = actionCreator(UPDATE_ORG_LISTING_FILTER, "option");
export const resetOrgListingFilter = actionCreator(RESET_ORG_LISTING_FILTER);
