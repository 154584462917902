import React, { FC, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconSubtract16, IconAdd16, IconHandshake32 } from "acca-design-system";
import {
  Container,
  PageContentLoader,
  replaceTemplates,
  useNotifications,
} from "@acca-portals/shared";

import DefaultPageLayout from "../../components/DefaultPageLayout/DefaultPageLayout";
import staticContent from "./Agreements.content";
import AgreementCard from "./components/AgreementCard/AgreementCard";
import { getQuotesAndAgreementsData } from "../../state/agreementsAndQuotes/selectors";
import { fetchAgreements } from "../../state/agreementsAndQuotes/actions";
import { getOrgId, getOrgRoleId } from "../../state/org/selectors";
import "./agreements.scss";

import AGREEMENT_STATUS from "../../constants/agreementStatus";
import Notifications from "../../components/Notifications/Notifications";

const Agreements: FC = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const orgRoleId = useSelector(getOrgRoleId);
  const [isExpiredExpanded, setIsExpiredExpanded] = useState(false);
  const quotesAndAgreementsData = useSelector(getQuotesAndAgreementsData);
  const { notificationsLoading, notifications } = useNotifications();

  let ctaPlaceholder = "";
  quotesAndAgreementsData.staleAgreements.map((item) => {
    if (item.status === AGREEMENT_STATUS.STALE.EXPIRED) {
      ctaPlaceholder = "expired";
    }
    if (item.status === AGREEMENT_STATUS.STALE.DECLINED) {
      if (ctaPlaceholder === "expired") {
        ctaPlaceholder = ctaPlaceholder.concat("/declined");
      } else if (!ctaPlaceholder) {
        ctaPlaceholder = "declined";
      }
    }
    return null;
  });

  const expiredCtaText = replaceTemplates(staticContent.expiredCtaText, {
    placeholderText: ctaPlaceholder,
  });
  const pageReady = !!quotesAndAgreementsData;

  const onToggleExpired = async (e: FormEvent) => {
    e.preventDefault();
    setIsExpiredExpanded(!isExpiredExpanded);
  };

  useEffect(() => {
    dispatch(fetchAgreements(orgId, orgRoleId));
  }, [dispatch]);

  if (!pageReady) {
    return <PageContentLoader />;
  }

  return (
    <DefaultPageLayout
      titleContentText={staticContent.titleContentText}
      pageTitle={staticContent.pageTitle}
      pageSubTitle={staticContent.pageSubTitle}
      pageSubTitleFontSize="h500"
    >
      {notifications && (
        <Notifications notifications={notifications.filter((n) => n.category === "QUOTES")} />
      )}
      <Container className="section" innerClassName="agreements-headline u-margin-bottom-1">
        <div className="headline-icon">
          <IconHandshake32 />
        </div>
        <div className="headline-text">Your agreements</div>
      </Container>
      <Container className="section">
        <>
          {quotesAndAgreementsData.activeQuotes?.length > 0 &&
            quotesAndAgreementsData.activeQuotes?.map(
              ({ name, headline, imageUrl, includedItems, status, quoteNumber, validTo }, i) => (
                <AgreementCard
                  key={i}
                  headline={headline}
                  imageUrl={imageUrl}
                  status={status}
                  name={name}
                  includedItems={includedItems}
                  quoteNumber={quoteNumber}
                  validTo={validTo}
                />
              )
            )}

          {quotesAndAgreementsData.activeAgreements?.length > 0 &&
            quotesAndAgreementsData.activeAgreements?.map(
              ({ name, headline, imageUrl, includedItems, status, agreementId, validTo }, i) => (
                <AgreementCard
                  key={i}
                  headline={headline}
                  imageUrl={imageUrl}
                  status={status}
                  name={name}
                  includedItems={includedItems}
                  agreementId={agreementId}
                  validTo={validTo}
                />
              )
            )}

          {quotesAndAgreementsData.activeAgreements?.length === 0 &&
            quotesAndAgreementsData.activeQuotes?.length === 0 && (
              <AgreementCard key="empty" imageUrl="https://picsum.photos/1056/362" isEmpty />
            )}
        </>
      </Container>
      <Container className="section u-margin-bottom-2 u-margin-top-2">
        <div className="buttons-wrapper">
          <Button text={staticContent.contactBDMCtaText} variant="outlined" />
          {quotesAndAgreementsData.staleAgreements?.length > 0 && (
            <Button
              iconPosition="left"
              icon={isExpiredExpanded ? IconSubtract16 : IconAdd16}
              text={expiredCtaText}
              onClick={onToggleExpired}
              variant="link"
            />
          )}
        </div>
      </Container>
      {isExpiredExpanded && (
        <Container className="section">
          {quotesAndAgreementsData.staleAgreements?.map(
            ({ name, headline, imageUrl, includedItems, status, agreementId, validTo }, i) => (
              <AgreementCard
                key={i}
                headline={headline}
                imageUrl={imageUrl}
                status={status}
                name={name}
                includedItems={includedItems}
                agreementId={agreementId}
                validTo={validTo}
                stale
              />
            )
          )}
        </Container>
      )}
    </DefaultPageLayout>
  );
};

export default Agreements;
