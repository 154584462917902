import React, { FC, useState, useContext, createContext, Context, useEffect } from "react";
import { useQuery } from "react-query";
import { ApiService } from "@acca-portals/shared";
import { INotification, INotificationsContext, INotificationsResponse } from "./types";
import content from "./content";

const NotificationsContext = createContext<INotificationsContext>(
  undefined
) as Context<INotificationsContext>;

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider: FC<{
  apiUrl: string;
}> = ({ children, apiUrl }) => {
  const [notifications, setNotifications] = useState<INotification[]>();
  const [notificationsLoading, setNotificationsLoading] = useState(true);

  const notificationsResponse = useQuery<INotificationsResponse, Error>(
    "notifications",
    async () => {
      const result = await ApiService.CallApi<INotificationsResponse>({
        url: apiUrl,
        method: "GET",
      });

      return result.data;
    }
  );

  useEffect(() => {
    if (notificationsResponse.isLoading) {
      return;
    }

    setNotifications(
      notificationsResponse.data.notifications
        .filter((n) => !n.suppressed)
        .map((notification) => {
          return {
            ...notification,
            content: content.cards.find(
              (card) => card.notificationCode === notification.notificationCode
            ),
          };
        })
    );

    setNotificationsLoading(false);
  }, [notificationsResponse?.isLoading, notificationsResponse?.data?.notifications]);

  return (
    <NotificationsContext.Provider value={{ notificationsLoading, notifications }}>
      {children}
    </NotificationsContext.Provider>
  );
};
