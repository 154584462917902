import {
  PendingUserActionTypes,
  PendingUserDetailsState,
  PENDING_DELETE_INVITE,
  PENDING_RESEND_INVITE,
} from "./types";

export const initialState: PendingUserDetailsState = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  userRole: "",
};

export default function pendingUserDetailsReducer(
  state = initialState,
  action: PendingUserActionTypes
): PendingUserDetailsState {
  switch (action.type) {
    case PENDING_DELETE_INVITE.SUCCESS:
      return initialState;
    case PENDING_RESEND_INVITE.SUCCESS:
      return initialState;
    default:
      return state;
  }
}
