import React, { FC } from "react";

import { Card, IconNational, Button } from "acca-design-system";

import { replaceTemplates } from "@acca-portals/shared";
import ROUTES from "../../../../constants/routes";
import getFlagIcon from "../../../../utilities/get-flag-icon";
import formatDate from "../../../../utilities/format-date";
import { Organisation, Role } from "../../../../state/organisationListing/types";

import content from "./OrganisationsListingCard.content";
import "./organisation-listing-card.scss";

export interface OrganisationsListingCardProps {
  organisationId: string;
  organisation: Organisation;
  roles: Array<Role>;
}

const OrganisationsListingCard: FC<OrganisationsListingCardProps> = ({
  organisationId,
  organisation,
  roles,
}) => {
  const { name, address, officeType } = organisation;
  const { line1, city, postalCode, country } = address;
  const { requestedToJoin, manageButtonText } = content;

  const addressText = Object.values({ line1, city, postalCode })
    .filter((addressLine) => !!addressLine)
    .join(", ");
  const countryFlagSrc = getFlagIcon(country.iso3);
  return (
    <Card className="organisation-listing-card" icon={IconNational} iconSize="large">
      <h3 className="h500">{name}</h3>
      <p>
        <strong>{officeType}</strong>
      </p>
      <div className="organisation-listing-card__address">
        <img
          src={countryFlagSrc}
          alt={country.name}
          className="organisation-listing-card__map icon--medium"
        />
        <p>{addressText}</p>
      </div>

      {roles &&
        roles
          .sort((a, b) => {
            const textA = a.orgRole.toUpperCase();
            const textB = b.orgRole.toUpperCase();
            return textA.localeCompare(textB);
          })
          .map(({ orgRole, orgRoleid, requestDate }, i) => {
            return (
              <div className="organisation-listing-card__role-cta" key={i}>
                <h4 className="h600">{orgRole}</h4>
                {requestDate ? (
                  <p>{replaceTemplates(requestedToJoin, { date: formatDate(requestDate) })}</p>
                ) : (
                  <Button
                    variant="outlined"
                    to={`${ROUTES.organisationsListing}/${organisationId}/${orgRoleid}`}
                    text={manageButtonText}
                  />
                )}
              </div>
            );
          })}
    </Card>
  );
};

export default OrganisationsListingCard;
