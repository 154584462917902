import validator from "validator";

import { replaceTemplates } from "@acca-portals/shared";
import VALIDATION_MESSAGES from "../constants/validationMessages";

export const isNotEmpty = (value = "") => {
  const result = !validator.isEmpty(value);
  return {
    result,
    message: VALIDATION_MESSAGES.isNotEmpty,
  };
};

export const isMaxLength = (value: string, maxLength?: number) => {
  const result = validator.isLength(value, {
    max: maxLength || 500,
  });
  return {
    result,
    message: replaceTemplates(VALIDATION_MESSAGES.isMaxLength, {
      maxLength: `${maxLength || 500}`,
    }),
  };
};
export const isMaximumLength = (value: string, maxLength?: number) => {
  const result = validator.isLength(value, {
    max: maxLength || 2000,
  });
  return {
    result,
    message: replaceTemplates(VALIDATION_MESSAGES.isMaxLength, {
      maxLength: `${maxLength || 2000}`,
    }),
  };
};

export const isAlphanumericValue = (value: string) => {
  const inputValue = value.trim();
  const isAlphanumericWithSpaces = /^[a-zA-z\d\-.\s?&@]+$/;
  const result = validator.isEmpty(inputValue) || isAlphanumericWithSpaces.test(inputValue);

  return {
    result,
    message: VALIDATION_MESSAGES.isAlphanumeric,
  };
};

export const isEmail = (value: string) => {
  const inputValue = value.trim();
  const result = validator.isEmail(inputValue);

  return {
    result,
    message: VALIDATION_MESSAGES.isEmail,
  };
};
