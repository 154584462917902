import { RootState } from "../rootReducer";

export const getOrgId = (state: RootState) => {
  if (state.org?.orgId) return state.org.orgId;
  return sessionStorage.getItem("orgId");
};

export const getOrgRoleId = (state: RootState) => {
  if (state.org?.orgRoleId) return state.org.orgRoleId;
  return sessionStorage.getItem("orgRoleId");
};
