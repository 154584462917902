import React, { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  IconChevronDown16,
  IconExamProgress16,
  IconEllipses16,
  IconEdit16,
  Table,
  TableBody,
  TableCell,
  TableHeadCell,
  TableRow,
} from "acca-design-system";

import { format, parseISO } from "date-fns";

import { updateOrgSortBy } from "../../../../state/organisationOverview/action";

import dates from "../../../../constants/dates";

import "./user-table.scss";
import ROUTES from "../../../../constants/routes";
import { isUserAdminForOrganisation } from "../../../../state/userProfile/selectors";

interface Users {
  name: string;
  jobTitle: string;
  roleType: string;
  userSince: string;
  pending?: boolean;
  contactId?: string;
}

export interface UserTableProps {
  users: Array<Users>;
  orgName?: string;
}

const UserTable: FC<UserTableProps> = ({ users, orgName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orgId, orgRoleId } = useParams<{ orgId: string; orgRoleId: string }>();
  const isUserAdmin = useSelector((state) => isUserAdminForOrganisation(state, orgName));
  const dateFormat = dates.FORMAT_DAY_ABBREVIATEDMONTH_YEAR;
  const tableHeader =
    users[0] &&
    Object.keys(users[0]).map((item, idx) => {
      if (item === "pending" || item === "contactId") {
        return null;
      }
      return (
        <TableHeadCell key={item} scope="col">
          <Button
            key={idx}
            text={item.replace(/([a-z])([A-Z])/g, "$1 $2").toUpperCase()}
            icon={IconChevronDown16}
            iconPosition="right"
            className="user-table-header"
            variant="nav"
            onClick={() => dispatch(updateOrgSortBy(item))}
          />
        </TableHeadCell>
      );
    });

  return (
    users && (
      <Table className="user-table">
        <thead>
          <tr>{tableHeader}</tr>
        </thead>
        <TableBody>
          {users.map((item) => {
            const isAdmin = item.roleType.toLowerCase() === "administrators";
            const isPending = item.pending;
            const declined = item.roleType === "Declined";
            const cancelled = item.roleType === "Cancelled";
            return (
              <TableRow
                className={declined || cancelled ? "no-pointer" : null}
                key={Math.random()}
                onClick={() => {
                  if (cancelled || declined) {
                    history.push(null);
                  } else if (!isPending || isUserAdmin) {
                    history.push(`${ROUTES.patchUser}/${orgId}/${orgRoleId}/${item.contactId}`);
                  } else if (isPending) {
                    history.push(`${ROUTES.pendingUser}/${orgId}/${orgRoleId}/${item.contactId}`);
                  }
                }}
              >
                {Object.keys(item).map((key, idx) => {
                  const isFirstCell = String(key) === "name";
                  const isDate = String(key) === "userSince";
                  if (String(key) === "pending") {
                    return null;
                  }
                  return (
                    String(key) !== "contactId" && (
                      <TableCell className={isPending ? "user-table__pending" : null} key={idx}>
                        <div className="user-table__mobile-table-head">
                          <>
                            <div>{key.replace(/([a-z])([A-Z])/g, "$1 $2").toUpperCase()}</div>
                            {isFirstCell && (
                              <div className="user-table__mobile-button">
                                <Button to="#" variant="link" text="Edit" icon={IconEdit16} />
                              </div>
                            )}
                          </>
                        </div>
                        {(isAdmin && isFirstCell && (
                          <div className="user-table__name">
                            <div className="user-table__name-icon">
                              <IconExamProgress16 />
                            </div>
                            <div className="user-table__name-truncated">
                              <strong>{item[key]}</strong>
                            </div>
                          </div>
                        )) ||
                          (isAdmin && isDate && (
                            <strong>{format(parseISO(item[key]), dateFormat)}</strong>
                          )) ||
                          (isAdmin && <strong>{item[key]}</strong>) ||
                          (isFirstCell && (
                            <div className="user-table__name">
                              <div className="user-table__name-truncated">{item[key]}</div>
                            </div>
                          )) ||
                          (!isPending && isDate && format(parseISO(item[key]), dateFormat)) ||
                          item[key]}
                      </TableCell>
                    )
                  );
                })}
                <TableCell>
                  {declined || cancelled ? null : (
                    <div className="user-table__button">
                      <IconEllipses16 />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    )
  );
};

export default UserTable;
