export const { API_BASE_URL } = process.env;

export default {
  endpoints: {
    templates: {
      landingPage: `${API_BASE_URL}/public/ui/templates?page=lr/intro`,
      organisationsListing: `${API_BASE_URL}/public/ui/templates?page=admn/org`,
      addUser: `${API_BASE_URL}/public/ui/templates?page=admn/add-user`,
      editUser: `${API_BASE_URL}/public/ui/templates?page=admn/edit-user`,
      pendingUser: `${API_BASE_URL}/public/ui/templates?page=admn/pending`,
      reviewUser: `${API_BASE_URL}/public/ui/templates?page=admn/review-user`,
      // change the category, products link when we get a real one
      categories: `${API_BASE_URL}/public/ui/templates?page=admn/categories`,
      products: `${API_BASE_URL}/public/ui/templates?page=admn/products`,
      myDetailsPage: `${API_BASE_URL}/public/ui/templates?page=admn/user-details`,
      contactDetailsPage: `${API_BASE_URL}/public/ui/templates?page=admn/user-details/contact-details&channel=b2b-portal`,
      accountDetailsPage: `${API_BASE_URL}/public/ui/templates?page=admn/user-details/key-details&channel=b2b-portal`,
      marketingConsentPage: `${API_BASE_URL}/public/ui/templates?page=admn/marketing-consent&channel=b2b-portal`,
      notifications: `${API_BASE_URL}/public/ui/templates?page=notifications`,
    },
    user: {
      userProfile: `${API_BASE_URL}/users/me`,
      organisationListing: (contactId: string) =>
        `${API_BASE_URL}/contacts/${contactId}/organisations?admin=true&includeRequested=true`,
      organisationDetails: (orgId: string, orgRoleId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRoleId}?expand=users&includePending=true`,
      addUser: (orgId: string, orgRole: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRole}/users`,
      patchUser: (orgId: string, orgRole: string, contactId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRole}/users/${contactId}`,
      fetchUser: (orgId: string, orgRole: string, contactId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRole}/users/${contactId}`,
      reviewUser: (orgId: string, orgRoleId: string, requestId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRoleId}/userRequests/${requestId}`,
      deleteUser: (orgId: string, orgRoleId: string, contactId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRoleId}/users/${contactId}`,
      contactDetails: (contactId: string) => `${API_BASE_URL}/contacts/${contactId}`,
      notifications: (orgId: string, orgRoleId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRoleId}/notifications`,
    },
    invite: {
      deleteInvite: (orgId: string, orgRoleId: string, requestId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRoleId}/invitations/${requestId}`,
      resendInvite: (orgId: string, orgRoleId: string, requestId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRoleId}/invitations/${requestId}`,
    },
    lookups: {
      userRoles: `${API_BASE_URL}/lookups?type=userRoles`,
      jobTitles: `${API_BASE_URL}/lookups?type=jobTitles`,
      quoteDeclineReasons: `${API_BASE_URL}/lookups?type=quoteDeclineReasons`,
      quoteAcceptDeclineSubmit: (orgId: string, orgRoleId: string, quoteId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRoleId}/quotes/${quoteId}`,
      countries: `${API_BASE_URL}/lookups?type=countries`,
    },
    agreements: {
      landingPage: (orgId: string, orgRoleId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRoleId}/quotes?includeAgreements=true`,
      agreementInfo: (orgId: string, orgRoleId: string, agreementId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRoleId}/agreements/${agreementId}?includeDocument=true`,
      agreementAcceptRejectSubmit: (orgId: string, orgRoleId: string, agreementId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/roles/${orgRoleId}/agreements/${agreementId}`,
      agreementSignatories: (orgId: string) =>
        `${API_BASE_URL}/organisations/${orgId}/agreementSignatories`,
    },
  },
  timeout: {
    greetingsTimeout: 2000,
    reviewUserTimeout: 20000,
    appTimeout: 1800000,
  },
};
