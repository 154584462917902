import { useQuery } from "react-query";
import ApiService from "../services/api/ApiService";

const getContent = async <T>(url: string) => {
  const result = await ApiService.CallApi({
    url,
    method: "GET",
  });

  return result.data as T;
};

export const useContent = <T>(url: string) =>
  useQuery<T, Error>(url, () => getContent<T>(url), {
    cacheTime: 1000 * 60 * 5,
  });
