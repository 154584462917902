import { RootState } from "../rootReducer";

export const getContactId = (state: RootState) => {
  const { primaryContactId } = state.userProfile;

  if (!primaryContactId) {
    return state.userProfile.personas[0].contactId;
  }

  return primaryContactId;
};

export const getUserEmail = (state: RootState) => {
  if (state.userProfile?.personas?.length > 0 && state.userProfile?.personas[0].email) {
    return state.userProfile?.personas[0].email;
  }

  return "";
};

export const isUserAdminForOrganisation = (state: RootState, orgName: string) => {
  if (state.userProfile.personas?.length > 0) {
    const orgPersona = state.userProfile.personas.filter(({ name }) => {
      return name === orgName;
    })[0];
    if (orgPersona && orgPersona.roles) {
      const adminUserRole = orgPersona.roles.filter(({ objectName, role }) => {
        return objectName === orgName && role === "Organisation Administrator";
      });

      return adminUserRole.length > 0;
    }
  }

  return false;
};

export const getOrgRole = (state: RootState, orgId: string, roleTypeId: string) => {
  if (!state.userProfile.personas?.length || !orgId || !roleTypeId) {
    return null;
  }

  const persona = state.userProfile.personas.find((p) =>
    p.roles.some((role) => role.objectId === orgId)
  );

  if (persona) {
    return persona.roles.find((role) => role.roleTypeId === roleTypeId)?.roleType;
  }

  return null;
};

export const getUserFirstName = (state: RootState) => state?.userProfile?.firstName;

export const getHasMultiplePortalAccounts = (state: RootState) => {
  const personas = state?.userProfile?.personas || [];
  return personas.length > 1 || (personas.length === 1 && personas[0].roles.length > 1);
};

export const getUserName = (state: RootState) => state?.userProfile?.name;
