import React, { FC, useState, FormEvent, useEffect } from "react";
import {
  Button,
  DashboardCard,
  IconTimelineCircles32,
  TimelineEvent,
  Modal,
  BodyCopy,
  IconOpenInANewWindow16,
} from "acca-design-system";
import { useDispatch, useSelector } from "react-redux";
import { replaceTemplates } from "@acca-portals/shared";
import staticContent from "../QATimelineEvents.content";

import { Agreement, Quote } from "../../../state/agreementsAndQuotes/types";
import DeclineModal from "../../../pages/AgreementQuoteInfoPage/components/DeclineModal/DeclineModal";
import AgreementRejectModal from "../../AgreementRejectModal/AgreementRejectModal";
import AgreementAcceptModal from "../../AgreementAcceptModal/AgreementAcceptModal";
import AgreementSuccessModal from "../../AgreementSuccessModal/AgreementSuccessModal";
import REQUIRED_SIGNATORIES from "../../../constants/requiredSignatories";

import {
  SubmitQuoteDeclinedReasons,
  SetQuoteNumber,
  SetQuoteId,
  SubmitQuoteAccepted,
  resetDeclineMessage,
  resetDeclineReasons,
  resetDeclineError,
} from "../../../state/lookups/actions";
import {
  getSelectedReasons,
  getDeclinedMessage,
  getDeclineError,
} from "../../../state/lookups/selectors";
import {
  getAgreementError,
  getAgreementRejectedMessage,
  getSelectedSignatories,
} from "../../../state/agreementsAndQuotes/selectors";
import {
  fetchAgreements,
  fetchAgreementInfo,
  setTimelineSubmitting,
  ResetRejectMessage,
  ResetAgreementError,
  submitAgreementRejectedReasons,
  ResetTotalSignatories,
  submitAgreementAccepted,
  ResetSelectedSignatories,
} from "../../../state/agreementsAndQuotes/actions";
import { getOrgId, getOrgRoleId } from "../../../state/org/selectors";
import ZERO_VALUE from "../../../constants/zeroValue";

export interface HighlightedProps {
  quoteData: Quote;
  agreementData: Agreement;
  isQuote: boolean;
}

const Highlighted: FC<HighlightedProps> = ({ quoteData, agreementData, isQuote }) => {
  const quoteName = isQuote && `${quoteData.productBundle} quote`;

  const agreementName = !isQuote && `agreement`;
  const dispatch = useDispatch();
  const reasonsList = useSelector(getSelectedReasons);
  const declineMessage = useSelector(getDeclinedMessage);
  const declineError = useSelector(getDeclineError);
  const agreementError = useSelector(getAgreementError);
  const rejectMessage = useSelector(getAgreementRejectedMessage);
  const signatories = useSelector(getSelectedSignatories);

  const refNumber =
    ((isQuote || !agreementData) && quoteData.quoteNumber) ||
    (!isQuote && agreementData && agreementData.id);
  const id =
    ((isQuote || !agreementData) && quoteData.id) ||
    (!isQuote && agreementData && agreementData.id);
  const orgId = useSelector(getOrgId);
  const orgRoleId = useSelector(getOrgRoleId);
  const [showDeclineModal, setShowDeclineModal] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(null);
  const [showAgreementRejectModal, setShowAgreementRejectModal] = useState(null);
  const [showAgreementAcceptModal, setShowAgreementAcceptModal] = useState(null);
  const [showAgreementSuccessModal, setShowAgreementSuccessModal] = useState(null);

  const declineSubmit = () =>
    new Promise((resolve) => dispatch(SubmitQuoteDeclinedReasons(resolve)));
  const agreementRejectSubmit = () =>
    new Promise((resolve) =>
      dispatch(submitAgreementRejectedReasons(orgId, orgRoleId, id, resolve))
    );
  const agreementAcceptSubmit = () =>
    new Promise((resolve) => dispatch(submitAgreementAccepted(orgId, orgRoleId, id, resolve)));
  const acceptSubmit = () => new Promise((resolve) => dispatch(SubmitQuoteAccepted(resolve)));

  useEffect(() => {
    dispatch(SetQuoteNumber(refNumber));
    dispatch(SetQuoteId(id));
  }, [dispatch, refNumber, id]);

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    dispatch(setTimelineSubmitting(true));
    await declineSubmit();
    dispatch(fetchAgreements(orgId, orgRoleId));
    dispatch(resetDeclineError());
    dispatch(setTimelineSubmitting(false));
    setShowDeclineModal(false);
  };

  const onQuoteAcceptFormSubmit = () => {
    setShowSuccessModal(true);
  };

  const onDeclineModalClose = () => {
    dispatch(resetDeclineMessage());
    dispatch(resetDeclineReasons());
    dispatch(resetDeclineError());
    setShowDeclineModal(false);
  };

  const onAgreementRejectSubmit = async (e: FormEvent) => {
    e.preventDefault();
    dispatch(setTimelineSubmitting(true));
    await agreementRejectSubmit();
    dispatch(fetchAgreements(orgId, orgRoleId));
    dispatch(fetchAgreementInfo(orgId, orgRoleId, refNumber));
    dispatch(ResetAgreementError());
    dispatch(setTimelineSubmitting(false));
    setShowAgreementRejectModal(false);
  };
  const onAgreementRejectModalClose = () => {
    dispatch(ResetRejectMessage());
    dispatch(ResetAgreementError());
    setShowAgreementRejectModal(false);
  };

  const onAgreementAcceptSubmit = () => {
    setShowAgreementSuccessModal(true);
    setShowAgreementAcceptModal(false);
  };
  const onAgreementAcceptModalClose = () => {
    dispatch(ResetAgreementError());
    dispatch(ResetTotalSignatories());
    dispatch(ResetSelectedSignatories());
    setShowAgreementAcceptModal(false);
  };
  const onAgreementSuccessSubmit = async (e: FormEvent) => {
    e.preventDefault();
    dispatch(setTimelineSubmitting(true));
    await agreementAcceptSubmit();
    dispatch(fetchAgreements(orgId, orgRoleId));
    dispatch(fetchAgreementInfo(orgId, orgRoleId, refNumber));
    dispatch(setTimelineSubmitting(false));
    dispatch(ResetAgreementError());
    dispatch(ResetTotalSignatories());
    dispatch(ResetSelectedSignatories());
    setShowAgreementSuccessModal(false);
  };

  const onQuoteSuccessFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    dispatch(setTimelineSubmitting(true));
    setShowSuccessModal(false);
    await acceptSubmit();
    dispatch(fetchAgreements(orgId, orgRoleId));
    dispatch(setTimelineSubmitting(false));
    setShowDeclineModal(false);
  };
  const isValid = reasonsList.length >= 1 && declineError.length === 0;
  const isAgreementRejectValid = rejectMessage && agreementError.length === 0;
  const isAgreementAcceptValid =
    signatories > 0 &&
    signatories <= REQUIRED_SIGNATORIES.REQUIRED_AGREEMENT_SIGNATORIES &&
    agreementError.length === 0;

  const buttonRightDecline = (
    <Button variant="primary" text="Send" onClick={onFormSubmit} disabled={!isValid} />
  );
  const buttonRightSuccess = (
    <Button variant="primary" text="Ok" onClick={onQuoteSuccessFormSubmit} />
  );
  const buttonRightAgreementReject = (
    <Button
      variant="primary"
      text="Send"
      onClick={onAgreementRejectSubmit}
      disabled={!isAgreementRejectValid}
    />
  );
  const buttonRightAgreementAccept = (
    <Button
      variant="primary"
      text="Save"
      onClick={onAgreementAcceptSubmit}
      disabled={!isAgreementAcceptValid}
    />
  );
  const buttonRightAgreementSuccess = (
    <Button variant="primary" text="Continue" onClick={onAgreementSuccessSubmit} />
  );
  const currencyRegExp = new RegExp(
    ((isQuote || !agreementData) && quoteData.currencySymbol) ||
      (!isQuote && agreementData && agreementData.currencySymbol),
    "g"
  );

  const quoteItemsNames =
    isQuote && quoteData && quoteData.productsList.map((item) => item.category);
  const agreementItemsNames =
    agreementData &&
    agreementData.productsList &&
    agreementData.productsList.map((item) => item.category);
  const numberOfQuoteExcemptions =
    quoteItemsNames && quoteItemsNames.filter((item) => item.includes("Exemption")).length;
  const numberOfQuoteSubscriptions =
    quoteItemsNames && quoteItemsNames.filter((item) => item.includes("Subscription")).length;
  const numberOfQuoteRegistrations =
    quoteItemsNames && quoteItemsNames.filter((item) => item.includes("Registration")).length;
  const numberOfAgreementExcemptions =
    agreementItemsNames && agreementItemsNames.filter((item) => item.includes("Exemption")).length;
  const numberOfAgreementSubscriptions =
    agreementItemsNames &&
    agreementItemsNames.filter((item) => item.includes("Subscription")).length;
  const numberOfAgreementRegistrations =
    agreementItemsNames &&
    agreementItemsNames.filter((item) => item.includes("Registration")).length;

  return (
    <TimelineEvent id="activeQuote" icon={IconTimelineCircles32} contentSize="large">
      <DashboardCard
        id={isQuote ? "quoteCard" : "agreementCard"}
        heading={
          (isQuote && replaceTemplates(staticContent.quote.active.title, { quoteName })) ||
          replaceTemplates(staticContent.agreement.active.title, { agreementName })
        }
      >
        {!isQuote && <p>{staticContent.agreement.active.description}</p>}
        {isQuote && quoteData?.productsList && (
          <h3 className="timeline-event_subtitle">{staticContent.includes}</h3>
        )}
        {isQuote && quoteData.productsList && (
          <div className="u-margin-top-0 u-margin-bottom-3">
            {numberOfQuoteExcemptions >= 1 && (
              <div className="agreement-item u-margin-top-0">
                {numberOfQuoteExcemptions} {staticContent.exemptionsText}
              </div>
            )}
            {numberOfQuoteSubscriptions >= 1 && (
              <div className="agreement-item">
                {numberOfQuoteSubscriptions} {staticContent.subscriptionsText}
              </div>
            )}
            {numberOfQuoteRegistrations >= 1 && (
              <div className="agreement-item">{staticContent.registrationsText}</div>
            )}
          </div>
        )}
        <div className="total-with-buttons">
          {isQuote ? (
            <>
              {quoteData.totalLineDiscountAmount.replace(currencyRegExp, "") !==
                ZERO_VALUE.NO_SAVINGS && (
                <div className="total h600 u-font-heading">
                  {quoteData.totalAmount && (
                    <>
                      <strong>
                        {replaceTemplates(staticContent.savingsStatement, {
                          savings: quoteData.totalLineDiscountAmount,
                        })}
                      </strong>
                    </>
                  )}
                </div>
              )}
              <Button
                variant="outlined"
                text={staticContent.quote.active.buttons.queryQuote}
                onClick={() => setShowDeclineModal(true)}
              />
            </>
          ) : (
            <Button
              variant="outlined"
              text={staticContent.agreement.active.buttons.queryAgreement}
              onClick={() => setShowAgreementRejectModal(true)}
            />
          )}

          {(isQuote && (
            <Button
              variant="primary"
              text={staticContent.quote.active.buttons.accept}
              onClick={isQuote && onQuoteAcceptFormSubmit}
            />
          )) || (
            <Button
              icon={IconOpenInANewWindow16}
              iconPosition="right"
              variant="primary"
              text={staticContent.agreement.active.buttons.activate}
              onClick={() => setShowAgreementAcceptModal(true)}
            />
          )}
        </div>

        {/* TODO Add Your agreement popout */}
      </DashboardCard>
      {showSuccessModal && (
        <Modal buttonRight={buttonRightSuccess}>
          <>
            <BodyCopy className="u-margin-bottom-3 u-content-center">
              <div className="u-font-heading h500 u-margin-bottom-2 u-margin-top-2 u-text-center">
                {staticContent.successHeading}
              </div>

              <p className="u-margin-bottom-1 u-text-center">{staticContent.successDescLineOne}</p>
              <p className="u-margin-bottom-1 u-text-center">{staticContent.successDescLineTwo}</p>
            </BodyCopy>
          </>
        </Modal>
      )}
      {showDeclineModal && (
        <Modal
          closeLinkText="Cancel"
          closeModal={onDeclineModalClose}
          buttonRight={buttonRightDecline}
        >
          <DeclineModal />
        </Modal>
      )}
      {showAgreementRejectModal && (
        <Modal
          closeLinkText="Cancel"
          closeModal={onAgreementRejectModalClose}
          buttonRight={buttonRightAgreementReject}
        >
          <AgreementRejectModal />
        </Modal>
      )}
      {showAgreementAcceptModal && (
        <Modal
          closeLinkText="Cancel"
          closeModal={onAgreementAcceptModalClose}
          buttonRight={buttonRightAgreementAccept}
        >
          <AgreementAcceptModal />
        </Modal>
      )}
      {showAgreementSuccessModal && (
        <Modal buttonRight={buttonRightAgreementSuccess}>
          <AgreementSuccessModal />
        </Modal>
      )}
    </TimelineEvent>
  );
};

export default Highlighted;
