import { createSelector } from "reselect";

import { RootState } from "../rootReducer";

export const getNewUserDetailsError = (state: RootState) => state.newUserDetails.error;
export const getErrorCode = (state: RootState) => state.newUserDetails.errorCode;
export const getEmailInvalid = (state: RootState) => {
  return state.newUserDetails.errorCode === "BE-101-002";
};
export const getUserRole = (state: RootState) => state.newUserDetails.role;
export const getUserRoleId = (state: RootState) => state.newUserDetails.roleId;
export const getFirstname = (state: RootState) => state.newUserDetails.firstName;
export const getLastname = (state: RootState) => state.newUserDetails.lastName;
export const getEmail = (state: RootState) => state.newUserDetails.emailAddress;
export const getMessage = (state: RootState) => state.newUserDetails.message;

export const getHasRequiredFields = createSelector(
  getLastname,
  getEmail,
  getUserRole,
  (lastname, email, role) => !!lastname && !!email && !!role
);
