import React, { useState } from "react";
import { Card, LoadingDots, Button, ToggleControlGroup, RadioButton } from "acca-design-system";
import { useMarketingUnsubscribeReasons, Label } from "@acca-portals/shared";
import staticContent from "./MarketingUnsubscribeReasonCard.content";

export interface IMarketingUnsubscribeReasonCardProps {
  onSubmit: (reasonId: string) => void;
  onDismiss: () => void;
}

const MarketingUnsubscribeReasonCard = ({
  onSubmit,
  onDismiss,
}: IMarketingUnsubscribeReasonCardProps) => {
  const { isLoading, isError, data } = useMarketingUnsubscribeReasons();
  const [selected, setSelected] = useState();

  if (isError) {
    return null;
  }

  const renderMain = () => {
    return (
      <>
        <Label id="option_1" text={staticContent.title} />
        <ToggleControlGroup className="u-margin-top-2 u-margin-bottom-2">
          {data.map((reason) => (
            <RadioButton
              key={`option_${reason.id}`}
              id={`option_${reason.id}`}
              value={reason.id}
              text={reason.value}
              checked={selected === reason.id}
              onChange={(e) => setSelected(e.target.value)}
            />
          ))}
        </ToggleControlGroup>

        <Button
          text="Submit"
          onClick={() => onSubmit(selected)}
          className="u-margin-right-1"
          disabled={!selected}
        />
        <Button text="Dismiss" variant="outlined" onClick={onDismiss} />
      </>
    );
  };

  return <Card backgroundColor="grey">{isLoading ? <LoadingDots /> : renderMain()}</Card>;
};

export default MarketingUnsubscribeReasonCard;
