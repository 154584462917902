export default {
  quote: {
    pageTitle: "Your {quoteName} quote",
    detailsTitle: "Quote details for {quoteName}",
    detailsDuration: "Quote runs {duration}",
    price: "Quote price",
    idTitle: "QUOTE ID: ",
    exemptions: "Exemptions",
    subscriptionsTitle: "Subscriptions",
    subscriptionsDetails:
      "Give your students a simpler experience by paying for their subscriptions directly",
    initialRegistrations: "Initial registrations",
    included: "Included",
    total: "Total {currencySymbol}",
    savingsStatement: "Save {totalLineDiscountAmount} per person with this quote",
    standardPrice: "Standard price",
  },
  agreement: {
    pageTitle: "Your {agreementTitle} agreement",
    agreementTitle: "Agreement details for <Course/Institute TBC>",
    acca: "Association of Chartered Certified Accountants (ACCA)",
    idTitle: "Agreement ID: ",
    agreementTermFrom: "Agreement runs from:",
    agreementTermUntill: "untill:",
    document: {
      page: "Page",
      previous: "Previous",
      next: "next",
      description: "Pre-signed agreement PDF",
      fullSize: "view full size",
      exportHeader: "Useful link",
      exportText: "Export PDf",
    },
    pageTitleAgreementSignatuerPending: "We're setting up your agreement",
    pageTitleAgreementSigned: "Agreement signed, awaiting activation date",
    pageTitleAgreementActive: "Thank you, your agreement is active",
    activateDate: "Activated on ",
  },
  shared: {
    backButton: "Back to Agreements",
    titleContentText: "Your Agreement information",
  },
};
