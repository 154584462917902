import { RootState } from "../rootReducer";

export const getReviewUserDetails = (state: RootState) => state.reviewUserDetails;
export const getReviewUserRoleId = (state: RootState) => state.reviewUserDetails.reviewRoleId;
export const getReviewUserRoleName = (state: RootState) => state.reviewUserDetails.reviewRoleName;
export const getEmail = (state: RootState) => state.reviewUserDetails.userRequest.email;
export const getRequesttedtTime = (state: RootState) =>
  state.reviewUserDetails.userRequest.createdDate;

export const getFullName = (state: RootState) =>
  `${state.reviewUserDetails.userRequest.firstName} ${state.reviewUserDetails.userRequest.lastName}`;

export const getReviewUserDeclineMsg = (state: RootState) =>
  state.reviewUserDetails.reviewDeclineMessage;
export const getReviewUserIsDeclined = (state: RootState) => state.reviewUserDetails.isDeclined;
export const getReviewUserIsAccepted = (state: RootState) => state.reviewUserDetails.isAccepted;
